import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "../../components/misc/Headings";
import MainHeader from "./HeaderComponent.js";
import Features from "./Component.js";
import aura from "../../images/cards/sound.jpg";
import Unblocking from "./Types.js";
import Benefits from "./Types.js";
import Process from "./Types.js";
import Prepare from "./Types.js";
import Expect from "./Types.js";
import Cost from "./Types.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 mb-4`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const imageCss = tw`rounded-4xl`;

export default ({
  headingText = "Chakra",
  cards = null,
  UnblockingStep = null,
  BenefitsStep = null,
  ProcessStep = null,
  PrepareStep = null,
  ExpectStep = null,
  CostStep = null,
}) => {
  const defaultCards = [
    {
      title: "Root Chakra – Rubies",
      description: "",
    },
    {
      title: "Sacral chakra",
      description:
        "The sacral chakra, or Svadhisthana, is located just below your belly button. This chakra is responsible for your sexual and creative energy. It’s also linked to how you relate to your emotions as well as the emotions of others",
    },
    {
      title: "Solar plexus chakra",
      description:
        "The solar plexus chakra, or Manipura, is located in your stomach area. It’s responsible for confidence and self-esteem, as well as helping you feel in control of your life",
    },
    {
      title: "Heart chakra",
      description:
        "The heart chakra, or Anahata, is located near your heart, in the centres of your chest. It comes as no surprise that the heart chakra is all about our ability to love and show compassion.",
    },
    {
      title: "Throat chakra",
      description:
        "The throat chakra, or Vishuddha, is located in your throat. This chakra has to do with our ability to communicate verbally.",
    },
    {
      title: "Third eye chakra",
      description:
        "The third eye chakra, or Ajna, is located between your eyes. You can thank this chakra for a strong gut instinct. That’s because the third eye is responsible for intuition. It’s also linked to imagination.",
    },
    {
      title: "Crown chakra",
      description:
        "The crown chakra, or Sahasrara, is located at the top of your head. Your Sahasrara represents your spiritual connection to yourself, others, and the universe. It also plays a role in your life’s purpose",
    },
  ];
  const UnblockingSteps = [
    {
      heading: "Yoga postures",
    },
    {
      heading: "Breathing practices to encourage the flow of energy",
    },
    {
      heading: "Meditation to bring about clarity of mind",
    },
  ];

  const BenefitsSteps = [
    {
      heading: "Relieves stress",
    },
    {
      heading: "Enhances thinking",
    },
    {
      heading: "Boosts confidence",
    },
    {
      heading: "Enhance your focus",
    },
    {
      heading: "increases energy",
    },
    {
      heading: "therapeutic in nature",
    },
    {
      heading: "Pain management",
    },
    {
      heading: "Build good relation in family and workplace",
    },
  ];
  const ProcessSteps = [
    {
      heading:
        "Sound healing sessions are 1-to-1 with a trained master, where you will be either sitting or lying down while listening to music or sounds from healing instruments.",
    },
    {
      heading:
        "You will receive healing vibrations on and around the body using special tools, such as Tuning Forks. Depending on the method, the master will encourage you to sing, move, or even use a musical instrument.",
    },
    {
      heading:
        "However, most people prefer to remain still, quiet, and relaxed, to allow the healing sounds to penetrate their mind, body, and soul.",
    },
  ];
  const PrepareSteps = [
    {
      heading:
        "Wear clean, loose-fitting, comfortable clothing. You may wish to wear natural fabrics such as cotton, linen, or silk.",
    },
    {
      heading: "Remove your shoes, jewellery, and glasses before your session.",
    },
    {
      heading: "Keep your phone switched off or leave it behind.",
    },
  ];
  const ExpectSteps = [
    {
      heading: "You will feel relaxed and your stress will be relieved",
    },
    {
      heading: "You will feel energised",
    },
    {
      heading: "Your focus will be enhanced.",
    },
  ];
  const CostSteps = [
    {
      heading:
        "The energy healing of a single sound therapy sessions will cost ₹1500 and the duration will be approx. 45 minutes",
    },
    {
      heading: "The couple of sessions will cost ₹2999.",
    },
  ];

  if (!CostStep) CostStep = CostSteps;
  if (!ExpectStep) ExpectStep = ExpectSteps;
  if (!ProcessStep) ProcessStep = ProcessSteps;
  if (!PrepareStep) PrepareStep = PrepareSteps;
  if (!BenefitsStep) BenefitsStep = BenefitsSteps;
  if (!UnblockingStep) UnblockingStep = UnblockingSteps;
  if (!cards) cards = defaultCards;
  return (
    <AnimationRevealPage>
      <Container>
        {/* <ContentWithPaddingXl> */}
        <MainHeader
          heading="What is Sound Healing?"
          primaryButtonText="Order Now"
          primaryButtonUrl="https://order.now.com"
          imageInsideDiv={false}
          description="Sound healing is an ancient method of healing, which is achieved by the sound of different frequencies. Our body has 7 divine chakras which are sensitive to certain frequencies. In sound healing, this specific frequency of sound is played to activate the chakra related to that sound frequency."
          imageSrc={aura}
          imageCss={Object.assign(tw`bg-cover`, imageCss)}
          imageContainerCss={tw`md:w-1/2 h-auto`}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
          textOnLeft={true}
        />

        <Text>
          {/* <Features
            heading="What Are Crystal Therapy Chakras?"
            description="Supporting the seven chakras with specific types of crystals improves overall health and wellbeing. Here's a list of the body's energy points, or chakras, and the corresponding stones that may be used for healing:"
            cards={cards}
          /> */}
          {/* <Unblocking
            heading="Benefits of Aura Cleansing"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={UnblockingStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          /> */}
          <Benefits
            heading="Benefits of Sound Healing"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={BenefitsStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Process
            heading="Process of Sound Healing?"
            Subheading=""
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={ProcessStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Expect
            heading="What to expect after your session?"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={ExpectStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Cost
            heading="How much does a Sound Healing session cost?"
            textOnLeft={false}
            imageDecoratorBlob={false}
            steps={CostStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
        </Text>
        {/* </ContentWithPaddingXl> */}
      </Container>
    </AnimationRevealPage>
  );
};
