import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { postPaymentUpdate } from "../../../redux/actions/stripeAction";
import { Typography, Box, Fade } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";




export const Confirmation = () => {
    const stripe = useSelector(state=>state.stripe)
    const location = useLocation()
    const param = new URLSearchParams(location.search);
    const navigate = useNavigate();
  
    const dispatch = useDispatch();
  
    useEffect(() => {
        dispatch(postPaymentUpdate({"payment_intent_client_secret_data":param.get('payment_intent_client_secret'), "payment_intent_data":param.get('payment_intent'), "payment_status":param.get('redirect_status'), "user_bookingtype":"Class"}))
    }, [])
    console.log(param.get("payment_intent_client_secret"));
    console.log(param.get("payment_intent"));
    console.log(param.get("redirect_status"));

    useEffect(()=>{
        if(stripe.payload && stripe.payload.payment_status === "succeeded")
            setTimeout(()=>{
                navigate(`/trending/class/${stripe.payload.class_data}`)
            },5000)
    },[stripe])

     const logoStyles = {
       marginRight: "8px",
       fontSize: "4rem",
       animation: "spin 1s ease-in-out",
       animationIterationCount: 1,
     };
  
    return (
      <>
        {stripe.payload ? (
          stripe.payload.payment_status === "succeeded" ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <Fade in={true} timeout={2000}>
                <Typography variant="h4" align="center" fontWeight={"bold"}>
                  <CheckCircleOutlineIcon
                    sx={{
                      fontSize: "32px",
                      color: "blue",
                      margin: "-2%",
                      marginRight: "1%",
                    }}
                  />
                  Payment Done Successfully{" "}
                  <span style={{ display: "inline-block", marginLeft: "1rem" }}>
                    {/* Your checkmark icon goes here */}
                  </span>
                </Typography>
              </Fade>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <Fade in={true} timeout={2000}>
                <Typography variant="h4" align="center" fontWeight={"bold"}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: "32px",
                      color: "red",
                      margin: "-2%",
                      marginRight: "1%",
                    }}
                  />
                  Error! Please try again{" "}
                  <span style={{ display: "inline-block", marginLeft: "1rem" }}>
                    {/* Your checkmark icon goes here */}
                  </span>
                </Typography>
              </Fade>
            </Box>
          )
        ) : null}
      </>
    );
};