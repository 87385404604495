import Navbar from "../../components/MyComponents/Navbar";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Footer from "../../components/MyComponents/Footer";
import Appointments from "../../components/MyComponents/Appointments";

export default () => {
  return (
    <>
      <AnimationRevealPage>
        <Navbar />
        <Appointments />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
