import { GET_TAG_REQUEST, GET_TAG_SUCCESS, GET_TAG_FAILED, POST_TAG_REQUEST, POST_TAG_SUCCESS, POST_TAG_FAILED } from "../constant";

export const tagReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TAG_REQUEST:
      return { loading: true };
    case GET_TAG_SUCCESS:
      return { loading: false, payload: action.payload };
    case GET_TAG_FAILED:
      return { loading: false, error: action.payload };
    case POST_TAG_REQUEST:
      return { ...state, dialogLoading: true };
    case POST_TAG_SUCCESS:
      return { ...state, dialogLoading: false, dialogPayload: action.payload };
    case POST_TAG_FAILED:
      return { ...state, dialogLoading: false, dialogError: action.payload };
    default:
      return state;
  }
};
