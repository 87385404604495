import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { passwordChange } from "../../redux/actions/passwordAction";

const Password = () => {
  const { old_password, new_password } = useSelector(
    (state) => state.auth.userDetails
  );
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState(old_password);
  const [newPassword, setNewPassword] = useState(new_password);
  const [passwordUpdated, setPasswordUpdated] = useState(null);

  const handleClick = async () => {
    try {
      await dispatch(
        passwordChange({ old_password: oldPassword, new_password: newPassword })
      );
      setPasswordUpdated(true);
    } catch (error) {
      setPasswordUpdated(false);
    }
  };

  const isFormValid = oldPassword && newPassword;

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={{ md: "flex-center", xs: "center" }}
      >
        <Grid item md={6} width={"100%"}>
          <Card
            variant="outlined"
            sx={{ height: "100%", minHeight: "76vh", width: "100%" }}
          >
            <form>
              <CardContent
                sx={{
                  p: 3,
                  maxHeight: { md: "40vh" },
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                <Typography variant="h2" paddingBottom={3}>
                  Change Password
                </Typography>
                {passwordUpdated === true && (
                  <Alert severity="success">
                    <Typography fontWeight="bold">
                      Password updated successfully!
                    </Typography>
                  </Alert>
                )}
                {passwordUpdated === false && (
                  <Alert severity="error">
                    <Typography fontWeight="bold">
                      Password update failed.
                    </Typography>
                  </Alert>
                )}

                <Grid component="form">
                  <Typography fontWeight={"bold"} paddingBottom={1}>
                    Old Password <br />
                  </Typography>
                  <TextField
                    fullWidth
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    variant="outlined"
                    sx={{ paddingBottom: "24px" }}
                  />
                </Grid>
                <Grid component="form">
                  <Typography fontWeight={"bold"} paddingBottom={1}>
                    New Password <br />
                  </Typography>
                  <TextField
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    variant="outlined"
                    sx={{ paddingBottom: "24px" }}
                  />
                </Grid>
                {/* BUTTON */}
                <Grid
                  container
                  justifyContent={{ xs: "center", md: "flex-end" }}
                  item
                  xs={6}
                >
                  <Button
                    sx={{ p: "1rem 2rem", my: 2, height: "3rem" }}
                    component="button"
                    size="large"
                    variant="contained"
                    color="secondary"
                    disabled={!isFormValid}
                    onClick={handleClick}
                  >
                    Change password
                  </Button>
                </Grid>
              </CardContent>
            </form>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Password;
