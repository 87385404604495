import {
  GET_DAILY_APPOINTMENT_REQUEST,
  GET_DAILY_APPOINTMENT_SUCCESS,
  GET_DAILY_APPOINTMENT_FAILED,
  GET_CREATE_DAILY_APPOINTMENT_REQUEST,
  GET_CREATE_DAILY_APPOINTMENT_SUCCESS,
  GET_CREATE_DAILY_APPOINTMENT_FAILED,
  DELETE_DAILY_APPOINTMENT_REQUEST,
  DELETE_DAILY_APPOINTMENT_SUCCESS,
  DELETE_DAILY_APPOINTMENT_FAILED,
  UPDATE_DAILY_APPOINTMENT_REQUEST,
  UPDATE_DAILY_APPOINTMENT_SUCCESS,
  UPDATE_DAILY_APPOINTMENT_FAILED,
} from "../constant";

export const appointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DAILY_APPOINTMENT_REQUEST:
      return { ...state, loading: true };
    case GET_DAILY_APPOINTMENT_SUCCESS:
      return { ...state, loading: false, dailyAppointment: action.payload };
    case GET_DAILY_APPOINTMENT_FAILED:
      return {...state,loading: false,dailyAppointmentFailed: action.payload,};
    case GET_CREATE_DAILY_APPOINTMENT_REQUEST:
      return { ...state, loading: true };
    case GET_CREATE_DAILY_APPOINTMENT_SUCCESS:
      return {...state,loading: false,createDailyAppointment: action.payload,};
    case GET_CREATE_DAILY_APPOINTMENT_FAILED:
      return { ...state, loading: false,  createDailyAppointmentError: action.payload };
    case DELETE_DAILY_APPOINTMENT_REQUEST:
      return { ...state, loading: true };
    case  DELETE_DAILY_APPOINTMENT_SUCCESS:
      return {...state,loading: false,createDailyAppointment: action.payload,};
    case DELETE_DAILY_APPOINTMENT_FAILED:
      return { ...state, loading: false,  createDailyAppointmentError: action.payload };
    case UPDATE_DAILY_APPOINTMENT_REQUEST:
      return { ...state, loading: true };
    case  UPDATE_DAILY_APPOINTMENT_SUCCESS:
      return {...state,loading: false,createDailyAppointment: action.payload,};
    case UPDATE_DAILY_APPOINTMENT_FAILED:
      return { ...state, loading: false,  createDailyAppointmentError: action.payload };
    default:
      return state;
  }
};
