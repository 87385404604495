import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../../misc/Headings.js";
import Cowrie from "../../../images/upcomming/cowrie.jpg";

import { useDispatch, useSelector } from "react-redux";
import { postRegister } from "../../../../../redux/actions/contactAction";
import { Alert } from "@mui/material";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: Cowrie,
      subtitle: "Cowrie",
      title: "Cowrie",
      description:
        "Cowrie shell divination refers to several distinct forms of divination using cowrie shells that are parts of the rituals and religions. In a positive sense, it indicates that you have a lot of spiritual protection, honesty, and perseverance.  ",
      url: "https://timerse.com",
    },
  ];

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const { loading, success, error } = useSelector((state) => state.contact);
  const [emailError, setEmailError] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleRegister = (title) => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    const formData = {
      title,
      email,
    };

    dispatch(postRegister(formData))
      .then(() => {
        // Registration successful
        setShowMessage(false);
        setEmail(""); // Clear the email input field
        console.log("registered");
        const showSuccess = "Successfully Registered to this course";
        setShowSuccess(showSuccess);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            const showMessage = "There is an internal server error";
            setShowMessage(showMessage);
          } else if (error.response.status === 400) {
            const showMessage = "Unable to Determine";
            setShowMessage(showMessage);
          }
        }
      });
  };

  console.log(error);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <Container>
      {/* <SingleColumn> */}
      {/* <HeadingInfoContainer>
        <HeadingTitle>Upcomming</HeadingTitle>
        <HeadingDescription>
          Here are some of the most Upcomming courses
        </HeadingDescription>
      </HeadingInfoContainer> */}

      <Content>
        {cards.map((card, i) => (
          <Card key={i} reversed>
            <Image imageSrc={card.imageSrc} />
            <Details>
              <Subtitle>{card.subtitle}</Subtitle>
              <Title>{card.title}</Title>
              <Description>{card.description}</Description>
              {/* <Link href={card.url}>Read More</Link> */}
              {showSuccess && <Alert severity="success">{showSuccess}</Alert>}
              <Actions>
                <input
                  type="text"
                  placeholder="Your E-mail Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                <button onClick={() => handleRegister(card.title)}>
                  Register
                </button>
              </Actions>
              {emailError && <div className="error-message">{emailError}</div>}
              {showMessage && <Alert severity="info">{showMessage}</Alert>}
            </Details>
          </Card>
        ))}
      </Content>
      {/* </SingleColumn> */}
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
