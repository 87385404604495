import {

  GET_LIBRARY_COMMENT_REQUEST,
  GET_LIBRARY_COMMENT_SUCCESS,
  GET_LIBRARY_CREATE_CLASS_FAILED,
  POST_LIBRARY_COMMENT_FAILED,
  POST_LIBRARY_COMMENT_REQUEST,
  POST_LIBRARY_COMMENT_SUCCESS,
} from "../constant";

export const libraryCommentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LIBRARY_COMMENT_REQUEST:
      return { ...state, loading: true };
    case GET_LIBRARY_COMMENT_SUCCESS:
      return { ...state, loading: false, payload: action.payload };
    case GET_LIBRARY_CREATE_CLASS_FAILED:
      return { ...state, loading: false, error: action.payload };
    case POST_LIBRARY_COMMENT_REQUEST:
      return { ...state, loading: true };
    case POST_LIBRARY_COMMENT_SUCCESS:
      return { ...state, loading: false, postComment: action.payload };
    case POST_LIBRARY_COMMENT_FAILED:
      return { ...state, loading: false, postCommentError: action.payload };
    default:
      return state;
  }
};
