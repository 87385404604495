import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./reducer/authReducer";
import { classReducer } from "./reducer/classReducer";
import { detailReducer } from "./reducer/detailReducer";
import { accountReducer } from "./reducer/accountReducer";
import { stripeReducer } from "./reducer/stripeReducer";
import { clanReducer } from "./reducer/clanReducer";
import { classDetailReducer } from "./reducer/classDetailReducer";
import { tagReducer } from "./reducer/tagReducer";
import { paymentReducer } from "./reducer/paymentReducer";
import { userListReducer } from "./reducer/userListReducer";
import { classManagementReducer } from "./reducer/classManagementReducer";
import { myClassReducer } from "./reducer/myClassReducer";
import { libraryReducer } from "./reducer/libraryReducer";
import { feedbackReducer } from "./reducer/feedbackReducer";
import { libraryDetailReducer } from "./reducer/libraryDetailReducer";
import { appointmentReducer } from "./reducer/appointmentReducer";
import { passwordReducer } from "./reducer/passwordReducer";
import { certificateReducer } from "./reducer/certificateReducer";
import { libraryCommentReducer } from "./reducer/libraryCommentReducer";
import { commentReducer } from "./reducer/commentReducer";
import { libraryManagementReducer } from "./reducer/libraryManagementReducer";
import { forgotReducer } from "./reducer/forgotReducer";
import { contactReducer } from "./reducer/contactReducer";
const reducer = combineReducers({
  auth: authReducer,
  forgot: forgotReducer,
  class: classReducer,
  detail: detailReducer,
  account: accountReducer,
  password: passwordReducer,
  stripe: stripeReducer,
  clan: clanReducer,
  classDetail: classDetailReducer,
  tag: tagReducer,
  payment: paymentReducer,
  userList: userListReducer,
  classManagement: classManagementReducer,
  myClass: myClassReducer,
  certificate: certificateReducer,
  library: libraryReducer,
  libraryDetail: libraryDetailReducer,
  libraryComment: libraryCommentReducer,
  libraryManagement: libraryManagementReducer,
  feedback: feedbackReducer,
  comment: commentReducer,
  contact: contactReducer,
  appointment: appointmentReducer,
});

const userInfoFromStorage = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null;

const initalState = {auth: {userDetails: userInfoFromStorage}}

const store = configureStore({
  reducer,
  preloadedState: initalState
});

export default store;