import { GET_STRIPE_CLIENT_FAILED, GET_STRIPE_CLIENT_REQUEST, GET_STRIPE_CLIENT_SUCCESS, POST_STRIPE_SUCCESS,  POST_STRIPE_FAILED, POST_STRIPE_REQUEST  } from "../constant"

export const stripeReducer = (state = {},action) => {
  switch(action.type) {
    case GET_STRIPE_CLIENT_REQUEST:
        return { loading: true }
    case GET_STRIPE_CLIENT_SUCCESS:
        return { loading: false, payload: action.payload }
    case GET_STRIPE_CLIENT_FAILED:
        return { loading: false, error: action.payload }
    case POST_STRIPE_REQUEST:
        return { loading: true }
    case POST_STRIPE_SUCCESS:
        return { loading: false, payload: action.payload }
    case POST_STRIPE_FAILED:
        return { loading: false, error: action.payload }  
    default:
      return state
  }
}