import axios from "axios";
import {
  GET_CLASS_DETAIL_REQUEST,
  GET_CLASS_DETAIL_SUCCESS,
  GET_CLASS_DETAIL_FAILED,
} from "../constant";
import apiurl from "../../constant/config";
export const getClassDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_CLASS_DETAIL_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));
    const user = JSON.parse(localStorage.getItem("userDetails"));

    var url = `${apiurl}/api/student/class/retrieve/${id}`;
    if (user.userProfile == "Admin" || user.userProfile == "Teacher") {
      url = `${apiurl}/api/teacher/classup/retrieve/${id}`;
    }
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${access}` },
    });

    dispatch({
      type: GET_CLASS_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CLASS_DETAIL_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
