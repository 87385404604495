// routes/service.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import ReikiService from "../pages/LandingPage/mysite/ServicePage/ReikiService";
import Aura from "../pages/LandingPage/mysite/ServicePage/Aura";
import Crystal from "../pages/LandingPage/mysite/ServicePage/Crystal";
import Meditation from "../pages/LandingPage/mysite/ServicePage/Meditation";
import SoundService from "../pages/LandingPage/mysite/ServicePage/SoundService";
import ChakraService from "../pages/LandingPage/mysite/ServicePage/ChakraService";
import NumerologyService from "../pages/LandingPage/mysite/ServicePage/NumerologyService";
import ConsciousnessService from "../pages/LandingPage/mysite/ServicePage/ConsciousnessService";

const ServiceRoutes = () => {
  return (
    <Routes>
      <Route path="/reiki" element={<ReikiService />} />
      <Route path="/aura" element={<Aura />} />
      <Route path="/crystal" element={<Crystal />} />
      <Route path="/meditation" element={<Meditation />} />
      <Route path="/sound" element={<SoundService />} />
      <Route path="/chakra" element={<ChakraService />} />
      <Route path="/numerology" element={<NumerologyService />} />
      <Route path="/consciousness" element={<ConsciousnessService />} />
      {/* Add more nested routes here */}
    </Routes>
  );
};

export default ServiceRoutes;
