import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  styled,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SignupImg from "../../assets/images/signup.svg";
import googleLogo from "../../assets/images/google_icon.svg";
import { CheckIcon, UnCheckedIcon } from "../../assets/icons/checkIcon";
import CustomButton from "../../components/common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../redux/actions/authActions";
import MuiAlert from "@mui/material/Alert";
import CircularIndeterminate from "../../components/Loader";
import Error from "../../components/Error";
import logo from "../../assets/images/logo.png";


const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fffff" : "#2b2b2b",
    // border: "4px solid #D9D9D9",
    fontSize: 16,
    width: "100%",
    // padding: "12px 24px",
    // paddingBottom: '4%',
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      borderColor: "#9D78BD",
    },
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SignupComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [userProfile, setUserProfile] = useState("Student");
  const [terms, setTerms] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subject, setSubject] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [ConfirmPasswordError, setConfirmPasswordError] = useState("");
  const auth = useSelector((state) => state.auth);
  const { error, loading } = auth;
  const [showMessage, setShowMessage] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const submitHandler = (e) => {
    e.preventDefault();
    // Perform signup logic here...
    dispatch(
      signup(
        email,
        password,
        firstname,
        lastname,
        subject,
        confirmPassword,
        userProfile
      )
    );
    if (userProfile === "Teacher") {
      setShowMessage(true);
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (!newEmail) {
      setEmailError("Please enter an email address");
      setIsDisabled(true);
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
      setEmailError("Please enter a valid email address");
      setIsDisabled(true);
    } else {
      setEmailError("");
      setIsDisabled(password.length < 8);
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    // Check password length
    if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      setIsDisabled(true);
      return;
    }

    // Check password strength
    const hasAlphaNumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(newPassword);
    if (!hasAlphaNumeric) {
      setPasswordError("Password must contain both letters and numbers");
      setIsDisabled(true);
      return;
    }

    setPasswordError("");
    setIsDisabled(
      email.length === 0 ||
        !email.includes("@") ||
        newPassword !== confirmPassword
    );
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    if (newConfirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
      setIsDisabled(true);
    } else {
      setConfirmPasswordError("");
      setIsDisabled(email.length === 0 || password.length < 8 || !terms);
    }
  };

  const handleTermsChange = (event) => {
    setTerms(event.target.checked);
    if (event.target.checked) {
      setIsDisabled(passwordError || ConfirmPasswordError);
    } else {
      setIsDisabled(true);
    }
  };

  return (
    <Box>
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <>
          <Grid
            container
            component="main"
            sx={{
              height: "100vh",
            }}
          >
            <Grid
              item
              sm={6}
              sx={{
                placeContent: "center",
                backgroundColor: "#E6F2FF",
                backgroundSize: "contain",
                backgroundPosition: "center",
                display: {
                  xs: "none",
                  sm: "grid",
                },
              }}
            >
              <img
                src={SignupImg}
                alt="signup"
                style={{ width: "100%" }}
                draggable={false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  px: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="h1"
                  variant="h4"
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  Get Started now !
                </Typography>
                {error ? (
                  <Alert
                    severity="error"
                    sx={{ marginTop: "1%", marginBottom: "1%" }}
                  >
                    {error}
                  </Alert>
                ) : null}

                <div className={classes.container}>
                  {showMessage && (
                    <Alert severity="info">
                      Your request requires admin approval before you can login
                      as a teacher.
                    </Alert>
                  )}
                  <FormControlLabel
                    control={
                      <Radio
                        checked={userProfile === "Student"}
                        onChange={(e) => setUserProfile(e.target.value)}
                        value="Student"
                      />
                    }
                    label="Student"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={userProfile === "Teacher"}
                        onChange={(e) => setUserProfile(e.target.value)}
                        value="Teacher"
                      />
                    }
                    label="Teacher"
                  />
                  {/* <FormControlLabel
                    control={
                      <Radio
                        checked={userProfile === "Admin"}
                        onChange={(e) => setUserProfile(e.target.value)}
                        value="Admin"
                      />
                    }
                    label="Admin"
                  /> */}
                </div>

                <Box
                  component="form"
                  noValidate
                  onSubmit={submitHandler}
                  sx={{ mt: 1 }}
                >
                  <StyledInput
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      handleEmailChange(e);
                    }}
                    helperText={
                      emailError && (
                        <Typography variant="body2" color="error">
                          {emailError}
                        </Typography>
                      )
                    }
                    sx={{ paddingBottom: "4%" }}
                  />

                  <StyledInput
                    required
                    label="First Name"
                    fullWidth
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    sx={{ paddingBottom: "4%" }}
                  />
                  <StyledInput
                    required
                    label="Last name"
                    fullWidth
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    sx={{ paddingBottom: "4%" }}
                  />
                  {userProfile === "Teacher" && (
                    <StyledInput
                      required
                      label="Subject"
                      fullWidth
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      sx={{ paddingBottom: "4%" }}
                    />
                  )}
                  <StyledInput
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      handlePasswordChange(e);
                    }}
                    helperText={
                      passwordError && (
                        <Typography variant="body2" color="error">
                          {passwordError}
                        </Typography>
                      )
                    }
                    fullWidth
                    sx={{ paddingBottom: "4%" }}
                  />
                  <StyledInput
                    label="Confirm Password"
                    autoComplete="new-password"
                    fullWidth
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      handleConfirmPasswordChange(e);
                    }}
                    helperText={
                      ConfirmPasswordError && (
                        <Typography variant="body2" color="error">
                          {ConfirmPasswordError}
                        </Typography>
                      )
                    }
                    sx={{ paddingBottom: "4%" }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={terms}
                        sx={{
                          paddingBlock: 0,
                          "&.Mui-checked": {
                            color: "#91C3F4",
                          },
                        }}
                        icon={<UnCheckedIcon />}
                        checkedIcon={<CheckIcon />}
                      />
                    }
                    name="terms"
                    id="terms"
                    onChange={(e) => {
                      setTerms(e.target.value);
                      handleTermsChange(e);
                    }}
                    label={
                      <Typography variant="body2" sx={{ padding: 0 }}>
                        {"I agree to the website "}
                        <Link to="" style={{ color: "#9D78BD" }}>
                          Privay Policy & Terms and Conditions
                        </Link>
                      </Typography>
                    }
                  />
                  <CustomButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: "#D9D9D9", fontSize: "18px" }}
                    disabled={isDisabled}
                  >
                    Sign Up
                  </CustomButton>
                  {/* <Divider
                    sx={{
                      color: "#D9D9D9",
                      "&::before, &::after": {
                        borderColor: "#9D78BD",
                      },
                    }}
                  >
                    Or
                  </Divider>
                  <Button
                    type="button"
                    fullWidth
                    sx={{
                      mt: 2,
                      mb: 2,
                      color: "#9D78BD",
                      border: "1px solid #9D78BD",
                      textTransform: "capitalize",
                      position: "relative",
                      borderRadius: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr 1fr",
                        alignItems: "center",
                        placeItems: "center",
                      }}
                    >
                      <img
                        src={googleLogo}
                        alt="googlelogo"
                        style={{
                          width: "20px",
                        }}
                      />
                      <span style={{ fontSize: "18px", whiteSpace: "nowrap" }}>
                        Signup with Google
                      </span>
                    </Box>
                  </Button> */}
                </Box>
                <Typography
                  sx={{
                    typography: { sm: "body1", xs: "body2", md: "h6" },
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Already have an account?&nbsp;
                  <Link
                    to="/login"
                    style={{ color: "#9D78BD", textDecoration: "none" }}
                  >
                    Sign In
                  </Link>
                  &nbsp;here
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

const useStyles = styled((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default SignupComponent;
