import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../../components/headers/Designed";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-blue-800 hover:border-blue-800 hover:text-blue-800`}
  }
  ${NavToggle}.closed {
    ${tw`text-secondary-500 hover:text-primary-500`}
  }

  /* New styles for sticky navbar */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  &.isSticky {
    ${tw`bg-white shadow-lg`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;


export default () => {
  const [isSticky, setIsSticky] = useState(false);
    const handleClick = () => {
      // Scroll to the top of the page when the link is clicked
      window.scrollTo(0, 0);
    };
  
  
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset > 0);
    };
    
    window.addEventListener("scroll", handleScroll);
    
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const items = [
    {
      label: (
        <Link
          style={{ fontWeight: "bold" }}
          onClick={handleClick}
          to="/trending"
        >
          Trending
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link
          style={{ fontWeight: "bold" }}
          onClick={handleClick}
          to="/upcoming"
        >
          Upcoming
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link
          style={{ fontWeight: "bold" }}
          onClick={handleClick}
          to="/attunement"
        >
          Attunements
        </Link>
      ),
      key: "3",
    },
    {
      label: (
        <Link
          style={{ fontWeight: "bold" }}
          onClick={handleClick}
          to="/festival"
        >
          Festivals
        </Link>
      ),
      key: "4",
    },
    // {
    //   label: <Link onClick={handleClick} to="/download">Downloads</Link>,
    //   key: "5",
    // },
  ];

  const navLinks = [
    <NavLinks key={1} style={{ whiteSpace: "nowrap" }}>
      <NavLink>
        <Link onClick={handleClick} to="/">
          Home
        </Link>
      </NavLink>
      {/* <NavLink href="#">Blog</NavLink> */}
      <NavLink style={{ fontSize: "16px", fontWeight: "bold" }}>
        <Dropdown
          menu={{
            items,
          }}
        >
          <Space>
            Workshops
            <DownOutlined />
          </Space>
        </Dropdown>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/meditation">
          Meditation
        </Link>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/appointment">
          Appointments
        </Link>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/event">
          Events
        </Link>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/testimonial">
          Testimonials
        </Link>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/contact">
          Contact Us
        </Link>
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink style={{ backgroundColor: "blue" }}>
        <Link onClick={handleClick} to="/login">
          Login/SignUp
        </Link>
      </PrimaryLink>
    </NavLinks>,
  ];

    const mobnavLinks = [
      <NavLinks
        key={1}
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavLink>
          <Link onClick={handleClick} to="/">
            Home
          </Link>
        </NavLink>
        <NavLink style={{ fontSize: "16px", fontWeight: "bold" }}>
          <Dropdown
            menu={{
              items,
            }}
          >
            <Space style={{ fontSize: "16px" }}>
              Workshops
              {/* <DownOutlined /> */}
            </Space>
          </Dropdown>
        </NavLink>
        <NavLink>
          <Link onClick={handleClick} to="/meditation">
            Meditation
          </Link>
        </NavLink>
        <NavLink>
          <Link onClick={handleClick} to="/appointment">
            Appointments
          </Link>
        </NavLink>
        <NavLink>
          <Link onClick={handleClick} to="/event">
            Events
          </Link>
        </NavLink>
        <NavLink>
          <Link onClick={handleClick} to="/testimonial">
            Testimonials
          </Link>
        </NavLink>
        <NavLink>
          <Link onClick={handleClick} to="/contact">
            Contact Us
          </Link>
        </NavLink>
      </NavLinks>,
      <NavLinks key={2}>
        <PrimaryLink style={{ color: "black" }}>
          <Link onClick={handleClick} to="/login">
            Login/SignUp
          </Link>
        </PrimaryLink>
      </NavLinks>,
    ];

  return (
    <Container>
      <HeroContainer>
        <StyledHeader
          links={navLinks}
          mobLink={mobnavLinks}
          className={isSticky ? "isSticky" : ""}
        />
      </HeroContainer>
    </Container>
  );
};
