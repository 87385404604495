import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import { LibraryCheckout } from "./libraryCheckout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLibraryClientSecret } from "../../../redux/actions/stripeAction";
import { useParams } from "react-router-dom";

export const LibraryStripe = () => {
  const { id } = useParams();
  const stripe = useSelector((state) => state.stripe);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLibraryClientSecret({ id }));
  }, []);
  console.log(stripe.payload);
  console.log(stripe.payload);

  return stripe.payload &&
    stripe.payload.client_secret &&
    stripe.payload.publish_key ?
    (
    <Elements
      stripe={loadStripe(stripe.payload.publish_key)}
      options={{ clientSecret: stripe.payload.client_secret }}
    >
      <LibraryCheckout id={id} />
    </Elements>
  ) : null;
};
