import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../../components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons.js";
import EmailIllustrationSrc from "../../images/email-illustration.svg";
import reiki from "../../images/cards/reiki.jpg";
import { useDispatch, useSelector } from "react-redux"; // Add this line
import { postComment } from "../../../../redux/actions/contactAction.js";

const Container = tw.div`
  relative bg-cover bg-center bg-no-repeat
`;
const TwoColumn = tw.div`
  flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24
`;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
  height: 114px;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 300ms;
  margin-top: 1.5rem;
  padding: 1rem;
`;
const font_style = {
  fontFamily: "'Great vibes', sans-serif",
};

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  // Add the following lines to manage form state and dispatch
  const [formData, setFormData] = useState({
    email: "",
    sender_name: "",
    subject: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: "",
    sender_name: "",
    subject: "",
    message: "",
  });

  const validateForm = () => {
    const errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    }
    if (!formData.sender_name) {
      errors.sender_name = "Full Name is required";
    }
    if (!formData.subject) {
      errors.subject = "Subject is required";
    }
    if (!formData.message) {
      errors.message = "Message is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.comment);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      // Dispatch the postComment action with the form data
      dispatch(postComment(formData));
      resetForm();
    }
  };

  const resetForm = () => {
    setFormData({
      email: "",
      sender_name: "",
      subject: "",
      message: "",
    });
  };

  useEffect(() => {
    if (success) {
      resetForm(); // Call the resetForm function after success
    }
  }, [success]);

  return (
    <Container
      style={{
        backgroundImage: `url(${reiki})`,
        backgroundAttachment: "fixed", // Add this property
        padding: "16px",
        marginTop: "16vh",
      }}
    >
      <TwoColumn
        style={{
          backdropFilter: "blur(8px)",
          padding: "16px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading style={font_style}>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form
              action={formAction}
              method={formMethod}
              onSubmit={handleSubmit}
            >
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {formErrors.email && <p>{formErrors.email}</p>}
              <Input
                type="text"
                name="sender_name"
                placeholder="Full Name"
                value={formData.sender_name}
                onChange={handleInputChange}
                required
              />
              {formErrors.sender_name && <p>{formErrors.sender_name}</p>}
              <Input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
              />
              {formErrors.subject && <p>{formErrors.subject}</p>}
              <Textarea
                name="message"
                placeholder="Your Message Here"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
              {formErrors.message && <p>{formErrors.message}</p>}
              <SubmitButton type="submit" disabled={loading}>
                {loading ? "Sending..." : submitButtonText}
              </SubmitButton>
              {error && <p>Error: {error}</p>}
              {success && <p>Message sent successfully!</p>}
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
