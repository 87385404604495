import React, { useState, useContext } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid, Typography, Badge, Avatar } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import GenderSelect from "../../components/Dropdown/Gender";
import DOBpicker from "../../components/DatePicker/Dob";
import QualificationSelect from "../../components/Dropdown/Qualification";
import SubjectSelect from "../../components/Dropdown/Subject";
import { postUserDetails, putUserDetails } from "../../redux/actions/detailAction";
import IconButton from "@mui/material/IconButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";  
import MartialSelect from '../../components/Dropdown/martialstatus'

const SettingsCard = ({detail, dispatch, userProfile}) => {

  const handleChange = (e) => {
    e.preventDefault();
    if(detail.payload && (detail.payload.length || Object.keys(detail.payload).length))
      dispatch(putUserDetails({profile,gender,qualification,subject,dob,joiningDate,marital}))
    else
      dispatch(postUserDetails({profile,gender,qualification,subject,dob,joiningDate,marital}))
  };

  const [profile, setProfile] =  useState(null);
  const [gender, setGender] = useState("");
  const [qualification, setQualification] = useState("");
  const [subject, setSubject] = useState("");
  const [dob, setDob] = useState(null);
  const [marital, setMarital] = useState(null);
  const [joiningDate, setJoiningDate] = useState(null);

   const isFormValid =
   dob &&
   joiningDate &&
     gender &&
     qualification &&
     subject &&
     marital;


  return (
    <>
      <Card
        variant="outlined"
        sx={{ height: "100%", minHeight: "76vh", width: "100%" }}
      >
        {/* TABS */}
        <br></br>
        <form>
          <CardContent
            sx={{
              p: 3,
              maxHeight: { md: "40vh" },
              textAlign: { xs: "center", md: "start" },
            }}
          >
            {/* FIELDS */}
            <FormControl fullWidth>
              <Grid
                container
                direction={{ xs: "column", md: "row" }}
                columnSpacing={5}
                rowSpacing={3}
              >
                <Grid
                  item
                  sx={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {/* PROFILE PHOTO */}
                  <Typography
                    fontWeight={"bold"}
                    paddingBottom={1}
                    variant="h5"
                  >
                    User Profile <br />
                  </Typography>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <IconButton aria-label="upload picture" component="label">
                        <input hidden accept="image/*" type="file" />
                        <PhotoCameraIcon
                          sx={{
                            border: "5px solid white",
                            backgroundColor: "#ff558f",
                            borderRadius: "50%",
                            padding: ".2rem",
                            width: 35,
                            height: 35,
                          }}
                        ></PhotoCameraIcon>
                      </IconButton>
                    }
                  >
                    <Avatar
                      sx={{ width: 100, height: 100, mb: 1.5 }}
                      src="https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/master/pass/best-face-oil.png"
                    ></Avatar>
                  </Badge>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight={"bold"} paddingBottom={1}>
                    Date of Birth <br />
                  </Typography>
                  <DOBpicker value={dob} setValue={setDob} />
                </Grid>
                {userProfile === "Teacher" ? (
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"} paddingBottom={1}>
                      Date of Joining <br />
                    </Typography>
                    <DOBpicker value={joiningDate} setValue={setJoiningDate} />
                  </Grid>
                ) : null}
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"} paddingBottom={1}>
                    Gender <br />
                  </Typography>
                  <GenderSelect value={gender} setValue={setGender} />
                </Grid>
                {userProfile === "Teacher" ? (
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"} paddingBottom={1}>
                      Martial Status <br />
                    </Typography>
                    <MartialSelect value={marital} setValue={setMarital} />
                  </Grid>
                ) : null}

                <Grid item xs={6}>
                  <Typography fontWeight={"bold"} paddingBottom={1}>
                    Qualification <br />
                  </Typography>
                  <QualificationSelect
                    value={qualification}
                    setValue={setQualification}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight={"bold"} paddingBottom={1}>
                    Subjects <br />
                  </Typography>
                  <SubjectSelect value={subject} setValue={setSubject} />
                </Grid>

                {/* BUTTON */}
                <Grid
                  container
                  justifyContent={{ xs: "center", md: "flex-center" }}
                  item
                  xs={12}
                >
                  <Button
                    sx={{ p: "1rem 2rem", my: 2, height: "3rem" }}
                    component="button"
                    size="large"
                    variant="contained"
                    color="secondary"
                    disabled={!isFormValid}
                    onClick={handleChange}
                  >
                    {detail.payload &&
                    (detail.payload.length ||
                      Object.keys(detail.payload).length)
                      ? "Update"
                      : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </CardContent>
        </form>
      </Card>
    </>
  );
};

export default SettingsCard;