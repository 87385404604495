import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import StudentAccount from "../components/Account/Student";
import TeacherDetails from "../components/CourseAboutCard/TeacherDetails";
import StudentDashboard from "../components/Dashboard/Student";
import PageNotFound from "../pages/PageNotFound";
import TeacherAccount from "../pages/Account";
import ClanMeeting from "../pages/Meeting"
import JoinClass from "../pages/ClassDetail";
import JoinLibrary from "../pages/LibraryDetail";
import Password from "../pages/Password";
import Profile from "../pages/Profile";
const Suggestion = React.lazy(() => import("../pages/Students/Suggestion"));

// Teacher Routes
const TeacherAppointments = React.lazy(() =>
  import("../pages/Teachers/Appointments")
);
const TeacherForum = React.lazy(() => import("../pages/Teachers/Forum"));
const TeacherLibrary = React.lazy(() => import("../pages/Teachers/Library"));
const TeacherMyClasses = React.lazy(() =>
  import("../pages/Teachers/MyClasses")
);
const EditLibrary = React.lazy(() =>
  import("../pages/Teachers/Library/EditLibrary")
);
const LibraryDetail = React.lazy(() =>
  import("../pages/Library/LibraryDetail")
);

const TeacherPayment = React.lazy(() => import("../pages/Payment"));
const CreateLibrary = React.lazy(() =>
  import("../pages/Teachers/Library/CreateLibrary")
);

const TeacherRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route element={<TeacherMyClasses />} path="/myclasses/*" />
      <Route element={<Navigate to="/myclasses" />} path="/login" />
      <Route element={<Navigate to="/myclasses" />} path="/" />
      <Route element={<TeacherAppointments />} path="/appointments" />
      <Route element={<TeacherLibrary />} path="/library" />
      <Route element={<LibraryDetail />} path="/library/:id" />
      <Route element={<CreateLibrary />} path="/library/create" />
      <Route element={<EditLibrary />} path="/library/edit/:id" />
      <Route element={<TeacherForum />} path="/forum" />
      <Route element={<TeacherPayment />} path="/payment" />
      <Route element={<Profile />} path="/profile" />
      <Route element={<TeacherAccount />} path="/account" />
      <Route element={<Password />} path="/password" />
      <Route element={<ClanMeeting />} path="/meeting/:id"/>
      <Route element={<JoinClass />} path="/class/:id"/>
      <Route element={<JoinLibrary />} path="/library/course/:id"/>
      <Route path="/signup" element={<Navigate to="/" />}/>
    </Routes>
  );
};

export default TeacherRoutes;
