import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import moment from "moment";

const ProfileCard = ({user, detail}) => {

  const styles = {
    details: {
      padding: "1rem",
      borderTop: "1px solid #e1e1e1",
    },
    value: {
      padding: "1rem 2rem",
      borderTop: "1px solid #e1e1e1",
      color: "#899499",
    },
  };

  return (
    <>
      <Card variant="outlined">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sx={{ p: "1.5rem 0rem", textAlign: "center" }}>
            {/* PROFILE PHOTO */}
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Avatar
                sx={{ width: 100, height: 100, mb: 1.5 }}
                src="https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/master/pass/best-face-oil.png"
              ></Avatar>
            </Badge>
            <Typography variant="h6">
              {user}
            </Typography>
            {/*<Typography color="text.secondary">Full Stact Developer</Typography>*/}
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              <Typography style={styles.details}>Age</Typography>
              <Typography style={styles.details}>DOB</Typography>
              <Typography style={styles.details}>Qualification</Typography>
            </Grid>
            {/* VALUES */}
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <Typography style={styles.value}>{detail.payload && (detail.payload.length || Object.keys(detail.payload).length) ? moment().diff(detail.payload.dob,  'years') : "N/A"}</Typography>
              <Typography style={styles.value}>{detail.payload && (detail.payload.length || Object.keys(detail.payload).length) ? moment(detail.payload.dob).format("DD-MM-YYYY") : "N/A"}</Typography>
              <Typography style={styles.value}>{detail.payload && (detail.payload.length || Object.keys(detail.payload).length) ? detail.payload.qual : "N/A"}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ProfileCard;
