import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "../../components/misc/Headings";
import MainHeader from "./HeaderComponent.js";
import Features from "./Component.js";
import aura from "../../images/cards/crystal.jpg";
import Unblocking from "./Types.js";
import Benefits from "./Types.js";
import Process from "./Types.js";
import Prepare from "./Types.js";
import Expect from "./Types.js";
import Cost from "./Types.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 mb-4`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const imageCss = tw`rounded-4xl`;

export default ({
  headingText = "Chakra",
  cards = null,
  UnblockingStep = null,
  BenefitsStep = null,
  ProcessStep = null,
  PrepareStep = null,
  ExpectStep = null,
  CostStep = null,
}) => {
  const defaultCards = [
    {
      title: "Root Chakra – Rubies",
      description:
        "",
    },
    {
      title: "Sacral chakra",
      description:
        "The sacral chakra, or Svadhisthana, is located just below your belly button. This chakra is responsible for your sexual and creative energy. It’s also linked to how you relate to your emotions as well as the emotions of others",
    },
    {
      title: "Solar plexus chakra",
      description:
        "The solar plexus chakra, or Manipura, is located in your stomach area. It’s responsible for confidence and self-esteem, as well as helping you feel in control of your life",
    },
    {
      title: "Heart chakra",
      description:
        "The heart chakra, or Anahata, is located near your heart, in the centres of your chest. It comes as no surprise that the heart chakra is all about our ability to love and show compassion.",
    },
    {
      title: "Throat chakra",
      description:
        "The throat chakra, or Vishuddha, is located in your throat. This chakra has to do with our ability to communicate verbally.",
    },
    {
      title: "Third eye chakra",
      description:
        "The third eye chakra, or Ajna, is located between your eyes. You can thank this chakra for a strong gut instinct. That’s because the third eye is responsible for intuition. It’s also linked to imagination.",
    },
    {
      title: "Crown chakra",
      description:
        "The crown chakra, or Sahasrara, is located at the top of your head. Your Sahasrara represents your spiritual connection to yourself, others, and the universe. It also plays a role in your life’s purpose",
    },
  ];
  const UnblockingSteps = [
    {
      heading: "Yoga postures",
    },
    {
      heading: "Breathing practices to encourage the flow of energy",
    },
    {
      heading: "Meditation to bring about clarity of mind",
    },
  ];

  const BenefitsSteps = [
    {
      heading: "Restore flow and balance of energy in their body and mind",
    },
    {
      heading: "Release the imbalances or blockages in the body, mind, emotions and spirit",
    },
    {
      heading: "Cleanse the energy body as it balances all the chakras",
    },
    {
      heading: "Heal various life issues and problems such as chronic pain, trauma, anxiety and stress",
    },
    {
      heading: "Heal various emotional and spiritual issues",
    },
    {
      heading: "Enhance focus, mental clarity, concentration and memory",
    },
    {
      heading: "Decreasing negative and limiting thought patterns",
    },
    {
      heading: "Improve and enrich relationships",
    },
    {
      heading: "Achieve their goals",
    },
    {
      heading: "Establish and strengthen the connection with their higher consciousness",
    },
    {
      heading: "Channel the divine universal life force energy",
    },
    {
      heading: "Generate and maintain an enhanced sense of wellbeing and positivity",
    },
  ];
  const ProcessSteps = [
    {
      heading: "Root Chakra – Rubies",
    },
    {
      heading: "Second Chakra or Womb Chakra – Moonstone",
    },
    {
      heading: "Third Chakra or Solar Plexus Chakra – Topaz",
    },
    {
      heading: "Second Chakra or Womb Chakra – Moonstone",
    },
    {
      heading: "Fourth Chakra or Heart Chakra – Rose quartz",
    },
    {
      heading: "Fifth Chakra or Throat Chakra – Turquoise",
    },
    {
      heading: "Sixth Chakra or the Third Eye – Amethyst",
    },
    {
      heading: "Seventh Chakra or Crown Chakra – Clear quartz",
    },
  ];
  const PrepareSteps = [
    {
      heading:
        "Wear clean, loose-fitting, comfortable clothing. You may wish to wear natural fabrics such as cotton, linen, or silk.",
    },
    {
      heading: "Remove your shoes, jewellery, and glasses before your session.",
    },
    {
      heading: "Keep your phone switched off or leave it behind.",
    },
  ];
  const ExpectSteps = [
    {
      heading:
        "Before the crystal healing session begins, the therapist sits down with the client to ask them about their troubles. Some people seek therapy for emotional or spiritual reasons, while others want to address physical pain. The crystals that a therapist chooses to work with depends on the client's needs",
    },
    {
      heading:
        "Once the therapist has selected the appropriate crystals, they ask the client to lie down on the treatment table fully clothed while they set their intention to heal. They then place the crystals around the client, allowing their body to self-heal and eliminate any blockages present in their chakras.",
    },
    {
      heading:
        "A crystal therapy session usually lasts for an hour and leaves the client feeling relaxed, rejuvenated and light.",
    },
  ];
  const CostSteps = [
    {
      heading:
        "The energy healing of a single crystal therapy session will cost ₹1500 and the duration will be approx. 30 minutes",
    },
  ];

  if (!CostStep) CostStep = CostSteps;
  if (!ExpectStep) ExpectStep = ExpectSteps;
  if (!ProcessStep) ProcessStep = ProcessSteps;
  if (!PrepareStep) PrepareStep = PrepareSteps;
  if (!BenefitsStep) BenefitsStep = BenefitsSteps;
  if (!UnblockingStep) UnblockingStep = UnblockingSteps;
  if (!cards) cards = defaultCards;
  return (
    <AnimationRevealPage>
      <Container>
        {/* <ContentWithPaddingXl> */}
        <MainHeader
          heading="What is Crystal therapy?"
          primaryButtonText="Order Now"
          primaryButtonUrl="https://order.now.com"
          imageInsideDiv={false}
          description="Crystal therapy is a form of vibrational medicine that uses the application of crystals or gemstones to facilitate and enhance physical, mental, and spiritual healing. It is based on a belief that powerful energy is stored in certain crystals and gems and they can positively affect imbalances in the energy fields or chakras and therefore promote good health and general well-being."
          imageSrc={aura}
          imageCss={Object.assign(tw`bg-cover`, imageCss)}
          imageContainerCss={tw`md:w-1/2 h-auto`}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
          textOnLeft={true}
        />

        <Text>
          <h1>How Does Crystal Therapy Work?</h1>

          <p>
            During a session of crystal therapy, the patient lies clothed on a
            table or on a comfortable bed. Various stones of specific type,
            colour, or pattern are placed around the body, specific to the
            chakra points. The stones are left there for around 20 to 40
            minutes. When a crystal or precious stone is held or applied
            topically, the patient accepts the vibration that the crystal or
            stone has to offer, either unconsciously or intentionally. The
            interaction between the crystal's vibration and the body's vibration
            will trigger a series of responses such as creating a vibrational
            flow or spontaneous energy.
          </p>
          <p>
            Crystal Therapy is used as a channel for pure positive energy while
            the client is responsible for accepting the healing. It promotes
            deep relaxation and can initiate the release of various physical and
            emotional blockages.
          </p>
          {/* <Features
            heading="What Are Crystal Therapy Chakras?"
            description="Supporting the seven chakras with specific types of crystals improves overall health and wellbeing. Here's a list of the body's energy points, or chakras, and the corresponding stones that may be used for healing:"
            cards={cards}
          /> */}
          {/* <Unblocking
            heading="Benefits of Aura Cleansing"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={UnblockingStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          /> */}
          <Process
            heading="What Are Crystal Therapy Chakras?"
            Subheading="Supporting the seven chakras with specific types of crystals improves overall health and wellbeing. Here's a list of the body's energy points, or chakras, and the corresponding stones that may be used for healing:"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={ProcessStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Benefits
            heading="What Are the Benefits of Crystal Therapy?"
            Subheading="Crystals channel positive energy and regulate the body's natural frequencies to restore balance and harmony between the mental, emotional and physical wellbeing. Coming into contact with crystals allows a person to reconnect with their higher self and reap the following benefits:"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={BenefitsStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Prepare
            heading="How to prepare for your session?"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={PrepareStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Expect
            heading="What to expect after your session?"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={ExpectStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Cost
            heading="How much does a crystal therapy session cost?"
            textOnLeft={false}
            imageDecoratorBlob={false}
            steps={CostStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
        </Text>
        {/* </ContentWithPaddingXl> */}
      </Container>
    </AnimationRevealPage>
  );
};
