import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import ganeshChaturthi from "../../images/Festivals/ganesh-chaturthi.webp"
import shivji from "../../images/Festivals/shivji-god.jpg"
import guptnavratri from "../../images/Festivals/gupt-navratri.jpg"
import saraswatipuja from "../../images/Festivals/saraswati-puja.jpg"
import budhpurnima from "../../images/Festivals/budhpurnima.jpg"
import hanumanjayanti from "../../images/Festivals/hanuman-jayanti.jpg"
import holi from "../../images/Festivals/holi.jpeg"
import navratri from "../../images/Festivals/navratri.jpg"
import basantpanchmi from "../../images/Festivals/basant-papnchmi.webp"
import dashara from "../../images/Festivals/dashara.jpg" 
import diwali from "../../images/Festivals/diwali.jpg" 

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: ganeshChaturthi,
      subtitle: "Ganesh Chaturthi",
      title: "Ganesh Chaturthi",
      description:
        "Ganesh chaturthi is also known as vinayaka chavithi, It marks the birthday of a beloved lord Ganesha. It is an auspicious Hindu festival which is celebrated for 10 days every year. In Hinduism, the 10 days festival marks the birth of elephant headed deity Ganesha, the God of prosperity and wisdom. Ganesha is known as the God of wealth, sciences, knowledge, wisdom and prosperity, and that's why most Hindus remember him and seek his blessings before starting any important work.",
      url: "https://timerse.com",
    },

    {
      imageSrc: shivji,
      subtitle: "Shivji",
      title: "Shivji",
      description:
        "Shivji (Siva) is one of the most important gods in Hinduism and a member of the holy trinity (trimurti) with Brahma and Vishnu. Shiva is a complex character who may represent goodness and benevolence, and he serves as the Protector. Shiva is also associated with Time, and in this capacity, he is both the destroyer and creator of all things. He is depicted as an omniscient yogi who lives an ascetic life on Mount Kailash as well as a householder with his wife Parvati and his two children, Ganesha and Kartikeya. ",
      url: "https://timerse.com",
    },

    {
      imageSrc: guptnavratri,
      subtitle: "Gupt Navratri",
      title: "Gupt Navratri",
      description:
        "Gupta navratri is a period of nine days and nights dedicated to the nine forms of shakti (mother goddess) in the Hindu month of ashada. Gupta navratri is also known as Ashadha gupt navratri is observed as the Ashada shukla Paksha. The nine days of gupt Navratri is considered highly powerful, meritorious and is beneficial for shakti and tantra Sadhanas and accomplishments. As most of the Tantric rituals and sadhanas are done in secrecy hence the Ashadha and Magha Navratri got the name Gupta navratri. ",
      url: "https://timerse.com",
    },
    {
      imageSrc: saraswatipuja,
      subtitle: "Saraswati Puja",
      title: "Saraswati Puja",
      description:
        "Maa (Mother) Saraswati is a Hindu goddess who represents education, creativity, and music. Maa Saraswati remains a champion of the arts and intellectual rigour through poetry, literature, and music. Identified as a patron of the arts and sciences, Maa Saraswati is also known as the inventor of Sanskrit. Lord Brahma is known as a generator of the universe. He also created Maa Saraswati, whose knowledge and creativity was required by him to imbue his various other creations. Thus, Maa Saraswati is considered his daughter and his cerebral progeny.",
      url: "https://timerse.com",
    },
    {
      imageSrc: budhpurnima,
      subtitle: "Budh Purnima",
      title: "Budh Purnima",
      description:
        "Buddha Purnima is a festival that brings people from different cultures and backgrounds together to celebrate the life and teachings of the Buddha. It is a day to reflect on his teachings of love, compassion, and kindness and to inspire others to follow his path. Buddha Purnima is celebrated to commemorate the birth anniversary of Lord Buddha. Gautam Buddha was the founder of the religion, Buddhism and he was known as spiritual Guru who encouraged people to follow the path of spirituality.",
      url: "https://timerse.com",
    },
    {
      imageSrc: hanumanjayanti,
      subtitle: "Hanuman Jayanti",
      title: "Hanuman Jayanti",
      description:
        "Hanuman Jayanti is a Hindu festival that marks the birth of Hanuman, the monkey god known for selfless service and courage. Hanuman is the symbol of strength, energy and devotion. He is worshipped as a deity with supreme powers and conquers evil spirits. Lord Hanuman was the eleventh incarnation (Rudra Avatar) of Lord Shiva. He was a great devotee of Lord Rama. His loyalty and affection towards Lord Rama are inscribed in Ramayana. He is known as ‘Monkey God’.",
      url: "https://timerse.com",
    },
    {
      imageSrc: holi,
      subtitle: "Holi",
      title: "Holi",
      description:
        "Holi is known as the festival of colours. It is one of the most important festivals in India. Holi is celebrated each year with zeal and enthusiasm in the month of March by followers of the Hindu religion. Holi is a time to reach out with the colours of joy. It is time to love and forgive. It is the time that expresses the happiness of being loved and to be loved through colours. The moral of the holi festival is that good always wins over evil, and those who seek to torment the faithful will be destroyed. To celebrate the story, large bonfires are burned during Holi. In many parts of India, a dummy of Holika is burned on the fire.",
      url: "https://timerse.com",
    },
    {
      imageSrc: navratri,
      subtitle: "Navratri",
      title: "Navratri",
      description:
        "Navratri is the festival, which is devoted to the worship of Goddess Shakti. It is a nine-day holiday. During these nine days, people worship and pray to Goddess Shakti. Navratri has special significance in Hindu Religion and is one of the most awaited festivals across the world. The legend associated with Navratri speaks about the great battle between the powerful demon Mahishasura and Goddess Durga. Each year, on each day of Navratri, an incarnation of “Goddess Durga” is worshipped to celebrate the day of her victory over Mahishasura, and the ultimate victory of ‘Good over Evil.”   ",
      url: "https://timerse.com",
    },
    {
      imageSrc: basantpanchmi,
      subtitle: "Basant Panchmi",
      title: "Basant Panchmi",
      description:
        "Basant Panchami is a Hindu festival, celebrated during Spring usually in February. Goddess Saraswati is worshipped on this auspicious day, who is the goddess of knowledge, music, and learning. To achieve wisdom and art from her people celebrate Basant Panchami as Saraswati Pooja. This day is very auspicious; people start new work, get married or start anything new on this day.People wear yellow clothes, cook special dishes, exchange sweets, and decorate their homes with yellow colour. Basant Panchmi, the festival of wisdom and knowledge is celebrated with a lot of exuberance all over the country, gearing up for special celebrations. ",
      url: "https://timerse.com",
    },
    {
      imageSrc: dashara,
      subtitle: "Dussehra",
      title: "Dussehra",
      description: "Dussehra is a major Hindu festival celebrated by Hindus in the Indian subcontinent. The festival symbolises the victory of good over evil. The festival majorly celebrates the victory of Rama over Ravana. Dussehra is celebrated after a nine-day long Durga puja festival. Dussehra is integral to not only Hindu belief but it also entails the Indian philosophy of Truth always triumphs. Hindus of India had been celebrating Dussehra for thousands of years. ",
      url: "https://timerse.com",
    },
    {
      imageSrc: diwali,
      subtitle: "Diwali",
      title: "Diwali",
      description: "Diwali is one of the major religious festivals in Hinduism, Jainism and Sikhism. The name is derived from the Sanskrit term deepavali meaning row of lights. The festival generally symbolises the victory of light over darkness,good over evil and knowledge over ignorance. For Hindus, Diwali is linked to the ancient legend of Lord Rama, who was deprived of his kingdom and sent into exile for 14 years. Diwali celebrates Rama's eventual defeat of the evil spirit Ravana, and his triumphant return to his home.",
      url: "https://timerse.com",
    },
  ];

  return (
    <Container style={{ paddingTop: "16vh" }}>
      {/* <SingleColumn> */}
      <HeadingInfoContainer>
        <HeadingTitle>Festivals</HeadingTitle>
        <HeadingDescription>
          Here are some of the most trending courses
        </HeadingDescription>
      </HeadingInfoContainer>

      <Content>
        {cards.map((card, i) => (
          <Card key={i} reversed={i % 2 === 1}>
            <Image imageSrc={card.imageSrc} />
            <Details>
              <Subtitle>{card.subtitle}</Subtitle>
              <Title>{card.title}</Title>
              <Description>{card.description}</Description>
              {/* <Link href={card.url}>Read More</Link> */}
            </Details>
          </Card>
        ))}
      </Content>
      {/* </SingleColumn> */}
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
