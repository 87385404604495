import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import Cartomancy from "../../images/upcomming/cartomancy.jpg";
import Reiki from "../../images/cards/reiki.jpg";
import kurumaReiki from "../../images/Attunements/karuna-reiki.jpg";
import MoneyReiki from "../../images/Attunements/Reiki-Money.webp";
import VioletFlames from "../../images/Attunements/violet-flames.jpg";
import greentarama from "../../images/Attunements/greentarama.jpg";
import kamkhyadevi from "../../images/upcomming/kamkhya.jpg";
import { PrimaryButton } from "../misc/Buttons";
import { Dialog } from "@mui/material";
import { AppointmentSrtipe } from "../../../Students/Payment/appointmentStripe";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-700`;

export default () => {
  const cards = [
    {
      imageSrc: Reiki,
      subtitle: "Reiki",
      price: 1100,
      title: "Reiki All Level",
      description:
        "Reiki is a form of energy healing that is based on the belief that there is a universal life force energy that can be channeled to promote healing. Reiki Level 1 teaches the basics of Reiki, such as the history of Reiki, the five principles of Reiki, the symbols and their meanings, hand positions for self-healing and giving Reiki to others, and how to channel Reiki energy. Reiki Level 2 builds on the foundation of Level 1 and teaches more advanced techniques, such as distance healing, working with Reiki on a more spiritual level, and how to use Reiki to clear negative energy and enhance creativity and intuition. Reiki Level 3 teaches how to attune others to Reiki and how to teach Reiki classes.",
      url: "https://timerse.com",
    },

    {
      imageSrc: kurumaReiki,
      subtitle: "Karuna Reiki",
      price: 2100,
      title: "Karuna Reiki",
      description:
        "Karuna Reiki is healing energy which assists us in awakening universal compassion and the wisdom in one’s soul. When combined with energy, our state of consciousness in compassion has a great transformational power. Karuna Reiki is derived from a collection of “additional symbols” where some came along with attunement processes.Chanting and toning is included in Karuna Reiki in order to depend on the healing process.",
      url: "https://timerse.com",
    },

    {
      imageSrc: MoneyReiki,
      subtitle: "Money Reiki ",
      price: 1100,
      title: "Money Reiki",
      description:
        "Money Reiki is a healing therapy that heals all the money-related issues in all spheres of life where finance is involved whether it be earning well or saving it. Money reiki works with the spiritual energy of money; this energy is more about clearing the energies related to money.This Money Reiki will help in breaking the blockages and free up energy to get enough money.",
      url: "https://timerse.com",
    },
    {
      imageSrc: VioletFlames,
      subtitle: "Violet Flames",
      price: 1100,
      title: "Violet Flames",
      description:
        "The Violet Flame is a spiritual tool to transmute energy, remove obstacles, create change, dissolve traumas, and to heal on all levels. A powerful violet flame meditation created to help you transmute negative energy and surround yourself in the healing loving energy of the violet ray. Tuned to 432 Hz as an added boost to help align you to the heartbeat of the earth.This was originally created in 2019 to assist with personal and global healing. ",
      url: "https://timerse.com",
    },
    {
      imageSrc: greentarama,
      subtitle: "Green Tara Maa",
      price: 1100,
      title: "Green Tara Maa",
      description:
        "The green tara mantra, om Tara tuttare ture soha is one of the most recited in Tibetan Buddhism and is used as a veil of protection as practitioners face physical or emotional challenges. Green Tara is usually depicted as a compassionate being ready to step down from her lotus throne to offer comfort and protection from all of the sufferings we experience in the world. It helps you release hope for a particular outcome and bring the energy back to yourself, generating inner peace and clarity. ",
      url: "https://timerse.com",
    },
    {
      imageSrc: kamkhyadevi,
      subtitle: "Kamakhya Devi",
      price: 1100,
      title: "Kamakhya Devi",
      description:
        "Kamakhya, a mother goddess, is a Shakta Tantric deity, considered to be the embodiment of kama (desire), she is regarded as the goddess of desires. Counted among the most sacred shakti peethas, the temple is believed to be the place where the womb of the goddess fell. Thus, it is also known as the Supreme female power and fertility. Her abode - kamakhya temple is located in the region of Assam, India.",
      url: "https://timerse.com",
    },
  ];

  var statistics = null;

  const defaultStatistics = (cards) => [
    {
      key: "Price",
      value: cards.price,
    },
    {
      key: "Duration",
      value: "30 min",
    },
  ];

  const [open, setOpen] = useState(false);
  const [detail, setDetails] = useState({});
  const handleClickOpen = (title, price, image) => {
    setOpen(true);
    const data = { title: title, price: price, image: image };
    setDetails(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container style={{ paddingTop: "16vh" }}>
      {/* <SingleColumn> */}
      <HeadingInfoContainer>
        <HeadingTitle>Attunements</HeadingTitle>
        <HeadingDescription>
          Here are some of the most trending courses
        </HeadingDescription>
      </HeadingInfoContainer>

      <Content>
        {cards.map((card, i) => (
          <Card key={i} reversed={i % 2 === 1}>
            <Image imageSrc={card.imageSrc} />
            <Details>
              <Subtitle>{card.subtitle}</Subtitle>
              <Title>{card.title}</Title>
              <Description>{card.description}</Description>
              {/* <Link href={card.url}>Read More</Link> */}
              <Statistics>
                {defaultStatistics(card).map((stat, innerIndex) => (
                  <Statistic key={innerIndex}>
                    <Value>{stat.value}</Value>
                    <Key>{stat.key}</Key>
                  </Statistic>
                ))}
              </Statistics>
              {/* <PrimaryButton
                onClick={() => handleClickOpen(card.title, card.price, card.imageSrc)}
              >
                Book Now
              </PrimaryButton>
              <Dialog open={open} onClose={handleClose} fullScreen>
                <AppointmentSrtipe detail={detail} />
              </Dialog> */}
            </Details>
          </Card>
        ))}
      </Content>
      {/* </SingleColumn> */}
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
