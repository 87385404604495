import {
  GET_LIBRARY_COURSE_RETRIVE_REQUEST,
  GET_LIBRARY_COURSE_RETRIVE_SUCCESS,
  GET_LIBRARY_COURSE_RETRIVE_FAILED,
} from "../constant";

export const libraryDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LIBRARY_COURSE_RETRIVE_REQUEST:
      return { ...state, loading: true };
    case GET_LIBRARY_COURSE_RETRIVE_SUCCESS:
      return { ...state, loading: false, payload: action.payload };
    case GET_LIBRARY_COURSE_RETRIVE_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
