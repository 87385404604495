import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {Box, Alert} from "@mui/material";

export default function Error({message}) {
  return (
      <Alert severity="error">{message}</Alert>
  );
}

// Add a CSS media query to adjust the height based on viewport width
const styles = `
  @media (min-width: 600px) {
    .CircularIndeterminate-root {
      height: calc(100vh - 64px);
    }
  }
`;

// Add the styles to the head of the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
