import Navbar from "../../components/MyComponents/Navbar";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Footer from "../../components/MyComponents/Footer";
import Crystal from "../../components/Services/Crystal";

export default () => {
  return (
    <>
      <AnimationRevealPage>
        <Navbar />
        <Crystal />
      </AnimationRevealPage>
      <Footer />
    </>
  );
};
