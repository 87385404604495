import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { createAppointment } from "../../../redux/actions/stripeAction";
import { Typography, Box, Fade } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const AppointmentConfirmation = () => {
  const stripe = useSelector((state) => state.stripe);
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
      const title = queryParams.get("title");
      const price = queryParams.get("price");


  const dispatch = useDispatch();
  console.log(email);
  console.log(title);
  console.log(price)

  useEffect(() => {
    dispatch(
      createAppointment({
        title: title,
        email: email,
        price: price,
        payment_intent_client_secret_data: param.get(
          "payment_intent_client_secret"
        ),
        payment_intent_data: param.get("payment_intent"),
        payment_status: param.get("redirect_status"),
      })
    );
  }, []);
  console.log(param.get("payment_intent_client_secret"));

  useEffect(() => {
    if (stripe.payload && stripe.payload.message === "Appointment Done")
      setTimeout(() => {
        navigate("/appointment");
      }, 5000);
  }, [stripe]);

  const logoStyles = {
    marginRight: "8px",
    fontSize: "4rem",
    animation: "spin 1s ease-in-out",
    animationIterationCount: 1,
  };

  console.log(param.get("redirect_status"));
  console.log(stripe.payload);

  return (
    <>
      {stripe.payload ? (
        stripe.payload.message === "Appointment Done" ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
          >
            <Fade in={true} timeout={2000}>
              <Typography variant="h4" align="center" fontWeight={"bold"}>
                <CheckCircleOutlineIcon
                  sx={{
                    fontSize: "32px",
                    color: "blue",
                    margin: "-2%",
                    marginRight: "1%",
                  }}
                />
                Payment Done Successfully{" "}
                <span style={{ display: "inline-block", marginLeft: "1rem" }}>
                  {/* Your checkmark icon goes here */}
                </span>
              </Typography>
            </Fade>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
          >
            <Fade in={true} timeout={2000}>
              <Typography variant="h4" align="center" fontWeight={"bold"}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: "32px",
                    color: "red",
                    margin: "-2%",
                    marginRight: "1%",
                  }}
                />
                Error! Please try again{" "}
                <span style={{ display: "inline-block", marginLeft: "1rem" }}>
                  {/* Your checkmark icon goes here */}
                </span>
              </Typography>
            </Fade>
          </Box>
        )
      ) : null}
    </>
  );
};
