import axios from "axios";
import apiurl from "../../constant/config";
import { AUTH_FORGOT_SUCCESS, AUTH_FORGOT_REQUEST, AUTH_FORGOT_FAILED, AUTH_VERIFY_OTP_REQUEST, AUTH_VERIFY_OTP_SUCCESS, AUTH_VERIFY_OTP_FAILED, AUTH_NEW_PASSWORD_REQUEST, AUTH_NEW_PASSWORD_SUCCESS, AUTH_NEW_PASSWORD_FAILED } from "../constant";

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_FORGOT_REQUEST });

    // Send the email to the backend for handling the "Forgot Password" request
    const { data } = await axios.post(`${apiurl}/api/user/forget-password/`, {
      email,
    });

    // console.log("Response data:", data.message); // Log the response data for inspection

    // You may handle the response data if needed, but for this example, let's keep it simple.

    dispatch({
      type: AUTH_FORGOT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: AUTH_FORGOT_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const verifyOtp = (otp, email) => async (dispatch, getState) => {
  try {
    dispatch({ type: AUTH_VERIFY_OTP_REQUEST });

    // Send the email and OTP to the backend for OTP verification
    const { data } = await axios.post(`${apiurl}/api/user/otp-varification/`, {
      email,
      otp,
    });

    // If the OTP is successfully verified, you may handle the response data if needed, but for this example, let's keep it simple.

    dispatch({
      type: AUTH_VERIFY_OTP_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: AUTH_VERIFY_OTP_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const newPassword = (email, newPass) => async (dispatch, getState) => {
  try {
    dispatch({ type: AUTH_NEW_PASSWORD_REQUEST });

    // Send the email and OTP to the backend for OTP verification
    const { data } = await axios.put(`${apiurl}/api/user/forget-password/`, {
      email,
      newPass,
    });

    // If the OTP is successfully verified, you may handle the response data if needed, but for this example, let's keep it simple.

    dispatch({
      type: AUTH_NEW_PASSWORD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: AUTH_NEW_PASSWORD_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};