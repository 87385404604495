import { GET_ACCOUNT_FAILED, GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS } from "../constant"

export const accountReducer = (state = {},action) => {
  switch(action.type) {
    case GET_ACCOUNT_REQUEST:
      return { loading: true }
    case GET_ACCOUNT_SUCCESS:
        return { loading: false, payload: action.payload }
    case GET_ACCOUNT_FAILED:
        return { loading: false, error: action.payload }
    default:
      return state
  }
}