import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SubjectSelect({value, setValue}) {

  return (
    <Box sx={{ minWidth: 20 }}>
      <FormControl fullWidth>
        <Select
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <MenuItem value="English">English</MenuItem>
          <MenuItem value="Maths">Maths</MenuItem>
          <MenuItem value="Science">Science</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
