import axios from "axios";
import {
  GET_LIVE_CLASS_FAILED,
  GET_LIVE_CLASS_REQUEST,
  GET_LIVE_CLASS_SUCCESS,
  GET_UPCOMING_CLASS_FAILED,
  GET_UPCOMING_CLASS_REQUEST,
  GET_UPCOMING_CLASS_SUCCESS,
  GET_PREVIOUS_CLASS_FAILED,
  GET_PREVIOUS_CLASS_REQUEST,
  GET_PREVIOUS_CLASS_SUCCESS,
  GET_ADD_CLASS_FAILED,
  GET_ADD_CLASS_REQUEST,
  GET_ADD_CLASS_SUCCESS,
  GET_TRENDING_CLASS_FAILED,
  GET_TRENDING_CLASS_REQUEST,
  GET_TRENDING_CLASS_SUCCESS,
  CREATE_TRENDING_CLASS_REQUEST,
  CREATE_TRENDING_CLASS_SUCCESS,
  CREATE_TRENDING_CLASS_FAILED,
} from "../constant";
import apiurl from "../../constant/config";

export const fetchLiveClasses = () => async (dispatch) => {
  try {
    dispatch({ type: GET_LIVE_CLASS_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.get(
      `${apiurl}/api/st/liveclass/list`,
      {
        headers: { Authorization: `Bearer ${access}` },
      }
    );

    dispatch({
      type: GET_LIVE_CLASS_SUCCESS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: GET_LIVE_CLASS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchTeacherLiveClasses = () => async (dispatch) => {
  try {
    dispatch({ type: GET_LIVE_CLASS_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.get(`${apiurl}/api/teacher/liveclass/list`, {
      headers: { Authorization: `Bearer ${access}` },
    });

    dispatch({
      type: GET_LIVE_CLASS_SUCCESS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: GET_LIVE_CLASS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchUpcomingClasses = () => async (dispatch) => {
  try {
    dispatch({ type: GET_UPCOMING_CLASS_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.get(
      `${apiurl}/api/teacher/upclass/list`,
      { headers: { Authorization: `Bearer ${access}` } }
    );

    dispatch({
      type: GET_UPCOMING_CLASS_SUCCESS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: GET_UPCOMING_CLASS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchPreviousClasses = () => async (dispatch) => {
  try {
    dispatch({ type: GET_PREVIOUS_CLASS_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.get(
      `${apiurl}/api/teacher/pastclass/list`,
      { headers: { Authorization: `Bearer ${access}` } }
    );

    dispatch({
      type: GET_PREVIOUS_CLASS_SUCCESS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: GET_PREVIOUS_CLASS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchTrendingClasses = () => async (dispatch) => {
  try {
    dispatch({ type: GET_TRENDING_CLASS_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.get(`${apiurl}/api/trending/list/`, {
      // headers: { Authorization: `Bearer ${access}` },
    });

    dispatch({
      type: GET_TRENDING_CLASS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TRENDING_CLASS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};



export const createTrendingClass = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TRENDING_CLASS_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const requestData = {
      trending_class: formData.trending_class, // Provide the trending class ID
      // class_rank: formData.class_rank // Ensure the class rank value is in lowercase
    };

    const config = {
      headers: {
        Authorization: `Bearer ${access}`,
        "Content-Type": "application/json", // Set the content type explicitly
      },
    };

    const { data } = await axios.post(
      `${apiurl}/api/trendingclass/`,
      requestData,
      config
    );

    dispatch({
      type: CREATE_TRENDING_CLASS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_TRENDING_CLASS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};




export const createClass = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ADD_CLASS_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.post(
      `${apiurl}/api/teacher/classup/create`,
      formData,
      { headers: { Authorization: `Bearer ${access}` } }
    );
    console.log(data.status);

    dispatch({
      type: GET_ADD_CLASS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ADD_CLASS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
