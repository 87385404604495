import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  styled,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import SignupImg from "../../assets/images/signup.svg";
import MuiAlert from "@mui/material/Alert";
import googleLogo from "../../assets/images/google_icon.svg";
import { CheckIcon, UnCheckedIcon } from "../../assets/icons/checkIcon";
import CustomButton from "../../components/common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  verifyOtp,
  newPassword,
} from "../../redux/actions/forgotAction";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fffff" : "#2b2b2b",
    // border: "4px solid #D9D9D9",
    fontSize: 16,
    width: "100%",
    // padding: "12px 24px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      borderColor: "#9D78BD",
    },
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Forgetpassword = () => {
  const [otp, setOtp] = React.useState(false);
  const [newpass, setNewpass] = React.useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const { loading, verify, error, success, verifyError, newPassError } =
    useSelector((state) => state.forgot); // Replace 'auth' with your actual state slice name
  const dispatch = useDispatch();
  const [enteredOtp, setEnteredOtp] = useState("");
  const [enterNewPass, setEnterNewPass] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const forgot = useSelector((state) => state.forgot);
  const [showMessage, setShowMessage] = useState(false);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (!newEmail) {
      setEmailError("Please enter an email address");
      setIsDisabled(true);
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
      setEmailError("Please enter a valid email address");
      setIsDisabled(true);
    } else {
      setEmailError("");
      setIsDisabled(false); // Enable the button when the email is valid.
    }
  };

  const handleOtpOpen = () => {
    setOtp(true);
  };

  const handleOtpClose = () => {
    setOtp(false);
  };

  const handleNewpassOpen = () => {
    setNewpass(true);
  };

  const handleNewpassClose = () => {
    setNewpass(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(forgotPassword(email));
      console.log("Full response:", response); // Log the entire response object for inspection

      // Assuming the response message is directly in the response object, not under a "data" property
      if (error === "Request failed with status code 400") {
        const showMessage =
          "Your request requires admin approval before you can login as a teacher.";
        setShowMessage(showMessage);
      } else if (error === "Request failed with status code 401") {
        const showMessage = "Unable to Determine";
        setShowMessage(showMessage);
      } else if (error === "Request failed with status code 404") {
        const showMessage = "Unable to Determine && Email is not Registered";
        setShowMessage(showMessage);
      } else {
        setShowMessage(false);
        handleOtpOpen();
      }

      // Display the response message here (if needed)
      console.log("Response message:", response.forgot);
    } catch (error) {
      console.log("Error:", error); // Log the error if any occurred during the API call
      // You can handle any additional error logic here if needed
    }
  };

  console.log(error);

  const handleAgreeVerify = async () => {
    // Dispatch the verifyOtp action with the entered OTP
    const response = await dispatch(verifyOtp(enteredOtp, email));
    if (verifyError === "Request failed with status code 400") {
      const showMessage =
        "Incorrect Otp Entered";
      setShowMessage(showMessage);
    } else if (verifyError === "Request failed with status code 401") {
      const showMessage = "Unable to Determine";
      setShowMessage(showMessage);
    } else if (verifyError === "Request failed with status code 404") {
      const showMessage = "Unable to Determine && Email is not Registered";
      setShowMessage(showMessage);
    } else {
      setShowMessage(false);
      handleOtpClose();
      handleNewpassOpen();
    }
  };
  console.log(verifyError);

  const handleNewPassword = () => {
    // Dispatch the verifyOtp action with the entered OTP
    dispatch(newPassword(email, enterNewPass));
    const showMessage = "Password Chanaged Sucessfully";
    setShowMessage(showMessage);
    handleNewpassClose(); // Close the OTP verification dialog
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
      }}
    >
      <Grid
        item
        sm={6}
        sx={{
          placeContent: "center",
          backgroundColor: "#E6F2FF",
          backgroundSize: "contain",
          backgroundPosition: "center",
          display: {
            xs: "none",
            sm: "grid",
          },
        }}
      >
        <img
          src={SignupImg}
          alt="forgetpassword"
          style={{ width: "100%" }}
          draggable={false}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            px: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {error ? <Alert severity="error">{error}</Alert> : null}

          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontWeight: "700",
            }}
          >
            Forgot Your Password !
          </Typography>
          {showMessage && <Alert severity="info">{showMessage}</Alert>}

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 4 }}
          >
            <Typography
              variant="h5"
              pb={"2%"}
              sx={{
                fontWeight: "700",
              }}
            >
              You can reset your password Here!
            </Typography>
            <StyledInput
              id="email"
              value={email}
              name="email"
              label="Email"
              onChange={handleEmailChange}
              fullWidth
            />
            <CustomButton
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, color: "#D9D9D9", fontSize: "18px" }}
              type="submit"
              disabled={isDisabled}
            >
              Send Verification Link
            </CustomButton>
            <Dialog
              open={otp}
              onClose={handleOtpClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  minWidth: "60vh",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {"Use Google's location service?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Enter the opt
                </DialogContentText>
                {showMessage && <Alert severity="info">{showMessage}</Alert>}

                <MuiOtpInput value={enteredOtp} onChange={setEnteredOtp} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleOtpClose}>Disagree</Button>
                <Button onClick={handleAgreeVerify} autoFocus>
                  {" "}
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={newpass}
              onClose={handleNewpassClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  minWidth: "60vh",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {"Use Google's location service?"}
              </DialogTitle>
              <DialogContent style={{ paddingTop: "20px" }}>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <TextField
                    id="outlined-basic"
                    label="New Password"
                    value={enterNewPass}
                    onChange={(event) => setEnterNewPass(event.target.value)}
                    variant="outlined"
                  />
                  {/* <TextField
                    id="outlined-basic"
                    label="Confirm New Password"
                    variant="outlined"
                  /> */}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleNewpassClose} color="primary">
                  Disagree
                </Button>
                <Button onClick={handleNewPassword} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            <Divider
              sx={{
                color: "#D9D9D9",
                "&::before, &::after": {
                  borderColor: "#9D78BD",
                },
              }}
            >
              Or
            </Divider>
          </Box>
          <Typography
            sx={{
              typography: { sm: "body1", xs: "body2", md: "h6" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            Already have an account?&nbsp;
            <Link to="/" style={{ color: "#9D78BD", textDecoration: "none" }}>
              Sign In
            </Link>
            &nbsp;here
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Forgetpassword;
