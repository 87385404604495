import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function MartialSelect({value, setValue}) {
  return (
    <Box sx={{ minWidth: 20 }}>
      <FormControl fullWidth>
        <Select
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <MenuItem value="Un married">Un married</MenuItem>
          <MenuItem value="Married">Married</MenuItem>
          <MenuItem value="Planning">Planning</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
