import React from "react";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import Routers from "./Routes";
import store from "./redux/store";
import { Provider } from "react-redux";
import GlobalStyles from "./pages/LandingPage/styles/GlobalStyles";


const App = () => {
  const theme = createTheme({
    typography: {
      fontSize: 12,
      fontFamily: "Inria Sans",
    },
    link: { fontFamily: ["Inria Sans", "sans- serif"].join(",") },
    palette: {
      primary: {
        main: "#9D78BD",
      },
      light: {
        main: "#fefefe",
      },
      dark: {
        main: "#000000",
      },
    },
  });
  return (
    <div>
      <GlobalStyles />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Routers />
        </ThemeProvider>
      </Provider>
    </div>
  );
};
export default App;
