import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "../../components/misc/Headings";
import MainHeader from "./HeaderComponent.js";
import Features from "./Benefits.js";
import aura from "../../images/cards/reiki.jpg";
import Unblocking from "./Types.js";
import Benefits from "./Types.js";
import Process from "./Types.js";
import Prepare from "./Types.js";
import Expect from "./Types.js";
import Cost from "./Types.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 mb-4`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const imageCss = tw`rounded-4xl`;

export default ({
  headingText = "Chakra",
  cards = null,
  UnblockingStep = null,
  BenefitsStep = null,
  ProcessStep = null,
  PrepareStep = null,
  ExpectStep = null,
  CostStep = null,
}) => {
  const defaultCards = [
    {
      title: "Relieves pain, anxiety, and fatigue:",
      description:
        "Reiki may help to reduce pain and anxiety. It may also help to reduce fatigue. It helps treat cancer. It helps to reduce cesarean pains. Helps to reduce phobias and deep lying fears. Also helps in relieving lower back pain in people with herniated disks.",
    },
    {
      title: "Treats depression:",
      description:
        "Reiki treatments may be used as part of a treatment plan to help relieve depression.",
    },
    {
      title: "Enhances quality of life:",
      description:
        "The positive benefits of reiki can enhance your overall well-being. Reiki is helpful in improving the quality of life with life threatening diseases as well.",
    },
    {
      title: "Boosts mood:",
      description:
        "Reiki may help to improve your mood by relieving stress, insomnia, anxiety and depression.",
    },
    {
      title: "May improve some symptoms and conditions:",
      description: "Headache, Tension, Concentration, Nausea",
    },
  ];
  const UnblockingSteps = [
    {
      heading: "Yoga postures",
    },
    {
      heading: "Breathing practices to encourage the flow of energy",
    },
    {
      heading: "Meditation to bring about clarity of mind",
    },
  ];

  const BenefitsSteps = [
    {
      heading: "Helps in making right decisions",
    },
    {
      heading: "Improves your relationships",
    },
    {
      heading: "Helps in figuring out who you are",
    },
    {
      heading: "Defines your opportunities",
    },
    {
      heading: "Improves your life",
    },
  ];
  const ProcessSteps = [
    {
      heading:
        "A typical reiki session lasts between 15 and 30 minutes. In your first session, you’ll meet with your reiki master. You’ll have a short introduction or chat about the process and your expectations or intentions. Tell your reiki master about any symptoms you want addressed or if there are places in the body on which you’d like them to focus. Also, let your master know if you have any injuries or places that are sensitive to touch",
    },
    {
      heading:
        "You’ll be instructed to lie down on a treatment table or mat. They will cover you with a blanket. Usually soft, relaxing music will be playing in the background. For the most part there won’t be any talking during the session, but you can feel free to let your master know if there’s something you need to feel more comfortable or to share what you’re experiencing.",
    },
    {
      heading:
        "The master will scan your chakras with their energies. They may touch you lightly or have their hands just above your body.",
    },
    {
      heading:
        "You may experience sensations in the body such as heat or tingling. Some people see visualizations such as colors or pictures, or having memories appear. Try to allow whatever arises to pass without attaching too much meaning to it. Your experiences may become deeper the more you continue with reiki.",
    },
  ];
  const PrepareSteps = [
    {
      heading:
        "Wear clean, loose-fitting, comfortable clothing. You may wish to wear natural fabrics such as cotton, linen, or silk.",
    },
    {
      heading: "Remove your shoes, jewellery, and glasses before your session.",
    },
    {
      heading: "Keep your phone switched off or leave it behind.",
    },
  ];
  const ExpectSteps = [
    {
      heading: "Drink plenty of water after your session.",
    },
    {
      heading: "Some people feel calm, peaceful, or energized.",
    },
    {
      heading: "You may also feel tired.",
    },
  ];
  const CostSteps = [
    {
      heading:
        "The energy healing of a single reiki sessions will cost ₹1100 and the duration will be approx. 30 minutes.",
    },
  ];

  if (!CostStep) CostStep = CostSteps;
  if (!ExpectStep) ExpectStep = ExpectSteps;
  if (!ProcessStep) ProcessStep = ProcessSteps;
  if (!PrepareStep) PrepareStep = PrepareSteps;
  if (!BenefitsStep) BenefitsStep = BenefitsSteps;
  if (!UnblockingStep) UnblockingStep = UnblockingSteps;
  if (!cards) cards = defaultCards;
  return (
    <AnimationRevealPage>
      <Container>
        {/* <ContentWithPaddingXl> */}
        <MainHeader
          heading="What is Reiki?"
          primaryButtonText="Order Now"
          primaryButtonUrl="https://order.now.com"
          imageInsideDiv={false}
          description="Reiki is a Japanese energy healing technique. The predominant form of reiki practised throughout the world today, also known as Usui reiki, was created by Dr. Mikao Usui in the early 20th century. It’s a complementary or alternative health approach. Reiki does not directly cure diseases or illnesses. Instead, it’s used as a way to manage symptoms and improve general well-being."
          imageSrc={aura}
          imageCss={Object.assign(tw`bg-cover`, imageCss)}
          imageContainerCss={tw`md:w-1/2 h-auto`}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
          textOnLeft={true}
        />

        <Text>
          <Features
            heading="5 Health benefits of reiki"
            description=""
            cards={cards}
          />
          {/* <Unblocking
            heading="Benefits of Aura Cleansing"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={UnblockingStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          /> */}
          {/* <Benefits
            heading="Benefits of Numerology"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={BenefitsStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          /> */}
          <Process
            heading="Process of Reiki session"
            Subheading=""
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={ProcessStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Prepare
            heading="How to prepare for your session?"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={PrepareStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Expect
            heading="What to expect after your session?"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={ExpectStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Cost
            heading="How much does a Sound Healing session cost?"
            textOnLeft={false}
            imageDecoratorBlob={false}
            steps={CostStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
        </Text>
        {/* </ContentWithPaddingXl> */}
      </Container>
    </AnimationRevealPage>
  );
};
