import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../../components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons.js";
import attitude from "../../images/Maditation/attitude.webp";
import { ReactComponent as SvgDotPattern } from "../../images/dot-pattern.svg";
import { AppointmentSrtipe } from "../../../Students/Payment/appointmentStripe.js";
import { Dialog } from "@mui/material";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-700`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

export default ({
  subheading = "Our Track Record",
  title = "Attitude of Gratitude",
  price = 1100,
  description = "An attitude of gratitude means making it a conscious habit to express thankfulness and appreciation for every part of your life.  Having an attitude of gratitude means you operate from a place of abundance, rather than scarcity. When you develop an attitude of gratitude you feel happier and more positive.  Gratitude increases compassion and self-care. It also makes you grateful for the people and things that matter. Developing an attitude of gratitude requires a mindset shift to make it a daily habit to be grateful for and thankful for everything you appreciate in life.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = attitude,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false,
  heading = (
    <>
      {/* We have been doing this <wbr /> since{" "}
      <span tw="text-primary-500">1999.</span> */}
      {title}
    </>
  ),
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Price",
      value: "1100",
    },
    {
      key: "Duration",
      value: "30 min",
    },
  ];

  if (!statistics) statistics = defaultStatistics;

  const [open, setOpen] = useState(false);
  const [detail, setDetails] = useState({});
  const handleClickOpen = (title, price, image) => {
    setOpen(true);
    const data = { title: title, price: price, image: image };
    setDetails(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <TwoColumn
        css={!imageInsideDiv && tw`md:items-center`}
        style={{ gap: "4vh" }}
      >
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image imageSrc={imageSrc} css={imageCss} />
          ) : (
            <img src={imageSrc} css={imageCss} alt="" />
          )}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/* {subheading && <Subheading>{subheading}</Subheading>} */}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            {/* <PrimaryButton
              onClick={() => handleClickOpen(title, price, imageSrc)}
            >
              Book Now
            </PrimaryButton>
            <Dialog open={open} onClose={handleClose} fullScreen>
              <AppointmentSrtipe detail={detail} />
            </Dialog> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
