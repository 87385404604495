import { GET_USER_PAYMENT_REQUEST, GET_USER_PAYMENT_SUCCESS, GET_USER_PAYMENT_FAILED } from "../constant"

export const paymentReducer = (state = {},action) => {
  switch(action.type) {
    case GET_USER_PAYMENT_REQUEST:
        return { loading: true }
    case GET_USER_PAYMENT_SUCCESS:
        return { loading: false, payload: action.payload }
    case GET_USER_PAYMENT_FAILED:
        return { loading: false, error: action.payload }
    default:
      return state
  }
}