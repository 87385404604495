import {
  Grid,
  Card,
  CardContent,
  FormControl,
  TextField,
  Typography,
  Button
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putAccountDetails } from "../../redux/actions/accountAction";


const Account = () => {
  const {firstname, lastname, email} = useSelector(state => state.auth.userDetails)
  const dispatch = useDispatch()

  const [first_name, setFirstName] = useState(firstname);
  const [last_name, setLastName] = useState(lastname);
  const [mobile, setMobile] = useState("");

  const handleClick = () => {
    dispatch(putAccountDetails({first_name, last_name, mobile}))
  }

   const isFormValid = first_name && last_name && mobile;
  
  
  return (
    <>
      <Card
        variant="outlined"
        sx={{ height: "100%", minHeight: "76vh", width: "100%" }}
      >
        <form>
          <CardContent
            sx={{
              p: 3,
              maxHeight: { md: "40vh" },
              textAlign: { xs: "center", md: "start" },
            }}
          >
            <Typography variant="h2" paddingBottom={3}>
              Account Details
            </Typography>
            <Grid component="form">
              <Typography fontWeight={"bold"} paddingBottom={1}>
                Email Id <br />
              </Typography>
              <TextField
                fullWidth
                disabled
                value={email}
                variant="outlined"
                sx={{ paddingBottom: "24px" }}
              />
            </Grid>
            <Grid component="form">
              <Typography fontWeight={"bold"} paddingBottom={1}>
                First Name <br />
              </Typography>
              <TextField
                fullWidth
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
                variant="outlined"
                sx={{ paddingBottom: "24px" }}
              />
            </Grid>
            <Grid component="form">
              <Typography fontWeight={"bold"} paddingBottom={1}>
                Last Name <br />
              </Typography>
              <TextField
                fullWidth
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
                variant="outlined"
                sx={{ paddingBottom: "24px" }}
              />
            </Grid>
            <Grid component="form">
              <Typography fontWeight={"bold"} paddingBottom={1}>
                Mobile Number <br />
              </Typography>
              <TextField
                fullWidth
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                variant="outlined"
                sx={{ paddingBottom: "24px" }}
              />
            </Grid>
            {/* BUTTON */}
            <Grid
              container
              justifyContent={{ xs: "center", md: "flex-end" }}
              item
              xs={6}
            >
              <Button
                sx={{ p: "1rem 2rem", my: 2, height: "3rem" }}
                component="button"
                size="large"
                variant="contained"
                color="secondary"
                disabled={!isFormValid}
                onClick={handleClick}
              >
                Update
              </Button>
            </Grid>
          </CardContent>
        </form>
      </Card>
    </>
  );
};

export default Account;
