import axios from "axios";
import {
  GET_STRIPE_CLIENT_FAILED,
  GET_STRIPE_CLIENT_REQUEST,
  GET_STRIPE_CLIENT_SUCCESS,
  POST_STRIPE_SUCCESS,
  POST_STRIPE_FAILED,
  POST_STRIPE_REQUEST,
} from "../constant";
import apiurl from "../../constant/config";

export const getClientSecret =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_STRIPE_CLIENT_REQUEST });

      const access = JSON.parse(localStorage.getItem("access"));
      const { data } = await axios.get(
        `${apiurl}/api/checkout-session/class/${id}`,
        { headers: { Authorization: `Bearer ${access}` } }
      );
      localStorage.setItem(
        "stripe-token",
        JSON.stringify(data.stripe_custom_token)
      );

      dispatch({
        type: GET_STRIPE_CLIENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_STRIPE_CLIENT_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getLibraryClientSecret =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_STRIPE_CLIENT_REQUEST });

      const access = JSON.parse(localStorage.getItem("access"));
      const { data } = await axios.get(
        `${apiurl}/api/checkout-session/library/${id}`,
        { headers: { Authorization: `Bearer ${access}` } }
      );
      localStorage.setItem(
        "stripe-token",
        JSON.stringify(data.stripe_custom_token)
      );

      dispatch({
        type: GET_STRIPE_CLIENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_STRIPE_CLIENT_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Update your stripeAction.js file
export const getAppointmentClientSecret = (price) => async (dispatch) => {
  try {
    dispatch({ type: GET_STRIPE_CLIENT_REQUEST });

    const response = await axios.post(`${apiurl}/api/appointment/pay/`, {
      price: price,
    });

    dispatch({
      type: GET_STRIPE_CLIENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STRIPE_CLIENT_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createAppointment =
  ({
    title,
    email,
    price,
    payment_intent_data,
    payment_intent_client_secret_data,
    payment_status,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: POST_STRIPE_REQUEST });

      const response = await axios.post(`${apiurl}/api/appointment/create`, {
        title,
        email,
        price,
        payment_intent_data,
        payment_intent_client_secret_data,
        payment_status,
      });

      dispatch({
        type: POST_STRIPE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: POST_STRIPE_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


export const postPaymentUpdate =
  ({
    payment_intent_client_secret_data,
    payment_intent_data,
    payment_status,
    user_bookingtype,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: POST_STRIPE_REQUEST });

      const access = JSON.parse(localStorage.getItem("access"));
      const token = JSON.parse(localStorage.getItem("stripe-token"));
      const { data } = await axios.post(
        `${apiurl}/api/userdata/create`,
        {
          token,
          payment_intent_client_secret_data,
          payment_intent_data,
          payment_status,
          user_bookingtype,
        },
        { headers: { Authorization: `Bearer ${access}` } }
      );

      dispatch({
        type: POST_STRIPE_SUCCESS,
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: POST_STRIPE_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const postLibraryPaymentUpdate =
  ({
    payment_intent_client_secret_data,
    payment_intent_data,
    payment_status,
    user_bookingtype,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: POST_STRIPE_REQUEST });

      const access = JSON.parse(localStorage.getItem("access"));
      const token = JSON.parse(localStorage.getItem("stripe-token"));
      const { data } = await axios.post(
        `${apiurl}/api/userdata/create`,
        {
          token,
          payment_intent_client_secret_data,
          payment_intent_data,
          payment_status,
          user_bookingtype,
        },
        { headers: { Authorization: `Bearer ${access}` } }
      );

      dispatch({
        type: POST_STRIPE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_STRIPE_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };



  