import Navbar from "../../components/MyComponents/Navbar";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Footer from "../../components/MyComponents/Footer";
import Reiki from "../../components/Services/Reiki";

export default () => {
  return (
    <>
      <AnimationRevealPage>
        <Navbar />
        <Reiki />
      </AnimationRevealPage>
      <Footer />
    </>
  );
};
