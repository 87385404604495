import Navbar from "../../components/MyComponents/Navbar";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Footer from "../../components/MyComponents/Footer";
import Upcomming from "../../components/MyComponents/Upcomming";
import Cratomancy from "../../components/MyComponents/Upcoming/Cratomancy";
import Cowrie from "../../components/MyComponents/Upcoming/Cowrie";
import Humkara from "../../components/MyComponents/Upcoming/Humkara";
import AsthLakhmi from "../../components/MyComponents/Upcoming/AsthLakhmi";
import Kamakhya from "../../components/MyComponents/Upcoming/kamakhya";
import Tranic from "../../components/MyComponents/Upcoming/Tranic";
import Telephaty from "../../components/MyComponents/Upcoming/Telephaty";
import Rudrakh from "../../components/MyComponents/Upcoming/Rudrakh";
import AnimalTotam from "../../components/MyComponents/Upcoming/AnimalTotam";
import MasterMerlin from "../../components/MyComponents/Upcoming/MasterMerlin";
import { SectionHeading as HeadingTitle } from "../../components/misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

export default () => {
  return (
    <>
      <AnimationRevealPage>
        <Navbar />
        <Container style={{ paddingTop: "16vh" }}>
          <HeadingInfoContainer>
            <HeadingTitle>Upcomming</HeadingTitle>
            <HeadingDescription>
              Here are some of the most Upcomming courses
            </HeadingDescription>
          </HeadingInfoContainer>
        </Container>
        {/* <Upcomming /> */}
        <Cratomancy />
        <Cowrie />
        <Humkara />
        <AsthLakhmi />
        <Kamakhya />
        <Tranic />
        <Telephaty />
        <Rudrakh />
        <AnimalTotam />
        <MasterMerlin />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
