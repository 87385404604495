import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space } from "antd";
// import logo from "../../images/Logo/circle_logo.png";
import logo from "../../images/Logo/logo_try07.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-white text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-primary-500 text-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
    a {
      ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
      font-semibold tracking-wide transition duration-300 bg-primary-500
      pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`}
      &.workshops {
        ${tw`text-base font-bold`}
      }
    }
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  mobLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {

    const handleClick = () => {
      // Scroll to the top of the page when the link is clicked
      window.scrollTo(0, 0);
    };

  const items = [
    {
      label: <Link onClick={handleClick} to="/trending">Trending</Link>,
      key: "1",
    },
    {
      label: <Link onClick={handleClick} to="/upcoming">Upcoming</Link>,
      key: "2",
    },
    {
      label: <Link onClick={handleClick} to="/attunement">Attunements</Link>,
      key: "3",
    },
    {
      label: <Link onClick={handleClick} to="/festival">Festivals</Link>,
      key: "4",
    },
    {
      label: <Link onClick={handleClick} to="/download">Downloads</Link>,
      key: "5",
    },
  ];

  const defaultLinks = [
    <NavLinks key={1} style={{ whiteSpace: "nowrap", display: 'flex' , flexDirection: 'column'}}>
      <NavLink>
        <Link onClick={handleClick} to="/mysite">Home</Link>
      </NavLink>
      <NavLink style={{ fontSize: "16px", fontWeight: "bold" }}>
        <Dropdown menu={{ items }}>
          Workshops
          <DownOutlined />
        </Dropdown>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/meditation">Meditation</Link>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/appointment">Appointments</Link>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/event">Events</Link>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/testimonial">Testimonials</Link>
      </NavLink>
      <NavLink>
        <Link onClick={handleClick} to="/contact">Contact Us</Link>
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink style={{ color: "white" }}>Login/SignUp</PrimaryLink>
    </NavLinks>,
  ];


  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/" style={{  }}>
      <img src={logo} alt="logo" style={{ width: "16rem" }} />
    </LogoLink>
  );

  logoLink = defaultLogoLink;
  mobLink = mobLink || defaultLinks
  links = links || defaultLinks;
  // mobLink = defaultLinks

  return (
    <Header
      className={className || "header-light"}
      style={{ padding: "1rem", maxWidth: "none" }}
    >
      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {mobLink}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6 text-white" />
          ) : (
            <MenuIcon tw="w-6 h-6 " />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {/* {logoLink} */}
        {links}
      </DesktopNavLinks>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
