import {
  GET_PASSWORD_FAILED,
  GET_PASSWORD_REQUEST,
  GET_PASSWORD_SUCCESS,
} from "../constant";

export const passwordReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PASSWORD_REQUEST:
      return { loading: true };
    case GET_PASSWORD_SUCCESS:
      return { loading: false, payload: action.payload };
    case GET_PASSWORD_FAILED:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
