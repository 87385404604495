import {
  GET_LIBRARY_DELETE_REQUEST,
  GET_LIBRARY_DELETE_SUCCESS,
  GET_LIBRARY_DELETE_FAILED,
} from "../constant";
export const libraryManagementReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LIBRARY_DELETE_REQUEST:
      return { loading: true };
    case GET_LIBRARY_DELETE_SUCCESS:
      return { loading: false, deleteLibrary: action.payload };
    case GET_LIBRARY_DELETE_FAILED:
      return { loading: false, deleteLibraryError: action.payload };
    default:
      return state;
  }
};
