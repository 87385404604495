import * as React from "react";

import TextField from "@mui/material/TextField";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DOBpicker({value, setValue}) {

  return (
    <DatePicker
      value={value}
      onChange={(newValue) => {
        setValue(moment(newValue).format("YYYY-MM-DD"));
      }}
      renderInput={(params) => (
        <TextField sx={{ width: "100%" }} {...params} />
      )}
    />
  );
}
