import {
  GET_CERTIFICATE_REQUEST,
  GET_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_FAILED,
} from "../constant";

export const certificateReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CERTIFICATE_REQUEST:
      return { ...state, loading: true };
    case GET_CERTIFICATE_SUCCESS:
      return { ...state, loading: false, certificate: action.payload };
    case GET_CERTIFICATE_FAILED:
      return { ...state, loading: false, certificateError: action.payload };
    default:
      return state;
  }
};
