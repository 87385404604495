import { Typography } from "@mui/material";

const StudentDashboard = () => {
    return ( 
        <>
        <Typography variant="h1">
            StudentDashboard section comes here!
        </Typography>
        </>
     );
}
 
export default StudentDashboard;