import React, { useState, useEffect } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
// import { Box, Button, CardContent, Grid, Typography } from "@mui/material";
import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../LandingPage/images/Logo/logo_try07.png";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

export const AppointmentCheckout = ({ detail }) => {
  const theme = useTheme();
  const stripe = useStripe();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const elements = useElements();
  const dispatch = useDispatch();
  console.log(detail);

  const [email, setEmail] = useState("");
  const [paymentIntentData, setPaymentIntentData] = useState("");
  const [paymentIntentClientSecretData, setPaymentIntentClientSecretData] =
    useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  console.log(message);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${
          window.location.origin
        }/appointment/confirmation?email=${encodeURIComponent(
          email
        )}&title=${encodeURIComponent(detail.title)}&price=${encodeURIComponent(
          detail.price
        )}`,
      },
    });

    if (error) {
      setMessage(error.message);
      setIsProcessing(false);
      return;
    }

    setIsProcessing(false);
  };
  const gridSpacing = 3;

  console.log(param.get("redirect_status"));

  return (
    <>
      <Box sx={{ alignSelf: "center", width: "36vh" }}>
        <img src={logo} />
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }} // Column on mobile, row on larger screens
        alignItems="center"
        width={{ xs: "100%", sm: "80%" }}
        padding="2%"
        alignSelf="center"
      >
        {/* Left Section: Information */}
        <CardContent sx={{ width: "80%" }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid
                container
                alignContent="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="h4">{detail.title}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="column">
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        color="inherit"
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        Price
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            sx={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            {detail.price}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ pt: "16px !important" }}>
              <img src={detail.image} />
              {/* <BajajAreaChartCard /> */}
            </Grid>
          </Grid>
        </CardContent>

        {/* Right Section: Payment */}
        <Box width={{ xs: "100%", sm: "80%" }} padding="2%" alignSelf="center">
          <Typography
            fontSize={"30px"}
            fontWeight={"bold"}
            fontFamily={"sans-serif"}
          >
            Payment
          </Typography>
          <form id="payment-form" onSubmit={handleSubmit}>
            <Grid container direction="column" alignItems="center">
              <Box sx={{ marginRight: "auto" }}>
                <Typography fontSize={"16px"}>Email</Typography>
              </Box>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{
                  margin: "0 0 16px 0",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
              <Box
                width="100%"
                borderRadius="4px"
                border="1px solid #ccc"
                marginBottom="16px"
                padding="32px 16px 32px 16px"
              >
                <Typography fontSize={"800"}>
                  <PaymentElement id="payment-element" />
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  disabled={isProcessing || !stripe || !elements}
                  type="cancle"
                  sx={{
                    width: "16vh",
                    backgroundColor: "red",
                    fontSize: "16px",
                    margin: "2%",
                    fontWeight: "800",
                    height: "5vh",
                  }}
                >
                  <Link
                    to="/appointment"
                    style={{ color: "white" }}
                    onClick={() => window.location.reload()}
                  >
                    <span id="button-text">Cancel</span>
                  </Link>
                </Button>
                <Button
                  variant="contained"
                  disabled={isProcessing || !stripe || !elements}
                  id="submit"
                  type="submit"
                  sx={{
                    width: "16vh",
                    fontSize: "16px",
                    margin: "2%",
                    fontWeight: "800",
                    height: "5vh",
                  }}
                >
                  <span id="button-text">
                    {isProcessing ? "Processing..." : "Pay now"}
                  </span>
                </Button>
              </Box>
              {message && <div id="payment-message">{message}</div>}
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};
