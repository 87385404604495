import axios from "axios";
import {
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAILED,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAILED,
  PUT_FEEDBACK_REQUEST,
  PUT_FEEDBACK_SUCCESS,
  PUT_FEEDBACK_FAILED,
} from "../constant";
import apiurl from "../../constant/config";

export const getFeedBack = () => async (dispatch) => {
  try {
    dispatch({ type: GET_FEEDBACK_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.get(`${apiurl}/api/classreview/list`, {
      headers: { Authorization: `Bearer ${access}` },
    });

    dispatch({
      type: GET_FEEDBACK_SUCCESS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: GET_FEEDBACK_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createFeedBack =
  ({ id, rating, message }) =>
  async (dispatch) => {
    try {
      dispatch({ type: POST_FEEDBACK_REQUEST });

      const access = JSON.parse(localStorage.getItem("access"));

      const { data } = await axios.post(
        `${apiurl}/api/classreview/`,
        { class_rvw: id, rating, review_text: message },
        { headers: { Authorization: `Bearer ${access}` } }
      );

      dispatch({
        type: POST_FEEDBACK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_FEEDBACK_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateFeedBack =
  ({ id, rating, message }) =>
  async (dispatch) => {
    try {
      dispatch({ type: PUT_FEEDBACK_REQUEST });

      const access = JSON.parse(localStorage.getItem("access"));

      const { data } = await axios.put(
        `${apiurl}/api/classreview/${id}`,
        { rating, review_text: message },
        { headers: { Authorization: `Bearer ${access}` } }
      );

      dispatch({
        type: PUT_FEEDBACK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PUT_FEEDBACK_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
