import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import Sidebar from "../components/Sidebar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoginComponent as Login } from "../pages/Login";
import Signup from "../pages/Signup";
import TeacherRoutes from "./TeacherRoutes";
import StudentRoutes from "./StudentRoutes";
import AdminRoutes from "./AdminRoutes";
import { useSelector } from "react-redux";
import PageNotFound from "../pages/PageNotFound";
import Forgetpassword from "../pages/ForgetPassword.js";
import Main from "../pages/LandingPage/mysite/main"
import TrendingPage from "../pages/LandingPage/mysite/Pages/Trending"
import AppointmentPage from "../pages/LandingPage/mysite/Pages/Appointment"
import AttunementsPage from "../pages/LandingPage/mysite/Pages/Attunements"
import ContactUsPage from "../pages/LandingPage/mysite/Pages/ContactUs"
import DownloadsPage from "../pages/LandingPage/mysite/Pages/Downloads"
import EventsPage from "../pages/LandingPage/mysite/Pages/Event"
import FestivalsPage from "../pages/LandingPage/mysite/Pages/Festivals"
import MeditationPage from "../pages/LandingPage/mysite/Pages/Maditations"
import TestimonialPage from "../pages/LandingPage/mysite/Pages/Testimonial"
import UpcomingPage from "../pages/LandingPage/mysite/Pages/Upcomming"
import ReikiPage from "../pages/LandingPage/mysite/ServicePage/ReikiService"
import FooterPage from "../pages/LandingPage/components/MyComponents/Footer"
import ServiceRoutes from "./Services";
import { AppointmentConfirmation } from "../pages/Students/Payment/appointmentConfirmation";

function Routers() {
  const auth = useSelector(state => state.auth);
  const {userDetails} = auth;

  return (
    <>
      {userDetails ? (
        <>
          <Router>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Sidebar>
                <Suspense
                  fallback={
                    <div
                      style={{
                        minWidth: "100%",
                        minHeight: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  }
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{
                      pl: { xs: 0, sm: 0, md: 3 },
                      mt: { xs: "76px", sm: "88px" },
                    }}
                  >
                    {userDetails.userProfile === "Admin" ? (
                      <AdminRoutes />
                    ) : userDetails.userProfile === "Teacher" ? (
                      <TeacherRoutes />
                    ) : userDetails.userProfile === "Student" ? (
                      <StudentRoutes />
                    ) : null}
                  </Grid>
                </Suspense>
              </Sidebar>
            </LocalizationProvider>
          </Router>
        </>
      ) : (
        <Router>
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<Main />} />
            <Route path="/trending" element={<TrendingPage />} />
            <Route path="/upcoming" element={<UpcomingPage />} />
            <Route path="/attunement" element={<AttunementsPage />} />
            <Route path="/festival" element={<FestivalsPage />} />
            <Route path="/download" element={<DownloadsPage />} />
            <Route path="/meditation" element={<MeditationPage />} />
            <Route path="/appointment" element={<AppointmentPage />} />
            <Route
              path="/appointment/confirmation"
              element={<AppointmentConfirmation />}
            />
            <Route path="/event" element={<EventsPage />} />
            <Route path="/testimonial" element={<TestimonialPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/reiki" element={<ReikiPage />} />
            <Route path="/services/*" element={<ServiceRoutes />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgetpassword" element={<Forgetpassword />} />
          </Routes>
        </Router>
      )}
    </>
  );
}
export default React.memo(Routers);
