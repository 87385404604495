import {
  GET_CLASS_MANAGEMENT_REQUEST,
  GET_CLASS_MANAGEMENT_SUCCESS,
  GET_CLASS_MANAGEMENT_FAILED,
  GET_CLASS_DELETE_REQUEST,
  GET_CLASS_DELETE_SUCCESS,
  GET_CLASS_DELETE_FAILED,
} from "../constant";
export const classManagementReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLASS_MANAGEMENT_REQUEST:
      return { loading: true };
    case GET_CLASS_MANAGEMENT_SUCCESS:
      return { loading: false, payload: action.payload };
    case GET_CLASS_MANAGEMENT_FAILED:
      return { loading: false, error: action.payload };
    case GET_CLASS_DELETE_REQUEST:
      return { loading: true };
    case GET_CLASS_DELETE_SUCCESS:
      return { loading: false, deleteClass: action.payload };
    case GET_CLASS_DELETE_FAILED:
      return { loading: false, deleteClassError: action.payload };
    default:
      return state;
  }
};
