import { POST_FEEDBACK_REQUEST, POST_FEEDBACK_SUCCESS, POST_FEEDBACK_FAILED, GET_FEEDBACK_REQUEST, GET_FEEDBACK_SUCCESS, GET_FEEDBACK_FAILED } from "../constant"

export const feedbackReducer = (state = {},action) => {
  switch(action.type) {
    case POST_FEEDBACK_REQUEST:
      return { loading: true }
    case POST_FEEDBACK_SUCCESS:
        return { loading: false, payload: action.payload }
    case POST_FEEDBACK_FAILED:
        return { loading: false, error: action.payload }
    case GET_FEEDBACK_REQUEST:
      return { loading: true }
    case GET_FEEDBACK_SUCCESS:
        return { loading: false, payload: action.payload }
    case GET_FEEDBACK_FAILED:
        return { loading: false, error: action.payload }
    default:
      return state
  }
}