import {
  GET_MY_LIBRARY_REQUEST,
  GET_MY_LIBRARY_SUCCESS,
  GET_MY_LIBRARY_FAILED,
  GET_LIBRARY_COURSE_RETRIVE_REQUEST,
  GET_LIBRARY_COURSE_RETRIVE_SUCCESS,
  GET_LIBRARY_COURSE_RETRIVE_FAILED,
  GET_LIBRARY_CREATE_CLASS_REQUEST,
  GET_LIBRARY_CREATE_CLASS_SUCCESS,
  GET_LIBRARY_CREATE_CLASS_FAILED,
} from "../constant";

export const libraryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MY_LIBRARY_REQUEST:
      return { ...state, loading: true };
    case GET_MY_LIBRARY_SUCCESS:
      return { ...state, loading: false, payload: action.payload };
    case GET_MY_LIBRARY_FAILED:
      return { ...state, loading: false, error: action.payload };
    case GET_LIBRARY_COURSE_RETRIVE_REQUEST:
      return { ...state, loading: true };
    case GET_LIBRARY_COURSE_RETRIVE_SUCCESS:
      return { ...state, loading: false, payload: action.payload };
    case GET_LIBRARY_COURSE_RETRIVE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case GET_LIBRARY_CREATE_CLASS_REQUEST:
      return { ...state, loading: true };
    case GET_LIBRARY_CREATE_CLASS_SUCCESS:
      return { ...state, loading: false, addLibrary: action.payload };
    case GET_LIBRARY_CREATE_CLASS_FAILED:
      return { ...state, loading: false, addLibraryError: action.payload };
    default:
      return state;
  }
};
