import axios from "axios";
import {
  POST_CONTACT_REQUEST,
  POST_CONTACT_SUCCESS,
  POST_CONTACT_FAILED,
  POST_REGISTER_REQUEST,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_FAILED,
} from "../constant";
import apiurl from "../../constant/config";

export const postComment = (formData) => async (dispatch) => {
  try {
    dispatch({ type: POST_CONTACT_REQUEST });

    const requestData = {
      email: formData.email, // Provide the trending class ID
      message: formData.message, // Provide the trending class ID
      sender_name: formData.sender_name, // Provide the trending class ID
      subject: formData.subject, // Provide the trending class ID
    };

    const { data } = await axios.post(
      `${apiurl}/api/send-email/`,
      requestData
    );

    dispatch({
      type: POST_CONTACT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POST_CONTACT_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const postRegister = (formData) => async (dispatch) => {
  try {
    dispatch({ type: POST_REGISTER_REQUEST });

    const requestData = {
      title: formData.title,
      email: formData.email, // Provide the trending class ID
    };

    const { data } = await axios.post(
      `${apiurl}/api/upcoming/register`,
      requestData
    );

    dispatch({
      type: POST_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POST_REGISTER_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
