import { GET_CLAN_MEETING_REQUEST, GET_CLAN_MEETING_SUCCESS, GET_CLAN_MEETING_FAILED } from "../constant"

export const clanReducer = (state = {},action) => {
  switch(action.type) {
    case GET_CLAN_MEETING_REQUEST:
      return { loading: true }
    case GET_CLAN_MEETING_SUCCESS:
        return { loading: false, payload: action.payload }
    case GET_CLAN_MEETING_FAILED:
        return { loading: false, error: action.payload }
    default:
      return state
  }
}