import React, { useState, useEffect } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Checkout = ({id}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/confirmation`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Box width={{ xs: "100%", sm: "80%" }} padding="2%" alignSelf="center">
        <Typography
          fontSize={"30px"}
          fontWeight={"bold"}
          fontFamily={"sans-serif"}
        >
          Payment
        </Typography>
        <form id="payment-form" onSubmit={handleSubmit}>
          <Grid container direction="column" alignItems="center">
            <Box
              width="100%"
              borderRadius="4px"
              border="1px solid #ccc"
              marginBottom="16px"
              padding="32px 16px 32px 16px"
            >
              <Typography fontSize={"800"}>
                <PaymentElement id="payment-element" />
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                disabled={isProcessing || !stripe || !elements}
                type="cancle"
                sx={{
                  width: "16vh",
                  backgroundColor: "red",
                  fontSize: "16px",
                  margin: "2%",
                  fontWeight: "800",
                  height: "5vh",
                }}
              >
                <Link to="/trending" style={{ color: "white" }}>
                  <span id="button-text">Cancel</span>
                </Link>
              </Button>
              <Button
                variant="contained"
                disabled={isProcessing || !stripe || !elements}
                id="submit"
                type="submit"
                sx={{
                  width: "16vh",
                  fontSize: "16px",
                  margin: "2%",
                  fontWeight: "800",
                  height: "5vh",
                }}
              >
                <span id="button-text">
                  {isProcessing ? "Processing..." : "Pay now"}
                </span>
              </Button>
            </Box>

            {message && <div id="payment-message">{message}</div>}
          </Grid>
        </form>
      </Box>
    </>
  );
};
