import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../../components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "../../components/misc/Layouts";
import { SectionDescription } from "../../components/misc/Typography";
import reiki from "../../images/cards/reiki.jpg"

// const Container = tw(
//   ContainerBase
// )`my-8 lg:my-10 text-gray-100 -mx-8 px-8 relative`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(
  SectionDescription
)`text-black text-center mx-auto max-w-screen-md font-bold`;

const font_style = {
  fontFamily: "'Great vibes', sans-serif",
};

const BackgroundImage = tw.div`
  absolute top-0 left-0 w-full h-full bg-center bg-cover z-0 opacity-50 bg-fixed
  bg-primary-900
`;

const Container = tw(
  ContainerBase
)`my-8 lg:my-10 text-gray-100 -mx-8 px-8 relative overflow-hidden`;


const HeadingContainer = tw.div`
  z-10 relative -mx-8 px-8 py-16 text-center
`;

const Heading = tw(SectionHeading)`
  text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-5xl font-black text-primary-500
  text-black leading-tight
`;
const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`;
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`;
const StatKey = tw.div`text-xl font-medium`;
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`;

export default ({
  subheading = "",
  heading = "Learn the art of healing",
  description = "Explore a wide range of courses tailored to your need.",
}) => {
  return (
    <Container>
        <BackgroundImage style={{ backgroundImage: `url(${reiki})` }} />
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading style={font_style}>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
    </Container>
  );
};
