import {
  GET_MY_UPCOMMING_CLASS_REQUEST,
  GET_MY_UPCOMMING_CLASS_SUCCESS,
  GET_MY_UPCOMMING_CLASS_FAILED,
  GET_MY_PAST_CLASS_REQUEST,
  GET_MY_PAST_CLASS_SUCCESS,
  GET_MY_PAST_CLASS_FAILED,
} from "../constant";

export const myClassReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MY_UPCOMMING_CLASS_REQUEST:
      return { ...state, loading: true };
    case GET_MY_UPCOMMING_CLASS_SUCCESS:
      return { ...state, loading: false, myUpcommingClass: action.payload };
    case GET_MY_UPCOMMING_CLASS_FAILED:
      return { ...state, loading: false, myUpcommingClassError: action.payload };
    case GET_MY_PAST_CLASS_REQUEST:
      return { ...state, loading: true };
    case GET_MY_PAST_CLASS_SUCCESS:
      return { ...state, loading: false, myPastClass: action.payload };
    case GET_MY_PAST_CLASS_FAILED:
      return { ...state, loading: false, myPastClassError: action.payload };
    default:
      return state;
  }
};
