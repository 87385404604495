import axios from "axios";
import {
  GET_DETAIL_FAILED,
  GET_DETAIL_REQUEST,
  GET_DETAIL_SUCCESS,
} from "../constant";
import apiurl from "../../constant/config";

export const getUserDetails = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DETAIL_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.get(`${apiurl}/api/stuteacher/`, {
      headers: { Authorization: `Bearer ${access}` },
    });

    dispatch({
      type: GET_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DETAIL_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const postUserDetails =
  ({ profile, marital, joiningDate, gender, qualification, subject, dob }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_DETAIL_REQUEST });

      const access = JSON.parse(localStorage.getItem("access"));

      const { data } = await axios.post(
        `${apiurl}/api/stuteacher/`,
        {
          profile_pic: profile,
          dob,
          gender,
          qual: qualification,
          subjects: subject,
          marital,
          joining_date: joiningDate,
        },
        { headers: { Authorization: `Bearer ${access}` } }
      );

      dispatch({
        type: GET_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DETAIL_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const putUserDetails =
  ({ profile, marital, joiningDate, gender, qualification, subject, dob }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_DETAIL_REQUEST });
      const state = getState();
      const access = JSON.parse(localStorage.getItem("access"));

      const { data } = await axios.put(
        `${apiurl}/api/stuteacher/${state.auth.userDetails.user_id}`,
        {
          profile_pic: profile,
          dob,
          gender,
          qual: qualification,
          subjects: subject,
          marital,
          joining_date: joiningDate,
        },
        { headers: { Authorization: `Bearer ${access}` } }
      );

      dispatch({
        type: GET_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DETAIL_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
