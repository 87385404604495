import Navbar from "../../components/MyComponents/Navbar";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Footer from "../../components/MyComponents/Footer";
import Testimonial from "../../components/MyComponents/Testimonials";

export default () => {
  return (
    <>
      <AnimationRevealPage>
        <Navbar />
        <Testimonial />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
