import React, { useEffect } from "react";
import {
  alpha,
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import AlarmOnOutlinedIcon from "@mui/icons-material/AlarmOnOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { PlayCircleIcon } from "../../assets/icons/CourseIcons";
import CourseAboutCard from "../../components/CourseAboutCard";
import JoinModal from "../Students/Trending/JoinModal";
import Payment from "../Students/Payment";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { getClassDetails } from "../../redux/actions/classDetailAction";
// import { fetchLibraryCourseRetrive } from "../../redux/actions/libraryAction";
import { getLibraryDetail } from "../../redux/actions/libraryDetailAction";
import moment from "moment";
import CircularIndeterminate from "../../components/Loader";
import Error from "../../components/Error";
import { Stripe } from "../Students/Payment/stripe";
import { LibraryStripe } from "../Students/Payment/libraryStripe";

const containerStyle = {
  padding: "2rem",
  background: "linear-gradient(180deg, #9D78BD 0%, #8DA0D8 100%)",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  gap: 2.5,
  justifyContent: "center",
  boxShadow: "-1px 1px 8px rgba(0, 0, 0, 0.15)",
};

const itemStyle = {
  background: "#ffffff",
  width: "100%",
  padding: 1,
  paddingLeft: 2,
  borderRadius: "10px",
  gap: 2,
  alignItems: "center",
};
const centerIt = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const iconStyle = {
  color: "#9D78BD",
  fontSize: "1.4rem",
};
export const PRIMARY_COLOR = "#E8ECF7";
export const SECONDARY_COLOR = "#9D78BD";

const CourseDetailCard = ({ libraryDetail, handlePaymentOpen, id }) => {
  const user = useSelector((state) => state.auth);
  const auth = useSelector((state) => state.auth);
  const { userDetails } = auth;
  // console.log(userDetails.userProfile);
  console.log(libraryDetail.is_payment);
  // console.log(localStorage.getItem('userDetails'));
  return (
    <Card sx={containerStyle}>
      <Grid container sx={itemStyle}>
        <Grid item sx={centerIt}>
          <AlarmOnOutlinedIcon sx={iconStyle} />
        </Grid>
        <Grid item>
          <Typography variant="body1" sx={{ lineHeight: "2" }}>
            <b>{"Duration "}</b>
            {/* {moment
              .duration(moment(details.end_time).diff(details.start_time))
              .asMinutes()}{" "} */}
            {/* {course_duration} */}
            {libraryDetail.course_duration}
            Minutes
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={itemStyle}>
        <Grid item sx={centerIt}>
          <SellOutlinedIcon sx={iconStyle} />
        </Grid>
        <Grid item>
          <Typography variant="body1" sx={{ lineHeight: "2" }}>
            <b>{"Price "}</b>
            {libraryDetail.price} Rupees
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          background: "#ffffff",
          width: "100%",
          padding: 1,
          paddingLeft: 2,
          borderRadius: "10px",
          gap: 2,
          alignItems: "center",
          "&:hover": {
            background: alpha("#ffffff", 0.9),
          },
          " &:focus-within": {
            outline: `1px solid ${SECONDARY_COLOR}`,
          },
          transition: "background",
        }}
      >
        <Grid item sx={centerIt}>
          <InsertInvitationOutlinedIcon sx={iconStyle} />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <Typography
            variant="body1"
            fontWeight={"bold"}
            sx={{
              border: "none",
              background: "none",
              padding: 0,
            }}
          >
            Available Slots
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ lineHeight: "2" }}>
            {moment(libraryDetail.start_time).format("DD/MM/YYYY ")} to{" "}
            {moment(libraryDetail.end_time).format("DD/MM/YYYY ")}
          </Typography>
        </Grid>
      </Grid>
      {libraryDetail.price > 0 &&
      userDetails.userProfile === 'Student' && libraryDetail.is_payment === undefined ? (
        <Button
          sx={{
            color: "#fff",
            background: "hsl(225,49%,70%)",
            padding: "8px ",
            borderRadius: "5px",
            marginTop: "2vh",
            boxShadow:
              "-2px 2px 10px rgba(0, 0, 0, 0.15), 2px -2px 15px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              background: "hsl(225,49%,72%)",
            },
          }}
          fullWidth
          onClick={() => handlePaymentOpen()}
        >
          Buy Now
        </Button>
      ) : (
        <Link to={`/library/${id}`} state={{ libraryDetail }}>
          <Button
            sx={{
              color: "#fff",
              background: "hsl(225,49%,70%)",
              padding: "8px ",
              borderRadius: "5px",
              marginTop: "2vh",
              boxShadow:
                "-2px 2px 10px rgba(0, 0, 0, 0.15), 2px -2px 15px rgba(0, 0, 0, 0.25)",
              "&:hover": {
                background: "hsl(225,49%,72%)",
              },
            }}
            fullWidth
            // onClick={() => window.open(`/library/${id}`, "_self")}
          >
            Join Class
          </Button>
        </Link>
      )}
    </Card>
  );
};

const JoinLibrary = ({ pastClass = false }) => {
  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const libraryDetail = useSelector((state) => state.libraryDetail);

  useEffect(() => {
    dispatch(getLibraryDetail(id));
  }, []);

  const handlePaymentOpen = () => {
    setOpenPayment(true);
  };

  const handlePaymentClose = () => {
    setOpenPayment(false);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      {libraryDetail.loading ? (
        <CircularIndeterminate />
      ) : (
        <>
          {libraryDetail.error && <Error message={libraryDetail.error} />}

          <main>
            {libraryDetail.payload ? (
              <Box
                sx={{
                  marginLeft: "2vh",
                  mt: 10,
                  pr: 3,
                  paddingLeft: {
                    xs: 3,
                    md: 0,
                  },
                }}
              >
                <Typography variant="h5" fontWeight={"bold"} gutterBottom>
                  {libraryDetail.payload.course_title}
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={pastClass ? 10 : 8} lg={8}>
                    <Box
                      minHeight={pastClass ? 350 : 300}
                      sx={{
                        width: "94%",
                        position: "relative",
                        borderRadius: "15px",
                        backgroundImage: `url("https://picsum.photos/600/800")`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        marginLeft: "0vh",
                        boxShadow: "-1px 1px 8px rgba(0, 0, 0, 0.15)",
                      }}
                      style={{
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          cursor: "pointer",
                        }}
                      >
                        <PlayCircleIcon />
                      </Box>
                    </Box>
                  </Grid>
                  {!pastClass && (
                    <Grid item xs={12} md={4} sx={{ marginLeft: "-2vh" }}>
                      <CourseDetailCard
                        libraryDetail={libraryDetail.payload}
                        handleOpen={handleOpen}
                        handlePaymentOpen={handlePaymentOpen}
                        id={id}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container mt={0} spacing={4}>
                  <Grid item xs={12} sm>
                    <CourseAboutCard
                      title={libraryDetail.payload.course_title}
                      about={libraryDetail.payload.about_class}
                      aboutImg={libraryDetail.payload.thumbnail}
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : null}
            <JoinModal open={open} handleClose={handleClose} />
            <Dialog open={openPayment} onClose={handlePaymentClose} fullScreen>
              {/* <Payment
                handlePaymentClose={handlePaymentClose}
                details={libraryDetail.payload}
              /> */}
              <LibraryStripe handlePaymentClose={handlePaymentClose} />
            </Dialog>
          </main>
        </>
      )}
    </Box>
  );
};

export default JoinLibrary;
