import {
  POST_CONTACT_REQUEST,
  POST_CONTACT_SUCCESS,
  POST_CONTACT_FAILED,
  POST_REGISTER_REQUEST,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_FAILED,
} from "../constant";

export const contactReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CONTACT_REQUEST:
      return { ...state, loading: true };
    case POST_CONTACT_SUCCESS:
      return { ...state, loading: false, payload: action.payload };
    case POST_CONTACT_FAILED:
      return { ...state, loading: false, error: action.payload };
    case POST_REGISTER_REQUEST:
      return { ...state, loading: true };
    case POST_REGISTER_SUCCESS:
      return { ...state, loading: false, payload: action.payload };
    case POST_REGISTER_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
