import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import {Checkout} from './checkout';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClientSecret } from '../../../redux/actions/stripeAction';
import { useParams } from 'react-router-dom';

export const Stripe = () => {
  const {id} = useParams()
  const stripe = useSelector(state=>state.stripe)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientSecret({id}))
  }, [])

  return (
    
    stripe.payload && stripe.payload.client_secret && stripe.payload.publish_key ?
    (<Elements stripe={loadStripe(stripe.payload.publish_key)} options={{clientSecret: stripe.payload.client_secret}}>
      <Checkout id={id} />
    </Elements>)  : null

  );
};