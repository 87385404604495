import { GET_LIVE_CLASS_REQUEST, GET_LIVE_CLASS_FAILED, GET_LIVE_CLASS_SUCCESS, GET_UPCOMING_CLASS_REQUEST, GET_UPCOMING_CLASS_FAILED, GET_UPCOMING_CLASS_SUCCESS, GET_PREVIOUS_CLASS_FAILED, GET_PREVIOUS_CLASS_REQUEST, GET_PREVIOUS_CLASS_SUCCESS, GET_ADD_CLASS_FAILED, GET_ADD_CLASS_SUCCESS, GET_ADD_CLASS_REQUEST, GET_TRENDING_CLASS_REQUEST, GET_TRENDING_CLASS_SUCCESS, GET_TRENDING_CLASS_FAILED } from "../constant"

export const classReducer = (state = {},action) => {
  switch (action.type) {
    case GET_LIVE_CLASS_REQUEST:
      return { ...state, loading: true };
    case GET_LIVE_CLASS_SUCCESS:
      return { ...state, loading: false, liveClass: action.payload };
    case GET_LIVE_CLASS_FAILED:
      return { ...state, loading: false, liveClassError: action.payload };
    case GET_UPCOMING_CLASS_REQUEST:
      return { ...state, loading: true };
    case GET_UPCOMING_CLASS_SUCCESS:
      return { ...state, loading: false, upcomingClass: action.payload };
    case GET_UPCOMING_CLASS_FAILED:
      return { ...state, loading: false, upcomingClassError: action.payload };
    case GET_PREVIOUS_CLASS_REQUEST:
      return { ...state, loading: true };
    case GET_PREVIOUS_CLASS_SUCCESS:
      return { ...state, loading: false, previousClass: action.payload };
    case GET_PREVIOUS_CLASS_FAILED:
      return { ...state, loading: false, previousClassError: action.payload };
    case GET_ADD_CLASS_REQUEST:
      return { ...state, loading: true };
    case GET_ADD_CLASS_SUCCESS:
      return { ...state, loading: false, addClass: action.payload };
    case GET_ADD_CLASS_FAILED:
      return { ...state, loading: false, addClassError: action.payload };
    case GET_TRENDING_CLASS_REQUEST:
      return { ...state, loading: true };
    case GET_TRENDING_CLASS_SUCCESS:
      return { ...state, loading: false, trendingClass: action.payload };
    case GET_TRENDING_CLASS_FAILED:
      return { ...state, loading: false, trendingClassError: action.payload };

    default:
      return state;
  }
}