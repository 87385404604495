import { GET_CLASS_DETAIL_REQUEST, GET_CLASS_DETAIL_SUCCESS, GET_CLASS_DETAIL_FAILED } from "../constant"

export const classDetailReducer = (state = {},action) => {
  switch(action.type) {
    case GET_CLASS_DETAIL_REQUEST:
      return { loading: true }
    case GET_CLASS_DETAIL_SUCCESS:
        return { loading: false, payload: action.payload }
    case GET_CLASS_DETAIL_FAILED:
        return { loading: false, error: action.payload }
    default:
      return state
  }
}