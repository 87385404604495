import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getAppointmentClientSecret } from "../../../redux/actions/stripeAction";
import { AppointmentCheckout } from "./appointmentCheckout";
import { LibraryCheckout } from "./libraryCheckout";
import { Typography } from "@mui/material";

export const AppointmentSrtipe = (props) => {
  const dispatch = useDispatch();
  const stripe = useSelector((state) => state.stripe);
  // const appointmentPrice = 100; // Replace with the actual appointment price
  console.log(props);

  useEffect(() => {
    dispatch(getAppointmentClientSecret(props.detail.price));
  }, [dispatch, props.detail.price]);
  console.log(stripe.payload);

  return stripe.payload &&
    stripe.payload.client_secret &&
    stripe.payload.publish_key ? (
    <Elements
      stripe={loadStripe(stripe.payload.publish_key)}
      options={{ clientSecret: stripe.payload.client_secret }}
    >
      {/* Your payment component */}
      <AppointmentCheckout price={props.detail.price} detail={props.detail} stripe={stripe} />
    </Elements>
  ) : null;
};
