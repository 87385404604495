import {
  POST_COMMENT_REQUEST,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_FAILED,
} from "../constant";

export const commentReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_COMMENT_REQUEST:
      return { ...state, loading: true };
    case POST_COMMENT_SUCCESS:
      return { ...state, loading: false, payload: action.payload };
    case POST_COMMENT_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
