import {
  AUTH_FORGOT_REQUEST,
  AUTH_FORGOT_SUCCESS,
  AUTH_FORGOT_FAILED,
  AUTH_VERIFY_OTP_REQUEST,
  AUTH_VERIFY_OTP_SUCCESS,
  AUTH_VERIFY_OTP_FAILED,
  AUTH_NEW_PASSWORD_REQUEST,
  AUTH_NEW_PASSWORD_SUCCESS,
  AUTH_NEW_PASSWORD_FAILED,
} from "../constant";

export const forgotReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH_FORGOT_REQUEST:
      return { ...state, loading: true };
    case AUTH_FORGOT_SUCCESS:
      return { loading: false, forgot: action.payload };
    case AUTH_FORGOT_FAILED:
      return { loading: false, error: action.payload };
    case AUTH_VERIFY_OTP_REQUEST:
      return { ...state, loading: true };
    case AUTH_VERIFY_OTP_SUCCESS:
      return { loading: false, verify: action.payload };
    case AUTH_VERIFY_OTP_FAILED:
      return { loading: false, verifyError: action.payload };
    case AUTH_NEW_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case AUTH_NEW_PASSWORD_SUCCESS:
      return { loading: false, newPass: action.payload };
    case AUTH_NEW_PASSWORD_FAILED:
      return { loading: false, newPassError: action.payload };
    default:
      return state;
  }
};
