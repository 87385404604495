import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo-light.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-9.svg";
import logo from "../../images/Logo/logo_try07.png";
import { Link as RouterLink } from "react-router-dom";


const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;
const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 text-white`;


export default () => {
      const handleClick = () => {
        // Scroll to the top of the page when the link is clicked
        window.scrollTo(0, 0);
        console.log("lnik clicked");
      };
  return (
    <Container>
      {/* <Content> */}
        <FiveColumns style={{ padding: "4%" }}>
          <WideColumn>
            <LogoContainer style={{ marginTop: "-2vh" }}>
              <LogoImg
                src={logo}
                style={{ width: "75%", backgroundColor: "white" }}
              />
              {/* <LogoText>Treact Inc.</LogoText> */}
            </LogoContainer>
            <CompanyDescription>
              Soul Bliss will be to create a platform that provides individuals
              with access to powerful and effective energy healing techniques,
              and to empower them to use these techniques to improve their
              physical, emotional, and spiritual well-being. It aims to provide
              customers with natural, holistic and alternative products,
              services and solutions that promote overall well-being and health.
            </CompanyDescription>
          </WideColumn>
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link>
                  <RouterLink onClick={handleClick} to="/">
                    Home
                  </RouterLink>
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link>
                  <RouterLink onClick={handleClick} to="/meditation">
                    Meditations
                  </RouterLink>
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link>
                  <RouterLink onClick={handleClick} to="/appointment">
                    Appointments
                  </RouterLink>
                </Link>{" "}
              </LinkListItem>
              <LinkListItem>
                <Link>
                  <RouterLink onClick={handleClick} to="/event">
                    Event
                  </RouterLink>
                </Link>{" "}
              </LinkListItem>
              <LinkListItem>
                <Link>
                  <RouterLink onClick={handleClick} to="/testimonial">
                    Testimonials
                  </RouterLink>
                </Link>{" "}
              </LinkListItem>
              <LinkListItem>
                <Link>
                  <RouterLink onClick={handleClick} to="/contact">
                    Contact Us
                  </RouterLink>
                </Link>{" "}
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Social Media</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://www.youtube.com/@soulblisshealinghub">
                  Facebook
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.instagram.com/soulbliss_thehealinghub/?igshid=NTc4MTIwNjQ2YQ">
                  Instagram
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.youtube.com/@soulblisshealinghub">
                  Youtube
                </Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact Details</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <span style={{ fontWeight: "bold" }}>Phone :</span> +91
                8511248128
              </LinkListItem>
              <LinkListItem>
                <span style={{ fontWeight: "bold" }}>Email :</span>{" "}
                soulblissthehealinghub@gmail.com
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        {/* <Divider /> */}
        {/* <ThreeColRow>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Soulbliss Inc.</LogoText>
          </LogoContainer>
          <CopywrightNotice>
            &copy; 2018 Treact Inc. All Rights Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow> */}
      {/* </Content> */}
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
