import React, { useEffect } from "react";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProfileCard from "./ProfileCard";
import SettingsCard from "./SettingsCard";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../redux/actions/detailAction";
import CircularIndeterminate from "../../components/Loader";
import Error from "../../components/Error";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "1rem",
  },
  card: {
    width: "300px",
  },
});

const Profile = () => {
  const {firstname, lastname, userProfile} = useSelector(state  =>  state.auth.userDetails)
  const detail = useSelector(state  =>  state.detail)
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getUserDetails())
  },[])

  const classes = useStyles();

  return (
    <Box>
      {detail.loading ? (
        <CircularIndeterminate />
      ) : (
        <>
          {detail.error && <Error message={detail.error} />}
   
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={4} width={"100%"}>
          <Card>
            <ProfileCard user={`${firstname} ${lastname}`} detail={detail}/>
          </Card>
        </Grid>
        <Grid item md={7} width={"100%"}>
          <Card>
            <SettingsCard detail={detail} dispatch={dispatch} userProfile={userProfile}/>
          </Card>
        </Grid>
      </Grid>
    </div>

    </>
      )}
      </Box>
  );
};

export default Profile;
