import Navbar from "../../components/MyComponents/Navbar";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Footer from "../../components/MyComponents/Footer";
import Numerology from "../../components/Services/Numerology";

export default () => {
  return (
    <>
      <AnimationRevealPage>
        <Navbar />
        <Numerology />
      </AnimationRevealPage>
      <Footer />
    </>
  );
};
