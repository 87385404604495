import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound";
import Profile from "../pages/Profile";
import StudentAccount from "../components/Account/Student";
import Password from "../pages/Password";
import Account from "../pages/Account";

//  Admin Routes
const Earning = React.lazy(() => import("../pages/Admin/Earning"));
const ExpenseManagement = React.lazy(() => import("../pages/Admin/ExpenseManagement"));
const Subscribers = React.lazy(() => import("../pages/Admin/Subscribers"));
const Platform = React.lazy(() => import("../pages/Admin/Platform"));
const ContentManagement = React.lazy(() =>
  import("../pages/Admin/ContentManagement/index")
);
const TagManagement = React.lazy(() =>
  import("../pages/Admin/Tag Management")
);
const UserManagement = React.lazy(() =>
  import("../pages/Admin/UserManagement/index")
);

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route element={<Earning />} path="/earning/*" />
      <Route element={<Subscribers />} path="/subscribers" />
      <Route element={<Platform />} path="/platform" />
      <Route element={<UserManagement />} path="/users" />
      <Route element={<ContentManagement />} path="/contents/*" />
      <Route element={<TagManagement />} path="/tag/" />
      <Route element={<ExpenseManagement />} path="/expenses" />
      <Route element={<Profile />} path="/profile" />
      <Route element={<Account />} path="/account" />
      <Route element={<Password />} path="/password" />
      <Route path="/signup" element={<Navigate to="/" />} />
      <Route path="/login" element={<Navigate to={"/expenses"} />} />
      <Route path="/" element={<Navigate to={"/expenses"} />} />
    </Routes>
  );
};

export default AdminRoutes;
