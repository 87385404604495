import Navbar from "../../components/MyComponents/Navbar";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Footer from "../../components/MyComponents/Footer";
import Chakra from "../../components/Services/Chakra";

export default () => {
  return (
    <>
      <AnimationRevealPage>
        <Navbar />
        <Chakra />
      </AnimationRevealPage>
      <Footer />
    </>
  );
};
