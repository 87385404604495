import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import StudentAccount from "../components/Account/Student";
import StudentDashboard from "../components/Dashboard/Student";
// import StudentProfile from "../components/Profile/Student";
import StudentAccount from "../pages/Account";
import PageNotFound from "../pages/PageNotFound";
import StudentProfile from "../pages/Profile";
import JoinUpcommingClass from "../pages/Students/Trending/JoinUpcommingClass";
import ProfileTeacher from "../pages/Students/Trending/Detail";
import ClanMeeting from "../pages/Meeting"
import { Confirmation } from "../pages/Students/Payment/confirmation";
import { LibraryConfirmation } from "../pages/Students/Payment/libraryConfirmation";
import Payment from "../pages/Payment"
import JoinLibrary from "../pages/LibraryDetail";
import Password from "../pages/Password";
import Profile from "../pages/Profile";
import JoinClassRecording from "../pages/ClassDetail/Recording";

// Routes present in sidebar
const Trending = React.lazy(() => import("../pages/Students/Trending"));
const JoinClass = React.lazy(() =>
  import("../pages/ClassDetail")
);
const Appointments = React.lazy(() => import("../pages/Students/Appointments"));
const Myclasses = React.lazy(() => import("../pages/Students/MyClasses"));
const PastClassDetail = React.lazy(() =>
  import("../pages/Students/MyClasses/PastClassDetail")
);
const Certificate = React.lazy(() => import("../pages/Students/Certificate"));
const Suggestion = React.lazy(() => import("../pages/Students/Suggestion"));
const Feedback = React.lazy(() => import("../pages/Students/Feedback"));
const Library = React.lazy(() => import("../pages/Students/Library"));
const LibraryDetail = React.lazy(() =>
  import("../pages/Library/LibraryDetail")
);
const TeacherCalendar = React.lazy(() =>
  import("../pages/Students/Appointments/TeacherCalendar")
);
const ViewRecording = React.lazy(() =>
  import("../pages/Students/Appointments/ViewRecording")
);
const Forum = React.lazy(() => import("../pages/Students/Forum"));

const StudentRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />

      <Route element={<Trending />} path="/trending" />
      <Route element={<Navigate to={"/trending"} />} path="/" />
      <Route element={<Navigate to={"/trending"} />} path="/login" />
      <Route element={<JoinClass />} path="/trending/class/:id" />
      <Route element={<JoinClassRecording />} path="/recording/class/:id" />
      <Route element={<JoinUpcommingClass />} path="/trending/UpcommingClass/:id" />

      <Route element={<Appointments />} path="/appointments" />
      <Route
        element={<TeacherCalendar />}
        path="/appointments/teachercalendar/:id"
      />
      <Route
        element={<ViewRecording />}
        path="/appointments/viewrecording/:id"
      />
      <Route element={<Suggestion />} path="/suggestion" />
      <Route element={<Certificate />} path="/certificate" />
      <Route element={<Library />} path="/library" />
      <Route element={<LibraryDetail />} path="/library/:id" />
      <Route element={<JoinLibrary />} path="/library/course/:id" />
      <Route element={<Forum />} path="/forum" />
      <Route element={<Myclasses />} path="/myclasses" />
      <Route element={<PastClassDetail />} path="/myclasses/pastclass/:id" />
      <Route element={<Profile />} path="/profile" />
      <Route element={<StudentAccount />} path="/account" />
      <Route element={<Password />} path="/password" />
      <Route element={<ProfileTeacher />} path="/teacherprofile" />
      <Route element={<ClanMeeting />} path="/meeting/:id"/>
      <Route element={<Confirmation />} path="/confirmation"/>
      <Route element={<LibraryConfirmation />} path="/library/confirmation"/>
      <Route element={<Payment />} path="/payment" />
      <Route element={<Feedback />} path="/suggestion/:id" />
      <Route path="/signup" element={<Navigate to="/" />}/>
    </Routes> 
  );
};

export default StudentRoutes;
