import { GET_USER_LIST_REQUEST, GET_USER_LIST_FAILED, GET_USER_LIST_SUCCESS } from "../constant";

export const userListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_LIST_REQUEST:
      return { loading: true };
    case GET_USER_LIST_SUCCESS:
      return { loading: false, payload: action.payload };
    case GET_USER_LIST_FAILED:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
