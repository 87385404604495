import Navbar from "../../components/MyComponents/Navbar";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Footer from "../../components/MyComponents/Footer";
import ChakraBalancing from "../../components/MyComponents/chakraBalancing";
import Auracleansing from "../../components/MyComponents/Auracleansing";
import Attitude from "../../components/MyComponents/Attitude";
import Manifestation from "../../components/MyComponents/Manifestation";
import GuidedMaditation from "../../components/MyComponents/GuidedMaditation";

export default () => {
  return (
    <>
      <AnimationRevealPage>
        <Navbar />
        <ChakraBalancing />
        <Auracleansing />
        <Attitude />
        <Manifestation />
        <GuidedMaditation />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
