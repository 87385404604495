import axios from "axios";
import {
  GET_USER_PAYMENT_REQUEST,
  GET_USER_PAYMENT_SUCCESS,
  GET_USER_PAYMENT_FAILED,
} from "../constant";
import apiurl from "../../constant/config";
export const getPaymentDetail = () => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_PAYMENT_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.get(`${apiurl}/api/userdata/list`, {
      headers: { Authorization: `Bearer ${access}` },
    });

    dispatch({
      type: GET_USER_PAYMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_PAYMENT_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
