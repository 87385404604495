import { GET_DETAIL_FAILED, GET_DETAIL_REQUEST, GET_DETAIL_SUCCESS } from "../constant"

export const detailReducer = (state = {},action) => {
  switch(action.type) {
    case GET_DETAIL_REQUEST:
      return { loading: true }
    case GET_DETAIL_SUCCESS:
        return { loading: false, payload: action.payload }
    case GET_DETAIL_FAILED:
        return { loading: false, error: action.payload }
    default:
      return state
  }
}