import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "../../components/misc/Headings";
import MainHeader from "./HeaderComponent.js";
import Features from "./Component.js";
import aura from "../../images/cards/meditation.jpg";
import Unblocking from "./Types.js";
import Benefits from "./Types.js";
import Process from "./Types.js";
import Prepare from "./Types.js";
import Expect from "./Types.js";
import Cost from "./Types.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 mb-4`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const imageCss = tw`rounded-4xl`;

export default ({
  headingText = "Chakra",
  cards = null,
  UnblockingStep = null,
  BenefitsStep = null,
  ProcessStep = null,
  PrepareStep = null,
  ExpectStep = null,
  CostStep = null,
}) => {
  const defaultCards = [
    {
      title: "Root Chakra – Rubies",
      description: "",
    },
    {
      title: "Sacral chakra",
      description:
        "The sacral chakra, or Svadhisthana, is located just below your belly button. This chakra is responsible for your sexual and creative energy. It’s also linked to how you relate to your emotions as well as the emotions of others",
    },
    {
      title: "Solar plexus chakra",
      description:
        "The solar plexus chakra, or Manipura, is located in your stomach area. It’s responsible for confidence and self-esteem, as well as helping you feel in control of your life",
    },
    {
      title: "Heart chakra",
      description:
        "The heart chakra, or Anahata, is located near your heart, in the centres of your chest. It comes as no surprise that the heart chakra is all about our ability to love and show compassion.",
    },
    {
      title: "Throat chakra",
      description:
        "The throat chakra, or Vishuddha, is located in your throat. This chakra has to do with our ability to communicate verbally.",
    },
    {
      title: "Third eye chakra",
      description:
        "The third eye chakra, or Ajna, is located between your eyes. You can thank this chakra for a strong gut instinct. That’s because the third eye is responsible for intuition. It’s also linked to imagination.",
    },
    {
      title: "Crown chakra",
      description:
        "The crown chakra, or Sahasrara, is located at the top of your head. Your Sahasrara represents your spiritual connection to yourself, others, and the universe. It also plays a role in your life’s purpose",
    },
  ];
  const UnblockingSteps = [
    {
      heading: "Yoga postures",
    },
    {
      heading: "Breathing practices to encourage the flow of energy",
    },
    {
      heading: "Meditation to bring about clarity of mind",
    },
  ];

  const BenefitsSteps = [
    {
      heading: "Reduces Stress",
    },
    {
      heading:
        "Controls anxiety",
    },
    {
      heading: "Promotes emotional health",
    },
    {
      heading:
        "Self awareness",
    },
    {
      heading: "Increases attention span",
    },
    {
      heading: "Reduces memory loss",
    },
    {
      heading: "Generate kindness",
    },
    {
      heading: "Helps fighting addictions",
    },
    {
      heading: "Improves sleep",
    },
    {
      heading: "Controls pain",
    },
    {
      heading: "Decrease blood pressure",
    },
    {
      heading: "Accessible anywhere",
    },
  ];
  const ProcessSteps = [
    {
      heading: "Root Chakra – Rubies",
    },
    {
      heading: "Second Chakra or Womb Chakra – Moonstone",
    },
    {
      heading: "Third Chakra or Solar Plexus Chakra – Topaz",
    },
    {
      heading: "Second Chakra or Womb Chakra – Moonstone",
    },
    {
      heading: "Fourth Chakra or Heart Chakra – Rose quartz",
    },
    {
      heading: "Fifth Chakra or Throat Chakra – Turquoise",
    },
    {
      heading: "Sixth Chakra or the Third Eye – Amethyst",
    },
    {
      heading: "Seventh Chakra or Crown Chakra – Clear quartz",
    },
  ];
  const PrepareSteps = [
    {
      heading:
        "Wear clean, loose-fitting, comfortable clothing. You may wish to wear natural fabrics such as cotton, linen, or silk.",
    },
    {
      heading: "Remove your shoes, jewellery, and glasses before your session.",
    },
    {
      heading: "Keep your phone switched off or leave it behind.",
    },
  ];
  const ExpectSteps = [
    {
      heading:
        "You will feel stress-free",
    },
    {
      heading:
        "You will be calm, relaxed and peaceful.",
    },
    {
      heading:
        "You will also feel energised",
    },
  ];
  const CostSteps = [
    {
      heading:
        "A single session of meditation of a group of 10 people will cost ₹300 per person.",
    },
  ];

  if (!CostStep) CostStep = CostSteps;
  if (!ExpectStep) ExpectStep = ExpectSteps;
  if (!ProcessStep) ProcessStep = ProcessSteps;
  if (!PrepareStep) PrepareStep = PrepareSteps;
  if (!BenefitsStep) BenefitsStep = BenefitsSteps;
  if (!UnblockingStep) UnblockingStep = UnblockingSteps;
  if (!cards) cards = defaultCards;
  return (
    <AnimationRevealPage>
      <Container>
        {/* <ContentWithPaddingXl> */}
        <MainHeader
          heading="What is Meditation?"
          primaryButtonText="Order Now"
          primaryButtonUrl="https://order.now.com"
          imageInsideDiv={false}
          description="Meditation is a practice in which an individual uses a technique such as mindfulness, or focuses the mind on a particular object, thought, or activity – to train attention and awareness, and achieve a mentally clear and emotionally calm and stable state. Meditation is practised in numerous religious traditions. It may also improve your overall quality of life."
          imageSrc={aura}
          imageCss={Object.assign(tw`bg-cover`, imageCss)}
          imageContainerCss={tw`md:w-1/2 h-auto`}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
          textOnLeft={true}
        />

        <Text>
          {/* <Features
            heading="What Are Crystal Therapy Chakras?"
            description="Supporting the seven chakras with specific types of crystals improves overall health and wellbeing. Here's a list of the body's energy points, or chakras, and the corresponding stones that may be used for healing:"
            cards={cards}
          /> */}
          {/* <Unblocking
            heading="Benefits of Aura Cleansing"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={UnblockingStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          /> */}
          <Benefits
            heading="Benefits of Meditation"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={BenefitsStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Prepare
            heading="How to prepare for your session?"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={PrepareStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Expect
            heading="What to expect after your session?"
            textOnLeft={false}
            imageDecoratorBlob={true}
            steps={ExpectStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          <Cost
            heading="How much does a Meditation session cost?"
            textOnLeft={false}
            imageDecoratorBlob={false}
            steps={CostStep}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
        </Text>
        {/* </ContentWithPaddingXl> */}
      </Container>
    </AnimationRevealPage>
  );
};
