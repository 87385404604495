import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import chakra from "../../images/cards/chakra.jpg";
import reiki from "../../images/cards/reiki.jpg";
import crystal from "../../images/cards/crystal.jpg";
import meditation from "../../images/cards/meditation.jpg";
import aura from "../../images/cards/auro.jpeg";
import sound from "../../images/cards/sound.jpg";
import pranic from "../../images/cards/rpanic Healing.jpg";
import gratitude from "../../images/cards/gratitude.webp";
import tarot from "../../images/cards/tarot.jpeg";
import rider from "../../images/cards/rider.jpg";
import numerology from "../../images/cards/numerology.jpg";
import pendulum from "../../images/cards/pendulum.avif";
import facereading from "../../images/cards/face-reading.jpg";
import symbology from "../../images/cards/symbology.jpg";
import vedicwords from "../../images/cards/vedic-word.jpg";
import betchflower from "../../images/cards/betch-flower.webp";
import yantra from "../../images/cards/yantra.jpg";
import relationship from "../../images/cards/relationship.jpg";
import colortherapy from "../../images/cards/color-tharapy.jpg";
import angels from "../../images/cards/angels.jpg";
import { Link as RouterLink } from "react-router-dom";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: reiki,
      subtitle: "Reiki",
      title: "Reiki Healing",
      description:
        "Reiki is a Japanese technique for stress reduction and relaxation that also promotes healing through gentle touch. The word Reiki is made of two Japanese words – Rei which means “God’s Wisdom or the Higher Power” and Ki or as we call it “prana” or “chi” refers to “life force energy”. So, Reiki is actually “spiritually guided life force energy.”",
      url: "/services/reiki",
    },

    {
      imageSrc: crystal,
      subtitle: "Crystal",
      title: "Crystals Healing",
      description:
        "Crystal healing is an alternative medical technique in which crystals and other stones are used to cure ailments and protect against disease. Proponents of this technique believe that crystals act as conduits for healing — enabling positive, healing energy to flow into the body as negative, disease-causing energy flows out.But despite the fact that crystal healing has seen an upsurge in popularity in recent years, this alternative treatment is not popular with most medical doctors and scientists, many of whom refer to crystal healing as a pseudoscience..",
      url: "/services/crystal",
    },

    {
      imageSrc: tarot,
      subtitle: "Osho Tarot",
      title: "Osho Tarot Reading",
      description:
        "Tarot is one of the most popular divination practices, and though occultists have been drawing the allegorical cards for centuries, illustrated decks are now popping up all over. The intrinsic aestheticism of this ancient art has revitalized interest in tarot, and after seeing so many tarot readings over on TikTok, perhaps you're ready to start your own practice. After all, tarot manicures are even a thing.",
      url: "/services/tarot",
    },
    {
      imageSrc: rider,
      subtitle: "Rider Waite",
      title: "Rider Waite",
      description:
        "All the 78 cards of osho zen tarot based on Rider Waite tarot deck. It is widely popular deck for tarot card reading. It is also known as the Rider-Waite-Smith. The deck has been published in numerous editions and inspired a wide array of variants and imitations.",
      url: "/services/rider",
    },
    {
      imageSrc: numerology,
      subtitle: "Numerology",
      title: "Numerology",
      description:
        "Numerology is the science of numbers. It is an ancient study that draws meaning from different numbers, number combinations, letters and symbols in your life. Numerology is the use of numbers to tell somebody what will happen in the future. It is the belief in an occult, divine, or mystical relationship between a number and one or more coinciding events.",
      url: "/services/numerology",
    },
    {
      imageSrc: pendulum,
      subtitle: "Pendulum",
      title: "Pendulum",
      description:
        "Pendulums are known to be divination tool used in spiritual healing. Pendulum healing is the use of a Pendulum as a tool to help transform your body, mind and soul from lower to higher states of health and well-being. They are used as a form of reflection by asking questions to receive guidance, awareness and understanding. ",
      url: "/services/pendulum",
    },
    {
      imageSrc: facereading,
      subtitle: "Face Reading",
      title: "Face Reading",
      description:
        "Face reading is also known as physiognomy. It is an ancient art of divination and helps to understand a person better. It refers to the act of analysing a person's emotions and mood based on their facial expressions. Physiognomy says that every facial feature such as narrow eyes, big nose, long chin, thick eyebrows etc has a psychological meaning. ",
      url: "/services/facereading",
    },
    {
      imageSrc: symbology,
      subtitle: "Symbology",
      title: "Symbology",
      description:
        "Symbology is the study of symbols and symbolism. A symbol is a mark, sign, or word that indicates, signifies or is understood as representing an idea, object or relationship. Symbols are a fundamental way in which the mind knows and understands reality by taking us to the universal structures of human existence. ",
      url: "/services/symbology",
    },
    {
      imageSrc: vedicwords,
      subtitle: "Vedic Switch Words",
      title: "Vedic Switch Words",
      description:
        "Vedic switch words is a tool to heal the root of the life situations and manifest the desires. It means that words hold the power to change your energy. Vedic switch words help you to manifest. These powerful words speak directly to your subconscious mind activating your ability to manifest money, creativity, self healing and success.",
      url: "/services/vediwords",
    },
    {
      imageSrc: betchflower,
      subtitle: "Betch Flower",
      title: "Betch Flower",
      description:
        "The original Bach flower remedies are a safe and natural method of healing. They gently restore the balance between mind and body by casting out negative emotions such as fear, worry, hatred, and indecision which interfere with the equilibrium of the being as a whole. The Bach flower remedies allow peace and happiness to return to the sufferer so that the body is free to heal itself.",
      url: "/services/betchflower",
    },
    {
      imageSrc: yantra,
      subtitle: "Yantra",
      title: "Yantra",
      description:
        "Yantra is a visual pattern on which attention is focused during concentrative meditation. Yantras are tools for meditation, mantra recitation, and prayer, helping to focus on intentions harmoniously and channel the universe's energy into the body. It is a form, simple, or complex towards a particular purpose, providing human beings with a source of Supreme knowledge. ",
      url: "/services/yantra",
    },
    {
      imageSrc: relationship,
      subtitle: "Heal Your Relationship",
      title: "Heal Your Relationship",
      description:
        "Healing a relationship involves open communication, active listening, empathy, forgiveness, and taking responsibility for past conflicts. Establishing healthy boundaries, spending quality time together, and seeking professional help if needed can contribute to the healing process. Patience, problem-solving, appreciation, and personal growth are key factors, requiring both individuals to actively engage and work together toward resolution.",
      url: "/services/relationship",
    },
    {
      imageSrc: colortherapy,
      subtitle: "Color therapy",
      title: "Color therapy",
      description:
        "Color therapy, also known as chromotherapy, is a holistic healing technique based on the idea that different colors can have psychological and physiological effects on individuals. It involves using specific colors to promote emotional, mental, and physical well-being. Each color is believed to have unique properties and associations that can impact mood and health. For instance, warm colors like red and orange are thought to be stimulating and energizing, while cooler colors like blue and green are considered calming and soothing. Color therapy can be applied through various means such as colored lights, clothing, and surroundings to achieve a desired therapeutic effect. However, scientific evidence for the direct impact of color on health is limited, and color therapy is often used in conjunction with other therapeutic practices.",
      url: "/services/colortherapy",
    },
    {
      imageSrc: angels,
      subtitle: "Angels",
      title: "Angels",
      description:
        "Angels are spiritual beings found in various religious and cultural traditions. They are often depicted as divine messengers or intermediaries between the human and the divine realm. In many belief systems, angels are considered benevolent and are thought to guide, protect, and offer assistance to humans. Different traditions have varying classifications and hierarchies of angels, each with specific roles and attributes. In Christianity, angels are central figures in the Bible, with archangels like Michael and Gabriel playing significant roles. In Islam, angels are important as well, with archangels like Jibril (Gabriel) delivering messages to prophets. Angelic belief systems can also be found in Judaism, Zoroastrianism, and New Age spirituality, among others. People may invoke angels for guidance, protection, comfort, and spiritual support, and belief in angels often ties into broader concepts of the supernatural and the unseen.",
      url: "/services/angels",
    },
  ];

  return (
    <Container style={{ paddingTop: "16vh" }}>
      {/* <SingleColumn> */}
      <HeadingInfoContainer>
        <HeadingTitle>Trending</HeadingTitle>
        <HeadingDescription>
          Here are some of the most trending courses
        </HeadingDescription>
      </HeadingInfoContainer>

      <Content>
        {cards.map((card, i) => (
          <Card key={i} reversed={i % 2 === 1}>
            <Image imageSrc={card.imageSrc} />
            <Details>
              <Subtitle>{card.subtitle}</Subtitle>
              <Title>{card.title}</Title>
              <Description>{card.description}</Description>
              {/* <Link><RouterLink to={card.url}>Read More</RouterLink></Link> */}
            </Details>
          </Card>
        ))}
      </Content>
      {/* </SingleColumn> */}
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
