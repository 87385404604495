import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Divider, styled, TextField } from "@mui/material";
import CustomButton from "../../components/common/CustomButton";
import LoginImg from "../../assets/images/login.svg";
import google from "../../assets/images/google_icon.svg";
import { login, googleLogin } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import CircularIndeterminate from "../../components/Loader";
import {GoogleLogin} from '@react-oauth/google';
import {GoogleOAuthProvider} from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import logo from "../../assets/images/logo.png"
const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fffff" : "#2b2b2b",

    fontSize: 16,
    width: "100%",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      borderColor: "#9D78BD",
    },
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const LoginComponent = () => {

  const dispatch = useDispatch();
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const auth = useSelector(state => state.auth)
  const [showMessage, setShowMessage] = useState(false);


  const {error, loading} = auth;

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
    if (error === 'Request failed with status code 401'){
      const showMessage = "Your request requires admin approval before you can login as a teacher."
       setShowMessage(showMessage);
    }
    else if (error === "Request failed with status code 400"){
      const showMessage = "Unable to Determine"
      setShowMessage(showMessage);
    }
    else {
      setShowMessage(false);
    }
  };


  console.log(error);

  const handleSuccess = async (credentialResponse) => {
    try {
      const details = jwt_decode(credentialResponse.credential);
      const { email, given_name, family_name } = details;

      // Dispatch the action to send user details to the API
      dispatch(googleLogin(email, given_name, family_name));

      console.log("Logged in successfully:", email, given_name, family_name);
    } catch (error) {
      console.error("Error processing Google login:", error);
    }
  };
  

const handleEmailChange = (event) => {
  const newEmail = event.target.value;
  setEmail(newEmail);
  if (!newEmail) {
    setEmailError("Please enter an email address");
    setIsDisabled(true);
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
    setEmailError("Please enter a valid email address");
    setIsDisabled(true);
  } else {
    setEmailError("");
    setIsDisabled(password.length < 8);
  }
};



  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      setIsDisabled(true);
    } else {
      setPasswordError("");
      setIsDisabled(email.length === 0 || !email.includes("@"));
    }
  };

  return (
    <Box>
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {error ? <Alert severity="error">{error}</Alert> : null}
                <Link to="/" >
                <img src={logo} />
                </Link>

                <Typography
                  component="h1"
                  variant="h4"
                  sx={{
                    fontWeight: "700",
                    lineHeight: "48px",
                  }}
                >
                  Welcome Back !
                </Typography>
                {showMessage && <Alert severity="info">{showMessage}</Alert>}
                <Box
                  component="form"
                  noValidate
                  onSubmit={submitHandler}
                  sx={{
                    width: "100%",
                    maxWidth: 500,
                    boxSizing: "border-box",
                    padding: "20px",
                    mt: 4,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ paddingBottom: "4%" }}>
                    <StyledInput
                      label="Email"
                      value={email}
                      helperText={
                        emailError && (
                          <Typography variant="body2" color="error">
                            {emailError}
                          </Typography>
                        )
                      }
                      onChange={handleEmailChange}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                  <Box sx={{ paddingBottom: "4%" }}>
                    <StyledInput
                      label="Password"
                      type="password"
                      value={password}
                      helperText={
                        passwordError && (
                          <Typography variant="body2" color="error">
                            {passwordError}
                          </Typography>
                        )
                      }
                      onChange={handlePasswordChange}
                      sx={{ width: "100%" }}
                    />
                  </Box>

                  <Box justifyContent="flex-end" display="flex" sx={{ mb: 1 }}>
                    <Typography>
                      <Link
                        to="/forgetpassword"
                        style={{
                          color: "#000000",
                          textDecoration: "none",
                          fontWeight: "bold",
                        }}
                      >
                        Forgot password?
                      </Link>
                    </Typography>
                  </Box>
                  <CustomButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isDisabled}
                    sx={{
                      mt: 2,
                      mb: 2,
                      color: "#D9D9D9",
                      fontSize: "18px",
                    }}
                  >
                    Login
                  </CustomButton>

                  <Divider
                    sx={{
                      color: "#D9D9D9",
                      "&::before, &::after": {
                        borderColor: "#9D78BD",
                      },
                    }}
                  >
                    Or
                  </Divider>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 3,
                      mb: 2,
                      borderColor: "#9D78BD",
                      textTransform: "none",
                      position: "relative",
                    }}
                  >
                    <GoogleOAuthProvider clientId="876612212431-oibsejipeff345lvh9bk84d0gv92mk86.apps.googleusercontent.com">
                      {" "}
                      <div
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <img
                          src={google}
                          alt="Google Icon"
                          style={{ width: "24px" }}
                        />
                      </div>
                      <GoogleLogin
                        onSuccess={handleSuccess}
                        // onError={handleError}
                        style={{width: '10vh'}}
                      />
                  
                    </GoogleOAuthProvider>
                  </Button>
                  <Typography
                    sx={{
                      typography: { sm: "body1", xs: "body2", md: "h6" },
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Don’t have an account?&nbsp;
                    <Link
                      to="/signup"
                      style={{ color: "#9D78BD", textDecoration: "none" }}
                    >
                      Sign Up
                    </Link>
                    &nbsp;here
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={false}
              sm={6}
              md={6}
              sx={{
                placeContent: "center",
                backgroundColor: "#E6F2FF",
                backgroundSize: "contain",
                backgroundPosition: "center",
                display: {
                  xs: "none",
                  sm: "grid",
                },
              }}
            >
              <img
                src={LoginImg}
                alt=""
                style={{ width: "100%" }}
                draggable={false}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
