import React, { useEffect } from "react";
import { clanMeeting } from "react-clan-meeting";

const VideoMeeting = ({
  domain,
  consumerId,
  getInstance,
  jwt,
  id,
  name,
  type,
}) => {
  console.log(jwt);

  const optionalProperties =
    type == "Teacher"
      ? {
          roomName: `${id}`,
          displayName: `${name}`,
          jwt: `${jwt}`,
          enableJoinMeetingPage: false,
        }
      : {
          roomName: `${id}`,
          displayName: `${name}`,
          enableJoinMeetingPage: false,
        };

  useEffect(() => {
    const meeting = new clanMeeting(domain, consumerId, optionalProperties);
    const script = document.createElement("script");
    script.src = `https://${domain}/external_api.js`;
    script.async = true;
    script.onload = () => {
      document.getElementById("my-meeting").style.zIndex = 99;
      meeting.start();
      getInstance(meeting);
    };

    script.onunload = () => {
      document.getElementById("my-meeting").style.zIndex = -1;
      meeting.end();
    };

    document.body.appendChild(script);
  }, [domain, consumerId, optionalProperties.jwt]);

  return (
    <>
      <span
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Loading. Please wait....
      </span>
    </>
  );
};

export default VideoMeeting;
