import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";
import reiki from "../../images/cards/reiki.jpg";
import consciousness from "../../images/cards/consciousness.jpg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`
  relative bg-cover bg-center bg-no-repeat
`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const HeadingTitle = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center text-primary-500`;


const TestimonialSliderContainer = tw.div`mt-8`;
const TestimonialSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    display: none;
  }
`;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`
  md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between
  bg-white backdrop-filter backdrop-blur-md bg-opacity-10
`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0
bg-white backdrop-filter backdrop-blur-md bg-opacity-10`;
const Quote = tw.blockquote`text-center md:text-left font-medium text-sm lg:text-xl xl:text-2xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;
const font_style = {
  fontFamily: "'Great vibes', sans-serif",
};

const QuotesLeft = tw(
  QuotesLeftIcon
)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
const QuotesRight = tw(
  QuotesRightIcon
)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const testimonials = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
      quote:
        "I have learnt a lot from mam and still have a zeal to do more and more from mam. She is full of knowledge and passion. Due to mam, I gained a lot of belief and compassion in learning all of this and started doing something for me, my family and known ones. Thanks to mam for making us independent by bringing such meaningful courses.",
      customerName: "Charlotte Hale",
      customerTitle: "CEO, Delos Inc.",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
      quote:
        "Thank u so much mene Aaj Tak koi course nahi kiya tha first time today aaj first day tha wolf seminar ma it was so amazing that has changed my life and just first time I got positive experience in 2 hours of reading and chanting that's ma'am power I called thank u ma'am. I would like to join another course also in future. Thank u ma'am for a wonderful opportunity.",
      customerName: "Adam Cuppy",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
      quote:
        "I know you are a professional person but still the personal touch u give makes u different from the rest. Thank you.",
      customerName: "Steven Marcetti",
      customerTitle: "Event Manager, Brite",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
      quote:
        "I must say this is one of the best courses. I just learnt it last month and after 4 days of practice my stuck client payments started flowing in. This course is worth every penny we invest.",
      customerName: "Steven Marcetti",
      customerTitle: "Event Manager, Brite",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
      quote:
        "Thank you, Mam, for the wonderful remedy given to me for my child’s admission into one of the best schools. Everyone was saying that getting into that school is very difficult. I duly followed your prescribed ritual and got this much long-awaited news yesterday. Thank you again, Mam.",
      customerName: "Steven Marcetti",
      customerTitle: "Event Manager, Brite",
    },
  ];

  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <></>,
    nextArrow: <></>,
  };

  return (
    <Container style={{ backgroundImage: `url(${reiki})` }}>
      <Content style={{ paddingBottom: "2rem" }}>
        <HeadingInfoContainer>
          <HeadingTitle style={font_style}>Testimonials</HeadingTitle>
          <HeadingDescription></HeadingDescription>
        </HeadingInfoContainer>
        <TestimonialSliderContainer>
          <TestimonialSlider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                {/* <ImageContainer>
                  <img src={testimonial.imageSrc} alt={testimonial.customerName} />
                </ImageContainer> */}
                {/* <TextContainer> */}
                <QuoteContainer>
                  <QuotesLeft />
                  <Quote>{testimonial.quote}</Quote>
                  <QuotesRight />
                </QuoteContainer>
                {/* <CustomerInfo>
                    <CustomerName>{testimonial.customerName}</CustomerName>
                    <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                  </CustomerInfo> */}
                {/* </TextContainer> */}
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
      {/* <DecoratorBlob1 /> */}
      {/* <DecoratorBlob2 /> */}
    </Container>
  );
};
