import axios from "axios";
import {
  GET_LIBRARY_COURSE_RETRIVE_REQUEST,
  GET_LIBRARY_COURSE_RETRIVE_SUCCESS,
  GET_LIBRARY_COURSE_RETRIVE_FAILED,
} from "../constant";
import apiurl from "../../constant/config";
export const getLibraryDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_LIBRARY_COURSE_RETRIVE_REQUEST });

    const access = JSON.parse(localStorage.getItem("access"));

    const { data } = await axios.get(`${apiurl}/api/library/retrieve/${id}`, {
      headers: { Authorization: `Bearer ${access}` },
    });

    dispatch({
      type: GET_LIBRARY_COURSE_RETRIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_LIBRARY_COURSE_RETRIVE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


