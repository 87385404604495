import axios from "axios";
import {
  GET_PASSWORD_FAILED,
  GET_PASSWORD_REQUEST,
  GET_PASSWORD_SUCCESS,
} from "../constant";
import apiurl from "../../constant/config";

export const passwordChange =
  ({ old_password, new_password }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_PASSWORD_REQUEST });

      const state = getState();
      const access = JSON.parse(localStorage.getItem("access"));

      const { data } = await axios.put(
        `${apiurl}/api/user/change-password/`,
        { old_password, new_password },
        { headers: { Authorization: `Bearer ${access}` } }
      );

      dispatch({
        type: GET_PASSWORD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PASSWORD_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
