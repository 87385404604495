export const PlayCircleIcon = () => {
  return (
    <svg
      width="97"
      height="97"
      viewBox="0 0 97 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="48.5" cy="48.5" r="31.5" fill="white" />
      <path
        d="M40.4166 66.6877V30.3127L64.6666 48.5002M48.5 8.0835C43.1924 8.0835 37.9368 9.1289 33.0332 11.16C28.1296 13.1912 23.6741 16.1682 19.9211 19.9213C12.3415 27.5009 8.08331 37.781 8.08331 48.5002C8.08331 59.2193 12.3415 69.4995 19.9211 77.0791C23.6741 80.8321 28.1296 83.8092 33.0332 85.8403C37.9368 87.8714 43.1924 88.9168 48.5 88.9168C59.2192 88.9168 69.4993 84.6587 77.0789 77.0791C84.6585 69.4995 88.9167 59.2193 88.9167 48.5002C88.9167 43.1926 87.8712 37.937 85.8401 33.0334C83.809 28.1298 80.8319 23.6743 77.0789 19.9213C73.3259 16.1682 68.8703 13.1912 63.9668 11.16C59.0632 9.1289 53.8076 8.0835 48.5 8.0835Z"
        fill="#9D78BD"
      />
    </svg>
  );
};
