export const AUTH_LOGIN_REQUEST =  'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS =  'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILED =  'AUTH_LOGIN_FAILED';

export const GOOGLE_AUTH_LOGIN_REQUEST =  'GOOGLE_AUTH_LOGIN_REQUEST';
export const GOOGLE_AUTH_LOGIN_SUCCESS =  'GOOGLE_AUTH_LOGIN_SUCCESS';
export const GOOGLE_AUTH_LOGIN_FAILED =  'GOOGLE_AUTH_LOGIN_FAILED';

export const AUTH_FORGOT_REQUEST =  'AUTH_FORGOT_REQUEST';
export const AUTH_FORGOT_SUCCESS =  'AUTH_FORGOT_SUCCESS';
export const AUTH_FORGOT_FAILED =  'AUTH_FORGOT_FAILED';

export const AUTH_VERIFY_OTP_REQUEST =  'AUTH_VERIFY_OTP_REQUEST';
export const AUTH_VERIFY_OTP_SUCCESS =  'AUTH_VERIFY_OTP_SUCCESS';
export const AUTH_VERIFY_OTP_FAILED =  'AUTH_VERIFY_OTP_FAILED';

export const AUTH_NEW_PASSWORD_REQUEST =  'AUTH_NEW_PASSWORD_REQUEST';
export const AUTH_NEW_PASSWORD_SUCCESS =  'AUTH_NEW_PASSWORD_SUCCESS';
export const AUTH_NEW_PASSWORD_FAILED =  'AUTH_NEW_PASSWORD_FAILED';

export const AUTH_SIGNUP_REQUEST =  'AUTH_SIGNUP_REQUEST';
export const AUTH_SIGNUP_SUCCESS =  'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNUP_FAILED =  'AUTH_SIGNUP_FAILED';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const GET_LIVE_CLASS_REQUEST = 'GET_LIVE_CLASS_REQUEST'
export const GET_LIVE_CLASS_SUCCESS = 'GET_LIVE_CLASS_SUCCESS'
export const GET_LIVE_CLASS_FAILED = 'GET_LIVE_CLASS_FAILED'

export const GET_UPCOMING_CLASS_REQUEST = 'GET_UPCOMING_CLASS_REQUEST'
export const GET_UPCOMING_CLASS_SUCCESS = 'GET_UPCOMING_CLASS_SUCCESS'
export const GET_UPCOMING_CLASS_FAILED = 'GET_UPCOMING_CLASS_FAILED'

export const GET_PREVIOUS_CLASS_REQUEST = 'GET_PREVIOUS_CLASS_REQUEST'
export const GET_PREVIOUS_CLASS_SUCCESS = 'GET_PREVIOUS_CLASS_SUCCESS'
export const GET_PREVIOUS_CLASS_FAILED = 'GET_PREVIOUS_CLASS_FAILED'

export const GET_TRENDING_CLASS_REQUEST = 'GET_TRENDING_CLASS_REQUEST'
export const GET_TRENDING_CLASS_SUCCESS = 'GET_TRENDING_CLASS_SUCCESS'
export const GET_TRENDING_CLASS_FAILED = 'GET_TRENDING_CLASS_FAILED'

export const CREATE_TRENDING_CLASS_REQUEST = 'CREATE_TRENDING_CLASS_REQUEST'
export const CREATE_TRENDING_CLASS_SUCCESS = 'CREATE_TRENDING_CLASS_SUCCESS'
export const CREATE_TRENDING_CLASS_FAILED = 'CREATE_TRENDING_CLASS_FAILED'

export const GET_ADD_CLASS_REQUEST = 'GET_ADD_CLASS_REQUEST'
export const GET_ADD_CLASS_SUCCESS = 'GET_ADD_CLASS_SUCCESS'
export const GET_ADD_CLASS_FAILED = 'GET_ADD_CLASS_FAILED'

export const GET_DETAIL_REQUEST = 'GET_DETAIL_REQUEST'
export const GET_DETAIL_SUCCESS = 'GET_DETAIL_SUCCESS'
export const GET_DETAIL_FAILED = 'GET_DETAIL_FAILED'

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST'
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS'
export const GET_ACCOUNT_FAILED = 'GET_ACCOUNT_FAILED'

export const GET_PASSWORD_REQUEST = 'GET_PASSWORD_REQUEST'
export const GET_PASSWORD_SUCCESS = 'GET_PASSWORD_SUCCESS'
export const GET_PASSWORD_FAILED = 'GET_PASSWORD_FAILED'

export const GET_CLAN_MEETING_REQUEST = 'GET_CLAN_MEETING_REQUEST'
export const GET_CLAN_MEETING_SUCCESS = 'GET_CLAN_MEETING_SUCCESS'
export const GET_CLAN_MEETING_FAILED = 'GET_CLAN_MEETING_FAILED'

export const GET_STRIPE_CLIENT_REQUEST = 'GET_STRIPE_CLIENT_REQUEST'
export const GET_STRIPE_CLIENT_SUCCESS = 'GET_STRIPE_CLIENT_SUCCESS'
export const GET_STRIPE_CLIENT_FAILED = 'GET_STRIPE_CLIENT_FAILED'

export const GET_CLASS_DETAIL_REQUEST = 'GET_CLASS_DETAIL_REQUEST'
export const GET_CLASS_DETAIL_SUCCESS = 'GET_CLASS_DETAIL_SUCCESS'
export const GET_CLASS_DETAIL_FAILED = 'GET_CLASS_DETAIL_FAILED'

export const GET_TAG_REQUEST = "GET_TAG_REQUEST"
export const GET_TAG_SUCCESS = "GET_TAG_SUCCESS"
export const GET_TAG_FAILED = "GET_TAG_FAILED"

export const POST_STRIPE_REQUEST = "POST_STRIPE_REQUEST"
export const POST_STRIPE_SUCCESS = "POST_STRIPE_SUCCESS"
export const POST_STRIPE_FAILED = "POST_STRIPE_FAILED"

export const GET_USER_PAYMENT_REQUEST = "GET_USER_PAYMENT_REQUEST"
export const GET_USER_PAYMENT_SUCCESS = "GET_USER_PAYMENT_SUCCESS"
export const GET_USER_PAYMENT_FAILED = "GET_USER_PAYMENT_FAILED"

export const POST_FEEDBACK_REQUEST = "POST_FEEDBACK_REQUEST"
export const POST_FEEDBACK_SUCCESS = "POST_FEEDBACK_SUCCESS"
export const POST_FEEDBACK_FAILED = "POST_FEEDBACK_FAILED"

export const POST_COMMENT_REQUEST = "POST_COMMENT_REQUEST"
export const POST_COMMENT_SUCCESS = "POST_COMMENT_SUCCESS"
export const POST_COMMENT_FAILED = "POST_COMMENT_FAILED"

export const POST_CONTACT_REQUEST = "POST_CONTACT_REQUEST"
export const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS"
export const POST_CONTACT_FAILED = "POST_CONTACT_FAILED"

export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST"
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS"
export const POST_REGISTER_FAILED = "POST_REGISTER_FAILED"

export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST"
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS"
export const GET_FEEDBACK_FAILED = "GET_FEEDBACK_FAILED"

export const PUT_FEEDBACK_REQUEST = "PUT_FEEDBACK_REQUEST"
export const PUT_FEEDBACK_SUCCESS = "PUT_FEEDBACK_SUCCESS"
export const PUT_FEEDBACK_FAILED = "PUT_FEEDBACK_FAILED"

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST"
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS"
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED"

export const PUT_USER_LIST_REQUEST = "PUT_USER_LIST_REQUEST"
export const PUT_USER_LIST_SUCCESS = "PUT_USER_LIST_SUCCESS"
export const PUT_USER_LIST_FAILED = "PUT_USER_LIST_FAILED"

export const PUT_TAG_REQUEST = "PUT_TAG_REQUEST"
export const PUT_TAG_SUCCESS = "PUT_TAG_SUCCESS"
export const PUT_TAG_FAILED = "PUT_TAG_FAILED"

export const POST_TAG_REQUEST = "POST_TAG_REQUEST"
export const POST_TAG_SUCCESS = "POST_TAG_SUCCESS"
export const POST_TAG_FAILED = "POST_TAG_FAILED"

export const POST_TAG_DELETE_REQUEST = "POST_TAG_DELETE_REQUEST"
export const POST_TAG_DELETE_SUCCESS = "POST_TAG_DELETE_SUCCESS"
export const POST_TAG_DELETE_FAILED = "POST_TAG_DELETE_FAILED"

export const GET_CLASS_MANAGEMENT_REQUEST = "GET_CLASS_MANAGEMENT_REQUEST";
export const GET_CLASS_MANAGEMENT_SUCCESS = "GET_CLASS_MANAGEMENT_SUCCESS";
export const GET_CLASS_MANAGEMENT_FAILED = "GET_CLASS_MANAGEMENT_FAILED";

export const GET_CLASS_DELETE_REQUEST = "GET_CLASS_DELETE_REQUEST";
export const GET_CLASS_DELETE_SUCCESS = "GET_CLASS_DELETE_SUCCESS";
export const GET_CLASS_DELETE_FAILED = "GET_CLASS_DELETE_FAILED";

export const GET_LIBRARY_DELETE_REQUEST = "GET_LIBRARY_DELETE_REQUEST";
export const GET_LIBRARY_DELETE_SUCCESS = "GET_LIBRARY_DELETE_SUCCESS";
export const GET_LIBRARY_DELETE_FAILED = "GET_LIBRARY_DELETE_FAILED";

export const GET_MY_UPCOMMING_CLASS_REQUEST = "GET_MY_UPCOMMING_CLASS_REQUEST";
export const GET_MY_UPCOMMING_CLASS_SUCCESS = "GET_MY_UPCOMMING_CLASS_SUCCESS";
export const GET_MY_UPCOMMING_CLASS_FAILED = "GET_MY_UPCOMMING_CLASS_FAILED";

export const GET_MY_PAST_CLASS_REQUEST = "GET_MY_PAST_CLASS_REQUEST";
export const GET_MY_PAST_CLASS_SUCCESS = "GET_MY_PAST_CLASS_SUCCESS";
export const GET_MY_PAST_CLASS_FAILED = "GET_MY_PAST_CLASS_FAILED";

export const GET_CERTIFICATE_REQUEST = "GET_CERTIFICATE_REQUEST";
export const GET_CERTIFICATE_SUCCESS = "GET_CERTIFICATE_SUCCESS";
export const GET_CERTIFICATE_FAILED = "GET_CERTIFICATE_FAILED";

export const GET_MY_LIBRARY_REQUEST = "GET_MY_LIBRARY_REQUEST";
export const GET_MY_LIBRARY_SUCCESS = "GET_MY_LIBRARY_SUCCESS";
export const GET_MY_LIBRARY_FAILED = "GET_MY_LIBRARY_FAILED";

export const GET_LIBRARY_COURSE_RETRIVE_REQUEST = "GET_LIBRARY_COURSE_RETRIVE_REQUEST";
export const GET_LIBRARY_COURSE_RETRIVE_SUCCESS = "GET_LIBRARY_COURSE_RETRIVE_SUCCESS";
export const GET_LIBRARY_COURSE_RETRIVE_FAILED = "GET_LIBRARY_COURSE_RETRIVE_FAILED";

export const GET_LIBRARY_COMMENT_REQUEST = "GET_LIBRARY_COMMENT_REQUEST";
export const GET_LIBRARY_COMMENT_SUCCESS = "GET_LIBRARY_COMMENT_SUCCESS";
export const GET_LIBRARY_COMMENT_FAILED = "GET_LIBRARY_COMMENT_FAILED";

export const POST_LIBRARY_COMMENT_REQUEST = "POST_LIBRARY_COMMENT_REQUEST";
export const POST_LIBRARY_COMMENT_SUCCESS = "POST_LIBRARY_COMMENT_SUCCESS";
export const POST_LIBRARY_COMMENT_FAILED = "POST_LIBRARY_COMMENT_FAILED";

export const GET_LIBRARY_CREATE_CLASS_REQUEST = "GET_LIBRARY_CREATE_CLASS_REQUEST";
export const GET_LIBRARY_CREATE_CLASS_SUCCESS = "GET_LIBRARY_CREATE_CLASS_SUCCESS";
export const GET_LIBRARY_CREATE_CLASS_FAILED = "GET_LIBRARY_CREATE_CLASS_FAILED";

export const GET_DAILY_APPOINTMENT_REQUEST = "GET_DAILY_APPOINTMENT_REQUEST";
export const GET_DAILY_APPOINTMENT_SUCCESS = "GET_DAILY_APPOINTMENT_SUCCESS";
export const GET_DAILY_APPOINTMENT_FAILED = "GET_DAILY_APPOINTMENT_FAILED";

export const GET_CREATE_DAILY_APPOINTMENT_REQUEST = "GET_CREATE_DAILY_APPOINTMENT_REQUEST";
export const GET_CREATE_DAILY_APPOINTMENT_SUCCESS = "GET_CREATE_DAILY_APPOINTMENT_SUCCESS";
export const GET_CREATE_DAILY_APPOINTMENT_FAILED = "GET_CREATE_DAILY_APPOINTMENT_FAILED";

export const DELETE_DAILY_APPOINTMENT_REQUEST = "DELETE_DAILY_APPOINTMENT_REQUEST";
export const DELETE_DAILY_APPOINTMENT_SUCCESS = "DELETE_DAILY_APPOINTMENT_SUCCESS";
export const DELETE_DAILY_APPOINTMENT_FAILED = "DELETE_DAILY_APPOINTMENT_FAILED";

export const UPDATE_DAILY_APPOINTMENT_REQUEST = "UPDATE_DAILY_APPOINTMENT_REQUEST";
export const UPDATE_DAILY_APPOINTMENT_SUCCESS = "UPDATE_DAILY_APPOINTMENT_SUCCESS";
export const UPDATE_DAILY_APPOINTMENT_FAILED = "UPDATE_DAILY_APPOINTMENT_FAILED";





