import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "../../components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import tarot from "../../images/Appointment/tarot-reading.jpeg";
import reiki from "../../images/Appointment/Reiki-healing.jpg";
import lama from "../../images/Appointment/lama-fara.jpg";
import yogmaya from "../../images/Appointment/yogmaya.jpg";
import sound from "../../images/Appointment/sound-healing.webp";
import grchakra from "../../images/Appointment/grchakrabal.png";
import numreport from "../../images/Appointment/numreport.webp";
import astro from "../../images/Appointment/astrology.webp";
import face from "../../images/Appointment/face-reading.jpg";
import { Dialog } from "@mui/material";
import { LibraryStripe } from "../../../Students/Payment/libraryStripe";
import { AppointmentSrtipe } from "../../../Students/Payment/appointmentStripe";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const SelectPriceContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }

  select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const sliderSettings_2cards = {
    arrows: false,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [open, setOpen] = useState(false);
  const [detail, setDetails] = useState({});
  const handleClickOpen = (title, price, image) => {
    setOpen(true);
    const data = { title: title, price: price, image: image };
    setDetails(data);
    console.log(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* Change this according to your needs */
  const cards1 = [
    {
      id: 1,
      imageSrc: tarot,
      title: "Tarot Card Reading",
      description:
        "Tarot reading is the practice of divining wisdom and guidance through a specific spread (or layout) of Tarot cards. However, contrary to popular belief, the cards do not simply tell your fortune, and one does not have to be a psychic to give Tarot readings. The cards are meant to provide insight into the innermost truths of your higher self. In other words, the cards provide an evolved awareness of what you already know deep within. The origin of the Tarot is unknown, but we do have documented references of Tarot card use back to fourteenth century Europe. The Tarot has been used as an oracle, in the basic form we know today, since the beginning of the seventeenth century.  ",
      locationText: "Rome, Italy",
      // pricingText: "2100",
      // rating: "30min",
      priceOptions: [
        { label: "30 min", price: 2500 },
        { label: "45 min", price: 3500 },
        { label: "1 hour", price: 4100 },
      ],
    },
    {
      id: 2,
      imageSrc: sound,
      title: "Sound Healing",
      description:
        "Sound healing is a form of healing that uses different vibrations to heal the body, mind and spirit. It works on two principles: That different emotions vibrate at different levels, and that blocked, or unexpressed emotions, are the main source of dis-ease. Even without the science of sound vibrations, there's also evidence that sound baths and music therapy can be effective for healing, as it encourages relaxation and brings your body into a parasympathetic state. The ancient Egyptians also utilised sound healing, with pyramids designed to create sound chambers.",
      locationText: "Ibiza, Spain",
      // pricingText: " 1500",
      // rating: "45min",
      priceOptions: [
        { label: "45 min", price: 1500 },
        { label: "2 session", price: 2999 },
      ],
    },
    // {
    //   id: 2,
    //   imageSrc: reiki,
    //   title: "Reiki Healing",
    //   description:
    //     "Reiki is an alternative medicine technique that began in ancient Japan. It's sometimes called biofield therapy. Reiki uses healing energy that is channelled from the Reiki therapist, also called a practitioner or healer, to the patient with the goal of reducing stress, pain, and fatigue while enhancing energy. Reiki healing is a form of energy work which works through the body’s existing energy centres (such as the chakras) and energy systems (like meridians). Reiki healing is a form of energy work which works through the body’s existing energy centres (such as the chakras) and energy systems (like meridians). ",
    //   locationText: "Ibiza, Spain",
    //   // pricingText: "1100",
    //   // rating: "30min",
    // priceOptions: [
    //   { label: "Option 1", price: 2100 },
    //   { label: "Option 2", price: 1500 },
    //   { label: "Option 3", price: 900 },
    // ],
    // },
    {
      id: 3,
      imageSrc: lama,
      title: "Lama Fera Healing",
      description:
        "Lama Fera is a powerful healing technique of ancient times started by the Buddhists of the Himalayas. Lama and Fera, both the words are distinct and have their own significance. Lama means the follower of Buddhist philosophy, and Fera means incorporating it fully into one's life.This therapy takes place at home and helps to clean the energy flow system of the body on a mental, physical, emotional and spiritual level. The practitioners of Lama Fera believe that because of their spiritual practice they are elevated from mental and emotional healing. ",
      locationText: "Palo Alto, CA",
      // pricingText: "2100",
      // rating: "30 min",
      priceOptions: [
        { label: "Per Session", price: 2100 },
        { label: "Site Visit", price: 5100 },
      ],
    },
  ];
  const cards2 = [
    {
      imageSrc: reiki,
      title: "Reiki Healing",
      description:
        "Reiki is an alternative medicine technique that began in ancient Japan. It's sometimes called biofield therapy. Reiki uses healing energy that is channelled from the Reiki therapist, also called a practitioner or healer, to the patient with the goal of reducing stress, pain, and fatigue while enhancing energy. Reiki healing is a form of energy work which works through the body’s existing energy centres (such as the chakras) and energy systems (like meridians). Reiki healing is a form of energy work which works through the body’s existing energy centres (such as the chakras) and energy systems (like meridians).",
      locationText: "Ibiza, Spain",
      pricingText: " 1100",
      rating: "30 min",
    },
    {
      imageSrc: grchakra,
      title: "Group chakra Balancing",
      description:
        "Group chakra healing is a form of energy healing that is performed by a group of people. The group members gather together and focus their intention on healing the chakras of one or more of the participants. This can be done by using visualization, meditation, or other techniques.",
      locationText: "Palo Alto, CA",
      pricingText: " 1100",
      rating: "30 min",
    },
    {
      imageSrc: numreport,
      title: "Numeology Report",
      description:
        "A numerology report is a document that analyzes a person's name and birthdate to reveal insights into their personality, strengths, weaknesses, and life path. Numerologists believe that the numbers in our names and birthdates have a profound influence on our lives.",
      locationText: "Rome, Italy",
      pricingText: "3100",
      rating: "Per Session",
    },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
    //   title: "Hudak Homes",
    //   description: "",
    //   locationText: "Arizona, RAK",
    //   pricingText: "USD 99/Day",
    //   rating: 4.5,
    // },
  ];
  const cards3 = [
    {
      imageSrc: astro,
      title: "Astrology Report",
      description:
        "An astrology report is a document that analyzes a person's birth chart to reveal insights into their personality, strengths, weaknesses, and life path. Astrologers believe that the position of the planets and stars at the time of our birth has a profound influence on our lives.",
      locationText: "Ibiza, Spain",
      pricingText: " 3100",
      rating: "Per Session",
    },
    {
      imageSrc: face,
      title: "Face Reading",
      description:
        "Face reading is an ancient art of analysing a person’s character based on their facial features. Face reading is also known as physiognomy, which says that every facial feature such as narrow eyes, big nose, long chin, thick eyebrows, etc has a psychological meaning. The face is an emblem of expressions, emotions, and senses. Facial expressions are a gateway to a person's inner world and a vast array of emotions, feelings, and beliefs that the persons hold. We generally read faces and try to understand the underlying feelings and emotions of the person. And hence, sometimes false judgement or bias are likely to arise.",
      locationText: "Palo Alto, CA",
      pricingText: " 3100",
      rating: "Per Sission",
    },
    // {
    //   imageSrc: face,
    //   title: "Face Reading",
    //   description:
    //     "Face reading is an ancient art of analysing a person’s character based on their facial features. Face reading is also known as physiognomy, which says that every facial feature such as narrow eyes, big nose, long chin, thick eyebrows, etc has a psychological meaning. The face is an emblem of expressions, emotions, and senses. Facial expressions are a gateway to a person's inner world and a vast array of emotions, feelings, and beliefs that the persons hold. We generally read faces and try to understand the underlying feelings and emotions of the person. And hence, sometimes false judgement or bias are likely to arise.",
    //   locationText: "Palo Alto, CA",
    //   pricingText: " ₹1200",
    //   rating: "30 min",
    // },
    // {
    //   imageSrc: face,
    //   title: "Face Reading",
    //   description:
    //     "Face reading is an ancient art of analysing a person’s character based on their facial features. Face reading is also known as physiognomy, which says that every facial feature such as narrow eyes, big nose, long chin, thick eyebrows, etc has a psychological meaning. The face is an emblem of expressions, emotions, and senses. Facial expressions are a gateway to a person's inner world and a vast array of emotions, feelings, and beliefs that the persons hold. We generally read faces and try to understand the underlying feelings and emotions of the person. And hence, sometimes false judgement or bias are likely to arise.",
    //   locationText: "Palo Alto, CA",
    //   pricingText: " ₹1200",
    //   rating: "30 min",
    // },
  ];
  const initialSelectedPrices = cards1.map(
    (card) => card.priceOptions[0].price
  );
  const [selectedPrices, setSelectedPrices] = useState(initialSelectedPrices);

  return (
    <Container style={{ paddingTop: "16vh" }}>
      <Content>
        <HeadingWithControl>
          <Heading>Appointments</Heading>
          {/* <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls> */}
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards1.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <SelectPriceContainer>
                    <label>Select Price:</label>
                    <select
                      value={selectedPrices[index]}
                      onChange={(e) => {
                        const newSelectedPrices = [...selectedPrices];
                        newSelectedPrices[index] = parseInt(e.target.value);
                        setSelectedPrices(newSelectedPrices);
                      }}
                    >
                      {card.priceOptions.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.price}>
                          {option.label} - ₹{option.price}
                        </option>
                      ))}
                    </select>
                  </SelectPriceContainer>
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton
                onClick={() =>
                  handleClickOpen(
                    card.title,
                    selectedPrices[index],
                    card.imageSrc
                  )
                }
              >
                Book Now
              </PrimaryButton>
              <Dialog open={open} onClose={handleClose} fullScreen>
                <AppointmentSrtipe detail={detail} />
                {/* Hello */}
              </Dialog>
            </Card>
          ))}
        </CardSlider>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards2.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer style={{ height: "28px" }}>
                      <AccessTimeIcon
                        style={{ width: "16px", marginTop: "-16px" }}
                      />
                    </IconContainer>
                    <Text>{card.rating}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer style={{ height: "28px" }}>
                      <CurrencyRupeeIcon
                        style={{ width: "16px", marginTop: "-16px" }}
                      />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton
                onClick={() =>
                  handleClickOpen(card.title, card.pricingText, card.imageSrc)
                }
              >
                Book Now
              </PrimaryButton>
              <Dialog open={open} onClose={handleClose} fullScreen>
                <AppointmentSrtipe detail={detail} />
                {/* Hello */}
              </Dialog>
            </Card>
          ))}
        </CardSlider>
        <CardSlider ref={setSliderRef} {...sliderSettings_2cards}>
          {cards3.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer style={{ height: "28px" }}>
                      <AccessTimeIcon
                        style={{ width: "16px", marginTop: "-16px" }}
                      />
                    </IconContainer>
                    <Text>{card.rating}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer style={{ height: "28px" }}>
                      <CurrencyRupeeIcon
                        style={{ width: "16px", marginTop: "-16px" }}
                      />{" "}
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton
                onClick={() =>
                  handleClickOpen(card.title, card.pricingText, card.imageSrc)
                }
              >
                Book Now
              </PrimaryButton>
              <Dialog open={open} onClose={handleClose} fullScreen>
                <AppointmentSrtipe detail={detail} />
                {/* Hello */}
              </Dialog>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
