import React, { useState, useEffect } from "react";
import VideoMeeting from "./clanMeeting";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getClanMeetingDetails } from "../../redux/actions/clanAction";
import CircularIndeterminate from "../../components/Loader";
import Error from "../../components/Error";

function Meeting() {
  const [meeting, setMeetingInstance] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const user = useSelector((state) => state.auth.userDetails);
  const clan = useSelector((state) => state.clan);

  useEffect(() => {
    dispatch(getClanMeetingDetails(id));
  }, []);

  const getInstance = (instance) => {
    setMeetingInstance(instance);
  };

  if (meeting) {
    // Example event that triggers if a participant joins the meeting
    // The callback listener is triggered in case this event occurs within the meeting
    const someoneJoinedLsnr = () => {
      console.log(
        "Trigger custom logic of what happens if a participant joins the meeting"
      );
    };

    // Start listening for the someoneJoined event
    meeting.on("someoneJoined", someoneJoinedLsnr);

    meeting.on("meetingLeft", () => {
      document.getElementById("my-meeting").style.zIndex = -1;
      if (user.userProfile === "Teacher") {
        navigate("/myclasses", { replace: true });
      } else {
        navigate(`/suggestion/${id}`, { replace: true });
      }
    });
  }
  console.log(clan.payload);

  return (
    <Box>
      {clan.loading ? (
        <CircularIndeterminate />
      ) : (
        <>
          {clan.error && <Error message={clan.error} />}
          <div className="App">
            {clan.payload ? (
              <VideoMeeting
                domain={clan.payload.domain}
                consumerId={clan.payload.consumer_id}
                jwt={clan.payload.jwt}
                getInstance={(data) => getInstance(data)}
                id={id}
                name={`${user.firstname} ${user.lastname}`}
                type={user.userProfile}
              />
            ) : null}
          </div>
        </>
      )}
    </Box>
  );
}

export default Meeting;
