import { Grid } from "@mui/material";
import Account from "./Account";

const TeacherAccount = () => {
    return (
      <>
        <Grid container spacing={3} justifyContent={{md: "flex-center" ,xs: "center"}}>  
          <Grid item md={6} width={'100%'}>
            <Account />
          </Grid>
        </Grid>
      </>
    );
}
 
export default TeacherAccount;