import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import Hero from "../components/MyComponents/Hero";
import Blog from "../components/MyComponents/Blog";
import FAQ from "../components/MyComponents/FAQ";
import Testimonials from "../components/MyComponents/Testimonial";
import Contact from "../components/MyComponents/Contact.js";
import Lable from "../components/MyComponents/Lable";
import Services from "../components/MyComponents/Services";
import About from "../components/MyComponents/About";
import GlobalStyles from "../styles/GlobalStyles.js";
import Footer from "../components/MyComponents/Footer.js";
import "../style.css";

export default () => (
  <>
    <GlobalStyles />
    <AnimationRevealPage>
      <Hero />
      <About />
      <Lable />
      <Services />
      <Blog />
      <Testimonials />
      {/* <FAQ
    //   imageSrc={myimg}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    /> */}
      <Contact />
      <Footer />
    </AnimationRevealPage>
  </>
);
